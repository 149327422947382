<template>
    <h1 class="title">Notifications</h1>
    <Tabs :showIcons="false" :tabs="tabs" v-model:selected-tab-id="selectedTabId" />
    <table v-if="selectedTabId == 'messages'" class="table is-fullwidth">
        <thead>
            <tr>
                <th>Time</th>
                <th>Message</th>
                <th>Title</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="notifications === null">
                <td colspan="3" style="text-align: center;">
                    <IsLoadingIcon style="margin: 2em;" />
                </td>
            </tr>
            <tr v-else-if="notifications.unreadMessages.length > 0" v-for="(message, i) in notifications?.unreadMessages"
                :key="i" @click="setSelectedJob(message)">
                <td>{{ new Date(message.date).toLocaleString() }}</td>
                <td>{{ message.prepend }}</td>
                <td>{{ message.title }}</td>
            </tr>
            <tr v-else>
                <td colspan="3" style="text-align: center;">
                    <IconText style="margin: 2em;" icon="fal fa-envelope" text="No unread messages." />
                </td>
            </tr>
        </tbody>
    </table>


    <!-- JOB INVITES -->
    <table v-else-if="selectedTabId == 'crewInvites'" class="table is-fullwidth">
        <thead>
            <tr>
                <th>Date</th>
                <th>Title</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="notifications === null">
                <td colspan="3" style="text-align: center;">
                    <IsLoadingIcon style="margin: 2em;" />
                </td>
            </tr>
            <tr :class="{ 'is-selected': invite == selectedJobInvite }" v-else-if="notifications.jobsAvailable.length > 0"
                v-for="(invite, i) in notifications?.jobsAvailable" :key="i" @click="selectJobInvite(invite)">
                <td>{{ formatDateTime("DD/MMMM/YY hh:mm xx", invite.startTime) }}</td>
                <td>{{ invite.title }}</td>
                <td>
                    <span style="display: flex; gap: 5px">
                        <ButtonIconText class="is-small is-rounded is-primary" text="Accept" icon="fas fa-check"
                            @clicked="acceptJobInvite(invite.idReqCrew, true)" />
                        <ButtonIconText class="is-small is-rounded" text="Decline" icon="fas fa-times"
                            @clicked="acceptJobInvite(invite.idReqCrew, false)" />
                    </span>
                </td>
            </tr>
            <tr v-else>
                <td colspan="3" style="text-align: center;">
                    <IconText style="margin: 2em;" icon="fal fa-envelope-open" text="No invites." />
                </td>
            </tr>
        </tbody>
    </table>

    <!-- CREW APPROVALS -->
    <table v-else-if="selectedTabId == 'crewApprovals'" class="table is-fullwidth">
        <thead>
            <tr>
                <th>Crew Name</th>
                <th>Worker</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="notifications === null">
                <td colspan="3" style="text-align: center;">
                    <IsLoadingIcon style="margin: 2em;" />
                </td>
            </tr>
            <tr :class="{ 'is-selected': invite == selectedCrewInvite }" v-else-if="notifications.listCrewInvite.length > 0"
                v-for="(invite, i) in notifications?.listCrewInvite" :key="i" @click="selectCrewInvite(invite)">
                <td>{{ invite.groupName }}</td>
                <td>{{ invite.fullName }}</td>
                <td>
                    <span style="display: flex; gap: 5px">
                        <ButtonIconText class="is-small is-rounded is-primary" text="Accept" icon="fas fa-check"
                            @clicked="replyToRequest(invite.idReqCrew, invite.idUser, invite.jobId, true)" />
                        <ButtonIconText class="is-small is-rounded" text="Decline" icon="fas fa-times"
                            @clicked="replyToRequest(invite.idReqCrew, invite.idUser, invite.jobId, false)" />
                    </span>
                </td>
            </tr>
            <tr v-else>
                <td colspan="3" style="text-align: center;">
                    <IconText style="margin: 2em;" icon="fal fa-envelope-open" text="No invites." />
                </td>
            </tr>
        </tbody>
    </table>


    <!-- COMPANY INVITES -->
    <table v-else-if="selectedTabId == 'companyInvites'" class="table is-fullwidth">
        <thead>
            <tr>
                <th>Logo</th>
                <th>Company</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="notifications === null">
                <td colspan="3" style="text-align: center;">
                    <IsLoadingIcon style="margin: 2em;" />
                </td>
            </tr>
            <tr :class="{ 'is-selected': invite == selectCompanyInvite }"
                v-else-if="notifications.listInviteCompany.length > 0"
                v-for="(invite, i) in notifications?.listInviteCompany" :key="i" @click="selectCompanyInvite(invite)">
                <td>{{ invite.companyLogo }}</td>
                <td>{{ invite.companyName }}</td>
            </tr>
            <tr v-else>
                <td colspan="3" style="text-align: center;">
                    <IconText style="margin: 2em;" icon="fal fa-envelope-open" text="No invites." />
                </td>
            </tr>
        </tbody>
    </table>

    <!-- POPOVERS -->
    <Transition>
        <PopOver :is-open="true" v-if="selectedJob !== null" @update:isOpen="selectedJob = null">
            <Chat :id="selectedJob.id" />
        </PopOver>
    </Transition>
    <Transition>
        <PopOver :is-open="true" v-if="selectedJobInvite !== null" @update:isOpen="selectJobInvite(null)">
            <JobDetails :id="selectedJobInvite.id" />
        </PopOver>
    </Transition>
    <Transition>
        <PopOver :is-open="true" v-if="selectedCompanyInvite !== null" @update:isOpen="selectCompanyInvite(null)">
            {{ JSON.stringify(selectedCompanyInvite) }}
        </PopOver>
    </Transition>
    <Transition>
        <PopOver :is-open="true" v-if="selectedCrewInvite !== null" @update:isOpen="selectCrewInvite(null)">
            <JobDetails :id="selectedCrewInvite.jobId" />
        </PopOver>
    </Transition>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import Tabs, { ITabObject } from "@/components/elements/Tabs.vue";
import IconText from "@/components/elements/IconText.vue";
import { notifications } from "../data/state";
import { UnreadMessagesNotification } from "../types/notifications";
import JobDetails from "../components/JobDetail.vue";
import { doRequest } from "../data/state";
import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import PopOver from "@/components/elements/PopOver.vue";
import Chat from "@/components/Chat.vue";
import IsLoadingIcon from "@/components/elements/IsLoadingIcon.vue";
import { formatDateTime, showModal } from '../generalFunctions';

const selectedJob = ref<UnreadMessagesNotification | null>(null);

const selectedJobInvite = ref<jobsAvailableNotification | null>(null);
function selectJobInvite(job: jobsAvailableNotification) {
    selectedCompanyInvite.value = null;
    selectedCrewInvite.value = null;

    if (selectedJobInvite.value === job || job === null) {
        selectedJobInvite.value = null;
    } else {
        selectedJobInvite.value = job;
    }
}

const selectedCrewInvite = ref<listCrewInviteJobNotification | null>(null);
function selectCrewInvite(job: listCrewInviteJobNotification) {
    selectedCompanyInvite.value = null;
    selectedJobInvite.value = null;

    if (selectedCrewInvite.value === job || job === null) {
        selectedCrewInvite.value = null;
    } else {
        selectedCrewInvite.value = job;
    }
}

const selectedCompanyInvite = ref<listInviteCompanyNotification | null>(null);
function selectCompanyInvite(job: listInviteCompanyNotification) {
    selectedCrewInvite.value = null;
    selectedJobInvite.value = null;

    if (selectedCompanyInvite.value === job || job === null) {
        selectedCompanyInvite.value = null;
    } else {
        selectedCompanyInvite.value = job;
    }
}

const selectedTabId = ref('messages');
const tabs: ITabObject[] = [
    { id: 'messages', text: 'Messages', icon: 'fas fa-comment' },
    { id: 'crewInvites', text: 'Invites', icon: 'fas fa-users' },
    { id: 'crewApprovals', text: 'Approvals', icon: 'fas fa-stamp' },
    { id: 'companyInvites', text: 'Companies', icon: 'fas fa-building' }
];

// watch(notifications, (newVal) => {
//     tabs[0].count = newVal.unreadMessages.length;
//     tabs[1].count = newVal.jobsAvailable.length;
//     tabs[2].count = newVal.listCrewInvite.length;
//     tabs[3].count = newVal.listInviteCompany.length;
// }, {deep: true});

function setSelectedJob(job: UnreadMessagesNotification | null) {
    if (selectedJob.value === job) {
        selectedJob.value = null;
        return;
    }
    selectedJob.value = job;
}

async function acceptJobInvite(id: number, accepted: boolean) {
    const response = await doRequest<IDefaultSnackbarResponse>("/api/user/replyJob", "POST", { id: id, accepted: accepted });
    if (response.error) {
        showModal(response);
    } else {
        showModal(response);
    }
}

async function replyToRequest(idReqCrew: number, idUser: number, jobId: number, accept: boolean) {
    const response = await doRequest<IDefaultSnackbarResponse>("/api/user/replyInviteJob", "POST",
        { idReqCrew, idUser, jobId, accept }
    );
    if (response.error) {
        showModal(response);
    } else {
        showModal(response);
    }
}


</script>