<template>
    <IsLoadingIcon v-if="notifications == null"></IsLoadingIcon>
    <template v-else>
        <CardsContainer>
            <JobRequest v-for="(item, i) in notifications?.listCrewInvite" @click="idJob = item.jobId"
                :key="`invites-${item.idUser}-${item.idReqCrew}`" :request="item" />
        </CardsContainer>
        <PopOver v-if="idJob" :is-open="true" @update:is-open="idJob = undefined">
            <JobForm :jobId="idJob" v-model:selectedTab="formSelectedTabId" v-model:formSubView="formSubView" />
        </PopOver>
    </template>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import JobRequest from "@/components/JobRequest.vue";
import CardsContainer from "@/components/elements/CardsContainer.vue";
import IsLoadingIcon from "@/components/elements/IsLoadingIcon.vue";
import PopOver from "@/components/elements/PopOver.vue";
import { computed, watch } from "vue";
import { notifications } from "../../data/state";
import JobForm from "@/components/JobForm.vue";

const router = useRouter();

const idJob = defineModel('idJob', { type: Number });
const formSelectedTabId = defineModel('formSelectedTabId', { type: String });
const formSubView = defineModel('formSubView', { type: String });
watch(formSelectedTabId, navigateJobForm);
watch(formSubView, navigateJobForm);
watch(idJob, navigateJobForm);

function navigateJobForm() {
    const params = { idJob: idJob.value, formSelectedTabId: formSelectedTabId.value, formSubView: formSubView.value || '' }
    const param = { name: 'requests', params };
    router.push(param);
}

// const selectedJob = computed({
//     get() {
//         return idJob;
//     },
//     set(value: number | undefined) {
//         if (value == props.id || !value) {
//             router.push({ name: "requests", params: { id: undefined } });
//         } else {
//             router.push({ name: "requests", params: { id: value } });
//         }
//     }
// });


</script>
<style lang="scss" scoped>
.card-container {
    display: flex;
    flex-wrap: wrap;
}

.box {
    margin: 1em;

    >span {
        display: block;
    }
}
</style>