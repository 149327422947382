<template>
    <div class="dropdown" :class="{ 'is-active': isOpen }">
        <div class="dropdown-trigger" @click="isOpen = !isOpen">
            <button class="button is-fullwidth" :class="props.size" aria-haspopup="true" aria-controls="dropdown-menu">
                <IconText v-if="selectedItem" :icon-color="selectedItem.iconColor" :icon="selectedItem.icon"
                    :text="selectedItem.text" @onclick="isOpen = !isOpen" />
                <IconText v-else :icon="nullItem.icon" :icon-color="nullItem.iconColor" :text="nullItem.text"
                    @onclick="isOpen = !isOpen" />
                <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
            </button>
        </div>
        <div v-if="isOpen" class="dropdown-menu  is-fullwidth" id="dropdown-menu" role="menu">
            <div class="dropdown-content is-fullwidth">
                <a href="#" class="dropdown-item" @click.stop="setSelection(null)">
                    <span>Not Selected</span>
                </a>
                <a v-for="item in props.list" href="#" class="dropdown-item" @click.stop="setSelection(item)">
                    <!-- @onclick="setSelection(item)" I removed this because it was calling the function twice-->
                    <IconText :icon-color="item.iconColor" :icon="item.icon" :text="item.text" />
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import IconText from './IconText.vue';
export type DropDownItem = { id: number, icon?: string, iconColor?: string, text?: string };
const emit = defineEmits(['changedOption']);
const nullItem: DropDownItem = { id: 0, icon: 'fas fa-question', text: "Not Selected", iconColor: 'lightgray' };
const props = defineProps<{ list: DropDownItem[], size?: string }>();
const selectedItem = defineModel<DropDownItem>('selectedItem');
const selectedId = defineModel<number>('selectedId')
const isOpen = ref(false);

watch(isOpen, (newVal) => {
    if (newVal) {
        setTimeout(() => {//I coudn't find and didnt want to spend a lot of time to find a better way to do this
            isOpen.value = false;
        }, 5000)
    }
});

onMounted(() => {
    selectedItem.value = props.list.find(el => el.id === selectedId.value) || nullItem
})
function setSelection(item: DropDownItem | null) {
    if (item == null) {
        selectedItem.value = nullItem;
    } else {
        selectedItem.value = item;
    }
    emit('changedOption', selectedItem.value.id)
    isOpen.value = false;
}

</script>
<style lang="scss" scoped>
.dropdown-content {
    text-align: left;
}

:deep(.icon-text) {
    flex-wrap: nowrap;

    .icon-text {
        flex-wrap: nowrap;
    }
}
</style>