<template>
    <table class="table is-fullwidth is-striped">
        <thead>
            <tr>
                <template v-for="(header, index) in headers">

                    <slot v-if="hasSlot('header.' + header.property)" :name="'header.' + header.property"></slot>
                    <th v-else-if="header.sortable" @click="doSortColumn(header)" draggable="true" @dragover.prevent
                        @dragstart="onDragStart(index)" @drop="onDrop(index)"
                        :class="header.sortable ? 'isSortable' : ''">
                        <template v-if="header.sortable && sortColumn === header.property">
                            <template v-if="sortDirection === 'asc'">
                                <i class="fas fa-sort-up"></i>
                            </template>

                            <template v-else-if="sortDirection === 'desc'">
                                <i class="fas fa-sort-down"></i>
                            </template>
                        </template>
                        {{ header.text }}
                    </th>
                    <th v-else>
                        {{ header.text }}
                    </th>

                </template>
            </tr>
        </thead>
        <tbody>

            <template v-if="isLoading">
                <slot v-if="hasSlot('isLoading')" name="isLoading"></slot>
                <tr v-else>
                    <td :colspan="headers.length" align="center">
                        <IsLoadingIcon />
                    </td>
                </tr>
            </template>

            <template v-else-if="!items || items.length === 0">
                <slot name="empty"></slot>
            </template>
            <tr v-else v-for="(item, i) in   items  " :key="i" @click="itemClicked(item)" :class="{
                    'is-selected': selectedItem === item
                }">

                <template v-for="header in headers">
                    <slot style="padding: 0px;" v-if="hasSlot(header.property)" :name="header.property"
                        :item="(item as any)">
                    </slot>
                    <td v-else-if="header.UTCFormatString" :align="header.align || 'left'">
                        {{ formatDateTime(header.UTCFormatString, item[header.property]) }}
                    </td>
                    <td v-else-if="header.isCurrency" :align="header.align || 'left'">
                        ${{ item[header.property].toFixed(2) }}
                    </td>
                    <td v-else-if="header.isDuration" :align="header.align || 'left'">
                        {{ formatDurationMS(item[header.property], false) }}
                    </td>
                    <td v-else :align="header.align || 'left'">
                        {{ item[header.property] }}
                    </td>

                </template>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { formatDateTime, formatDurationMS } from '@/generalFunctions';
import { Ref, ref, useSlots } from 'vue';
import IsLoadingIcon from './IsLoadingIcon.vue';
const headers = defineModel('headers', { required: true, type: Array<TableHeader> });
const items = defineModel('items', { required: true, type: Array<TableItems>, default: [] });
const selectItem = defineModel('selectItem', { required: false, type: Boolean, default: false });
const selectedItem = defineModel('selectedItem', { required: false, type: Object, default: null });
const isLoading = defineModel('isLoading', { required: false, type: Boolean, default: false });
const sortColumn: Ref<string> = ref('');
const sortDirection: Ref<'asc' | 'desc'> = ref('asc');

const slots = useSlots()
const hasSlot = (name: string) => {
    return !!slots[name];
}
const emit = defineEmits(['rowClick'])

function itemClicked(item: any) {
    if (selectItem.value) {
        selectedItem.value = item;
    }
    emit('rowClick', item)
}
const draggedIndex = ref(-1)
function onDragStart(index: number) {
    draggedIndex.value = index;
}
function onDrop(index: number) {
    if (draggedIndex.value === -1) return;

    const temp = headers.value[draggedIndex.value];
    headers.value.splice(draggedIndex.value, 1);
    headers.value.splice(index, 0, temp);
}

export interface TableHeader {
    text: string;
    property: string;//item property
    sortable: boolean;
    align?: 'left' | 'right' | 'center';
    UTCFormatString?: string;//if the property is a date, this is the format
    isDuration: boolean;
    isCurrency: boolean;
    sorter: (a: unknown, b: unknown, direction: 'asc' | 'desc') => number;
}
export interface TableItems {
    [key: string]: any;
}

function doSortColumn(column: TableHeader) {
    if (!column.sortable) return;
    if (sortColumn.value === column.property) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
        sortColumn.value = column.property;
        sortDirection.value = 'asc';
    }
    let isString = false
    if (items.value.length > 0) {
        isString = typeof items.value[0][sortColumn.value] === 'string'
    }
    if (column.sorter) {
        items.value.sort((a, b) => column.sorter(a, b, sortDirection.value))
    } else {
        items.value.sort((a, b) => {
            let aa = a[sortColumn.value];
            let bb = b[sortColumn.value];
            if (isString) {
                aa = aa.toLowerCase()
                bb = bb.toLowerCase()
            }
            if (sortDirection.value === 'asc') {
                return (aa) > bb ? 1 : -1;
            } else {
                return aa < bb ? 1 : -1;
            }
        });
    }
}



</script>

<style scoped>
.isSortable {
    cursor: pointer;
}
</style>