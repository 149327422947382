import { createRouter, createWebHistory } from "vue-router";
import SignUp from "../pages/public/signup.vue";
import Register from "../pages/public/register.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      name: "signup",
      path: "/public/signup/:type?",
      component: SignUp,
      props: true,
    },
    {
      name: "register",
      path: "/public/Register/:hash?",
      component: Register,
      props: true,
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log("Before each", to, from);
  next();
})

router.afterEach((to, from) => {
  //Log all routes
  if (typeof window.analytics !== "undefined") {
    const props: Record<string, any> = Object.assign({}, to.params);
    props.from = from.fullPath;
    props.domain = window.location.hostname;
    if (to.name) {
      props.name = to.name;
      window.analytics.page("", to.name.toString(), props);
    }
  }
});

export default router;
