<template>
    <h1 class="title">My Invoices</h1>
    <DefaultTable :is-loading="invoices == null" :data="invoices" :selection="selection" :headers="headers" :items="invoices"
        @rowClick="setSelelction($event)">
        <slot name="empty">
        </slot>
        <template #actions="{ item }">
            <i class="fas fa-file-search"></i>
        </template>
    </DefaultTable>

    <!-- <table class="table is-fullwidth is-striped">
        <thead>
            <tr>
                <th>Week Ending</th>
                <th>Amount</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="invoices == null">
                <td colspan="6" align="center">
                    <p style="margin: 4em">
                        <is-loading-icon></is-loading-icon>
                    </p>
                </td>
            </tr>
            <tr v-else v-for="(item, i) in invoices" :key="i" @click="setSelelction(item)"
                :class="{ 'is-selected': item == selection }">
                <td>{{ new Date(item.endDate).toDateString() }}</td>
                <td><span style="display: inline-block; width: 75px; text-align: right;">${{ item.total.toFixed(2) }}</span>
                </td>
                <td><i class="fas fa-file-search"></i></td>
            </tr>
        </tbody>
    </table> -->
    <PopOver v-if="selection != null" v-model:is-open="hasId">
        <h1 class="title">{{ new Date(selection?.endDate).toDateString() }}: ${{ selection?.total.toFixed(2) }}</h1>

        <Carousel :slides="imageList.map(el => el.image)" @on-download-click="downloadFile" />
        <!-- <iframe :src="(pdf as string)"></iframe> -->

    </PopOver>
</template>
<script lang="ts" setup>
import { IInvoice, loadInvoicesAsync, setTime } from "../data/state";
import { ref, Ref, onMounted, computed, watch } from "vue";
import DefaultTable from "@/components/elements/DefaultTable.vue";
import IsLoadingIcon from "../components/elements/IsLoadingIcon.vue";
import PopOver from "../components/elements/PopOver.vue";
import Carousel from "../components/Carousel.vue";
import { IId } from "@/types/global";
import { relativeNavigationTo } from "./_index";
import { useRoute, useRouter } from "vue-router";
import { localDownloadFile } from "@/generalFunctions";

const props = defineProps<IId>();
const headers = ref([
    { text: 'Week Ending', property: 'endDate', sortable: true, UTCFormatString: 'D MMMM YYYY' },
    { text: 'Amount', property: 'total', sortable: true, isCurrency: true, align: 'right' },
    { text: '', property: 'actions' }
]);

const invoices: Ref<Array<IInvoice>> = ref([]);
const selection = computed(() => {
    const item = invoices.value?.find(item => item.idInvoice == props.id);
    if (item == undefined) {
        return null;
    } else {
        return item;
    }
});

// let pdf: Ref<string | null> = ref(null);

let route = useRoute();
const currentPDF: Ref<undefined | any> = ref(undefined)
const hasId = computed({
    get() {
        return props.id !== null;
    },
    set(value) {
        relativeNavigationTo('invoices', {});
    }
})

async function parsePage(i: number) {
    const page = await currentPDF.value!.getPage(i);
    const scale = 1;
    const viewport = page.getViewport(scale);
    //
    // Prepare canvas using PDF page dimensions
    //
    canvas.value.height = viewport.height;
    canvas.value.width = viewport.width;
    //
    // Render PDF page into canvas context
    //
    await page.render({ canvasContext: canvas.value.getContext('2d')!, viewport }).promise;
    return canvas.value.toDataURL('image/png');
}

const imageList: Ref<{ image: string, isParsed: boolean }[]> = ref([])
function downloadFile() {
    const pdfData = new Blob([selectedFile], { type: 'application/pdf' });
    localDownloadFile(pdfData, 'invoice.pdf')
}

async function loadInvoices(id: number) {

    const updatedInvoice = await loadInvoicesAsync(new Date(0), new Date(0), id);
    if (selection.value != null) {
        const item = updatedInvoice[0];
        console.log(selection.value)
        selection.value!.pdf = item.pdf;
        const str = item.pdf!.data.map(item => String.fromCharCode(item)).join('');

        const pdfData = base64ToArrayBuffer(str);
        selectedFile = pdfData;
        pdfjsLib.value.getDocument({ data: pdfData }).promise.then(async (pdfDocument: any) => {
            currentPDF.value = pdfDocument;

            const numberOfPages = pdfDocument.numPages;
            //add first page straight awai
            imageList.value = [];
            imageList.value.push({
                image: await parsePage(1),
                isParsed: true,
            });
            //do the rest async so user can see page load faster
            setTimeout(async () => {
                for (let i = 2; i < numberOfPages + 1; ++i) {


                    imageList.value.push({
                        image: await parsePage(i),
                        isParsed: true,
                    });
                }
            }, 100)
        }).catch((error: any) => {
            console.error('Error loading PDF: ', error);
        });
    }
}

let selectedFile: ArrayBufferLike;
watch(route, async (a, b) => {
    if (props.id != null) {
        loadInvoices(props.id)
    }
});

async function setSelelction(item: IInvoice) {
    if (item.idInvoice !== props.id) {
        relativeNavigationTo('invoices', { id: item.idInvoice.toString() });
    } else {
        relativeNavigationTo('invoices', {});
    }
}

const canvas: Ref<HTMLCanvasElement> = ref(document.createElement('canvas'))

function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if the base64 is not encoded
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

const pdfjsLib: Ref<any | undefined> = ref(undefined);

onMounted(async () => {
    const startDate = new Date();
    const endDate = new Date();

    //get 2 years
    startDate.setFullYear(startDate.getFullYear() - 1);
    endDate.setFullYear(endDate.getFullYear() + 1);

    invoices.value = await loadInvoicesAsync(startDate, endDate);

    const libpdf = document.createElement('script');
    libpdf.onload = () => {
        pdfjsLib.value = (window as any)['pdfjs-dist/build/pdf'];
        if (props.id) {
            loadInvoices(props.id)
        }
    }
    libpdf.setAttribute(
        'src',
        '/pdfjs/pdf.js'
        //'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.10.100/pdf.min.js'
    );
    document.head.appendChild(libpdf);
    const pdfWorker = document.createElement('script');
    pdfWorker.setAttribute(
        'src',
        '/pdfjs/pdf.worker.js'
        // 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.10.100/pdf.worker.min.js'
    );
    document.head.appendChild(pdfWorker);



});


</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

table.table tbody tr.is-selected {
    cursor: pointer;
    color: black;
}

iframe {
    border: 0;
    margin: 0;
    height: calc(100vh - 8em);
    width: 100%;
}
</style>