<template>
    <Tabs :tabs="tabs" v-model:selectedTabId="selectedTab" />
    <template v-if="selectedTab == 'templates'">
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-comments" text="Pending Invite SMS" />
                <div class="control">
                    <textarea type="text" v-model="config.confSMSTemplatePendingInvite" class="textarea"
                        placeholder="e.g. You've been confirmed for a job, it will be available in your calendar." />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <Tooltip :widthText="'400px'" text="Available Variables:<br>
                {JobName} - Job Name<br>
                {JobDate} - Job Date<br>
                {JobTime} - Job Time<br>
                {LinkAccept} - Link to Accept the Job<br>
                {LinkReject} - Link to Refuse the Job">
                    <IconText icon="fal fa-envelope" text="Pending Invite Email" />
                </Tooltip>

                <div class="control">
                    <textarea type="text" v-model="config.confInviteJobEmail" class="textarea"
                        placeholder="e.g. <html><body>{jobName} ({jobDate})</body></html>" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <Tooltip :widthText="'200px'" text="Available Variables:<br>
                {JobName} - Job Name<br>
                {JobDate} - Job Date<br>">
                    <IconText icon="fal fa-envelope" text="Finished Job Email Subject" />
                </Tooltip>

                <div class="control">
                    <input type="text" v-model="config.confFinishJobEmailSubject" class="input"
                        placeholder="e.g. Welcome to {jobName}" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <Tooltip :widthText="'200px'" text="Available Variables:<br>
                {JobName} - Job Name<br>
                {JobDate} - Job Date<br>">
                    <IconText icon="fal fa-envelope" text="Finished Job Email Body" />
                </Tooltip>

                <div class="control">
                    <textarea type="text" v-model="config.confFinishJobEmailText" class="textarea"
                        placeholder="e.g. <html><body>{jobName} ({jobDate})</body></html>" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <Tooltip :widthText="'200px'" text="Available Variables:<br>
                    {firstName} First Name<br>
                    {lastName} Last Name<br>
                    {companyName} Company Name<br>">
                    <IconText icon="fal fa-envelope" text="Template Email Reply Company Registrant" />
                </Tooltip>

                <div class="control">
                    <textarea type="text" v-model="config.confReplyEmailRegistrantTemplate" class="textarea"
                        placeholder="e.g. <html><body>{jobName} ({jobDate})</body></html>" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-envelope"
                    text="Phone Notification after company register (use ; as separator)" />
                <div class="control">
                    <input type="text" v-model="config.confNotificationRegistrantPhone" class="input"
                        placeholder="+610451000001;+610451000001;+610451000001" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-envelope"
                    text="Phone Notification after company register (use ; as separator)" />
                <div class="control">
                    <input type="text" v-model="config.confNotificationRegistrantEmail" class="input"
                        placeholder="manager1@primehire.com.au;manager2@primehire.com.au" />
                </div>
            </label>
        </div>
    </template>
    <template v-else-if="selectedTab == 'jobs'">
        <div class="field">
            <label class="label">
                <IconText icon="fas fa-percentage" text="Overtime Rate Increase" />
                <div class="control has-icons-right">
                    <input type="number" v-model="config.confExtraRatePercent" class="input" />
                    <IconText iconColor="darkred" class="icon is-right" icon="fas fa-percent" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fas fa-percentage" text="Overnight Rate Increase" />
                <div class="control has-icons-right">
                    <input type="number" v-model="config.confOvernightRatePercent" class="input" />
                    <IconText iconColor="darkred" class="icon is-right" icon="fas fa-percent" />
                </div>
            </label>
        </div>
        <!-- <div class="field">
            <label class="label">
                <IconText icon="fal fa-envelope" text="Job Cancellation Email Template" />
                <div class="control">
                    <textarea type="text" v-model="jobCancelTemplate" class="textarea"
                        placeholder="e.g. <html><body>{jobName} ({jobDate})</body></html>" />
                </div>
            </label>
        </div> -->

        <div class="field">
            <label class="label">
                <IconText icon="fal fa-clock" text="Start Time (Overtime)" />
                <div class="control">
                    <input type="input" placeholder="13:00" class="input" v-model="config.confStartExtraRate" />
                </div>
            </label>
        </div>

        <div class="field">
            <label class="label">
                <IconText icon="fal fa-clock" text="Start Time(Overnight)" />
                <div class="control">
                    <input type="input" placeholder="13:00" class="input" v-model="config.confStartOvernight" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-clock" text="End Time(Overnight)" />
                <div class="control">
                    <input type="input" placeholder="13:00" class="input" v-model="config.confEndOvernight" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" class="checkbox" v-model="config.confEndOvernightNextDay" />
                <IconText icon="fal fa-calendar-day" text="Is next day?" />
            </label>
        </div>
    </template>
    <template v-else-if="selectedTab == 'bank'">
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-university" text="Bank State Branch (BSB)" />
                <div class="control">
                    <input type="text" v-model="config.confSenderBSB" class="input" placeholder="e.g. 000-123" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-university" text="Bank Name" />
                <div class="control">
                    <input type="text" v-model="config.confBank" class="input" placeholder="e.g. Commonwealth Bank" />
                </div>
            </label>
        </div>

        <div class="field">
            <label class="label">
                <IconText icon="fal fa-file-invoice-dollar" text="Account Number" />
                <div class="control">
                    <input type="number" v-model="config.confSenderAccountNumber" class="input"
                        placeholder="e.g. 123456789" />
                </div>
            </label>
        </div>
        <div class="field">
            <label class="label">
                <IconText icon="fal fa-money-check-edit" text="Remitter" />
                <div class="control">
                    <input type="text" v-model="config.confRemitter" class="input" placeholder="e.g. Prime Hire" />
                </div>
            </label>
        </div>
    </template>
    <ButtonIconText @clicked="saveConfig" class="is-primary" style="margin-top: auto;" icon="fal fa-save" text="Save" />
</template>

<script lang="ts" setup>
import IconText from '@/components/elements/IconText.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
import Tabs, { ITabObject } from '@/components/elements/Tabs.vue';
import { ref, onMounted, Ref } from 'vue';
import { doRequest, user } from "./../../data/state";
import Tooltip from '@/components/elements/Tooltip.vue';
import { IDefaultSnackbarResponse } from '@/types/user';
import { formatDurationMS, showModal } from '@/generalFunctions';

interface configSettings {
    confSMSTemplatePendingInvite: string,
    confOvernightRatePercent: number,
    confStartOvernight: string,
    confEndOvernight: string,
    confStartExtraRate: string,
    confExtraRatePercent: number,
    confEndOvernightNextDay: number | boolean,
    confSenderBSB: string,
    confSenderAccountNumber: number,
    confRemitter: string,
    confBank: string,
    confFinishJobEmailSubject: string,
    confFinishJobEmailText: string,
    confInviteJobEmail: string,
    // confAllowExternal: number,
    confReplyEmailRegistrantTemplate: string;
    confNotificationRegistrantPhone: string;
    confNotificationRegistrantEmail: string;
}

const config: Ref<configSettings> = ref({
    confAllowExternal: 0,
    confBank: "",
    confRemitter: "",
    confSenderBSB: "",
    confSenderAccountNumber: 0,
    confStartOvernight: '',
    confEndOvernight: '',
    confEndOvernightNextDay: 0,
    confExtraRatePercent: 0,
    confOvernightRatePercent: 0,
    confFinishJobEmailSubject: "",
    confFinishJobEmailText: "",
    confNotificationRegistrantEmail: "",
    confNotificationRegistrantPhone: "",
    confReplyEmailRegistrantTemplate: "",
    confInviteJobEmail: "",
    confSMSTemplatePendingInvite: "",
    confStartExtraRate: '',
} as configSettings);

const tabs: ITabObject[] = [
    { id: 'templates', text: 'Templates' },
    { id: 'jobs', text: 'Job Settings' },
    { id: 'bank', text: 'Bank Settings' }
];

const selectedTab = ref('templates');

function showErrorModal(error: string) {
    showModal({ error: true, snackbar: { active: true, color: 'primary', timeout: 10000, text: error } })
}
async function saveConfig() {
    const confStartExtraRate = config.value.confStartExtraRate.split(':')
    if (confStartExtraRate.length !== 2) showErrorModal('Invalid start extra rate time')
    const confStartExtraRateMS = Number(confStartExtraRate[0]) * 60 * 60 * 1000 + Number(confStartExtraRate[1]) * 60 * 1000
    const confStartOvernight = config.value.confStartOvernight.split(':')
    if (confStartOvernight.length !== 2) showErrorModal('Invalid start overnight time')
    const confStartOvernightMS = Number(confStartOvernight[0]) * 60 * 60 * 1000 + Number(confStartOvernight[1]) * 60 * 1000
    const confEndOvernight = config.value.confEndOvernight.split(':')
    if (confEndOvernight.length !== 2) showErrorModal('Invalid end overnight time');
    const confEndOvernightMS = Number(confEndOvernight[0]) * 60 * 60 * 1000 + Number(confEndOvernight[1]) * 60 * 1000
    const json = await doRequest<IDefaultSnackbarResponse>("/api/misc/setConfigCompany", "POST",
        {
            ...config.value,
            confStartExtraRate: confStartExtraRateMS,
            confStartOvernight: confStartOvernightMS,
            confEndOvernight: confEndOvernightMS,
            confEndOvernightNextDay: config.value.confEndOvernightNextDay ? 1 : 0
        }
    );
    showModal(json)
}

onMounted(async () => {
    config.value = await doRequest<configSettings>("/api/misc/getConfigCompany", "GET", undefined);
    config.value.confEndOvernightNextDay = config.value.confEndOvernightNextDay == 1;
    config.value.confStartExtraRate = formatDurationMS(Number(config.value.confStartExtraRate), true)
    config.value.confStartOvernight = formatDurationMS(Number(config.value.confStartOvernight), true)
    config.value.confEndOvernight = formatDurationMS(Number(config.value.confEndOvernight), true)
})

</script>

<style lang="scss" scoped></style>