import { IAvailablePermissions } from '@/data/state'
import { UserPage } from './global'
import { statusCrew } from './jobs'

export const enum EFilterType {
  checkbox = 0,
  text = 1,
  combobox = 2,
}

export interface IAvailableCrewFilter {
  id: number
  description: string
  valuecombo: string
  type: EFilterType
  valuecomboparsed: string[]
}
export interface IAvailableCrew {
  id: number
  fullName: string
  email: string
  phone: string
  active: boolean
  hasCV: number
  subgroups: string
  filterValuesUnparsed: string
  filterValues: { id: number; value: string[] | string }[]
  state: 'VIC' | 'NSW' | 'QLD' | 'ACT' | 'WA' | 'NT' | 'SA' | 'TAS'
}

export interface IAvailableCrewSelect extends IAvailableCrew {
  selectedOption: statusCrew
}

export interface ILoginResponse {
  error: boolean
  snackbar: ISnackbar
  user: IUser
  userPages: Array<UserPage>
  redirect: string
  token: string
}
export interface IDefaultSnackbarResponse {
  error: boolean
  snackbar: ISnackbar
}
export interface ISnackbar {
  active: boolean
  color: string
  text: string
  timeout: number
  icon?: string
  iconColor?: string
}

export interface IWorkers extends Record<string, any> {
  id: number
  picture?: string
  fullName?: string
}

export interface IUser {
  abn: string
  address: string
  bankAccountBSB: string
  bankAccountName: string
  bankAccountNumber: string
  bankName: string
  birthday: number
  city: string
  email: string
  emergencyName: string
  emergencyNumber: string
  firstName: string
  id: number
  lastName: string
  password: string
  phone: string
  picture: string
  postcode: string
  registeredDate: number
  state: string
  suburb: string
  token?: string
  permissions?: IAvailablePermissions[]
  managerOf: string
  filter?: { idFilter: number; value: string | string[] }[]
}

export interface IUserAdmin {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  abn: string
  address: string
  password: string
  // birthday: number;
  postcode: string
  emergencyNumber: string
  emergencyName: string
  suburb: string
  city: string
  state: string
  bankAccountBSB: string
  bankAccountNumber: string
  bankName: string
  bankAccountName: string
  groups: Array<{
    id: number
    groupName: string
    rate: number
  }>
  defaultRate: number
  deleteUser: boolean
  subgroups: string
  filter: { idFilter: number; value: string | string[] }[]
}

// export interface userProfile extends Record<string, any> {
//   abn: string
//   address: string
//   bankAccountBSB: string
//   bankAccountName: string
//   bankAccountNumber: string
//   bankName: string
//   birthday: number | string
//   city: string
//   email: string
//   emergencyName: string
//   emergencyNumber: string
//   firstName: string
//   id: number
//   lastName: string
//   password?: string
//   phone: string
//   picture: string
//   postcode: string
//   registeredDate: number
//   state: string
//   suburb: string
// }

export interface IOnSiteResponsible {
  id: number
  onSiteResponsibleName: string
  onSiteResponsibleNumber: string
}
