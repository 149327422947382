<template>
    <div class="box" style="display: flex; flex-direction: column; align-items: start; min-height: 330px; justify-content: space-between;">
        <div style="width: 100%;">
            <Avatar :src="src" :size="48" :name="props.request.fullName" :fontSize="'1.3em'"></Avatar>
            <table class="table">
                <tbody>
                    <tr>
                        <th><i class="fal fa-briefcase" /></th>
                        <td>{{ props.request.jobName }}</td>
                    </tr>
                    <tr>
                        <th><i class="fal fa-users" /></th>
                        <td>{{ props.request.groupName }}</td>
                    </tr>
                    <tr>
                        <th><i class="fal fa-calendar" /></th>
                        <td>{{ formatDateTime('DD/MM/YY hh:mm:ss xx', props.request.startTime) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="width: 100%;">
            <p class="buttons" v-if="displayText.length == 0">
                <ButtonIconText class="is-roundish" icon="fal fa-check" @clicked="acceptJob(true)" :isLoading="isLoading" text="Accept" />
                <ButtonIconText class="is-roundish" icon="fal fa-times" @clicked="acceptJob(false)" :isLoading="isLoading" text="Decline" />
            </p>
            <span class="results" v-if="displayText.length > 0">{{ displayText }}</span>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { formatDateTime } from '@/generalFunctions';
import { computed, ref } from "vue";
import { acceptJobAsync, doRequest, workers } from '../data/state';
import { IJobRequest } from '../types/jobs';
import Avatar from './elements/Avatar.vue';
import ObjectTable from './elements/ObjectTable.vue';
import ButtonIconText from './elements/ButtonIconText.vue';
const props = defineProps<{
    request: IJobRequest
}>();

const isLoading = ref(false);

const emit = defineEmits<{
    'chatClick': []
}>();

const displayText = ref("");

async function acceptJob(acceptCandidate: boolean){
    isLoading.value = true;
    let data = await doRequest<{error: boolean, snackbar: Record<string, any>}>("/api/user/replyInviteJob","POST",{
        accept: acceptCandidate,
        jobId: props.request.jobId,
        idUser: props.request.idUser,
        idReqCrew: props.request.idReqCrew
    });
    if(data.error){
        alert("Error\n\n" + data.snackbar.text);
    }else{
        if(acceptCandidate){
            displayText.value = "Accepted";
        }else{
            displayText.value = "Declined";        
        }
    }
    isLoading.value = false;
}

const src = computed(() => {
    const worker = workers.value.find(item => item.id == props.request.idUser);
    if(worker != undefined && worker.picture !== undefined){
        return worker.picture;  
    }else{
        return '';
    }
});

</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";

.results{
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    background-color: $primary-light;
    border-color: $primary;
    padding-left: 5px;
    padding-right: 5px;
}
.box{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 310px;
    max-width: 40%;
    @include mobile{
        width: 100%;
        max-width: 100%;            
    }
    .table .fal{
        color: $primary;
    }

    .buttons {
        width: 100%;
        align-self: center;

        .button{
            @extend .is-small;
        }

        :deep(.button){
            flex-grow: 1 !important;
            border-radius: 10px !important;
        }
    }
}
</style>