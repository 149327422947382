import {
  detectOS,
  isAppInstalled,
  normalizeMinutes,
  parseJwt,
} from '@/generalFunctions'
import { hex2rgba } from '@/scripts/colors'
import { UserPage, setSnackbar } from '@/types/global'
import { IGroup } from '@/types/groups'
import { ILogItem } from '@/types/logs'
import { IPrepends } from '@/types/prepends'
import {
  ISaveSignatureTimes,
  IUserTimeCheck,
  IUserTimeList,
} from '@/types/timesheet'
import { Ref, computed, ref } from 'vue'
import { IAdminChatResponse, IChatResponse } from '../types/chat'
import {
  IDashboardAdmin,
  IFullDashboard,
  IGetJobSupervisor,
  IJobComplete,
  IJobCompleteClocks,
  IJobExtraData,
  IJobFile,
  ILoadTimeSheets,
  IPictureData,
  IjobDetails,
  TypeUpdateSetTime,
} from '../types/jobs'
import { INotificationPayload } from '../types/notifications'
import { ITodoList } from '../types/todo'
import {
  EFilterType,
  IAvailableCrew,
  IAvailableCrewFilter,
  IAvailableCrewSelect,
  IDefaultSnackbarResponse,
  ILoginResponse,
  IUser,
  IUserAdmin,
  IWorkers,
} from '../types/user'
type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'MERGE'
export const _user: Ref<IUser | null> = ref(null)

export const user = computed<IUser | null>(() => {
  if (_user.value === null) {
    //try to load from localStorage
    let userJson = localStorage.getItem('user')
    if (userJson !== null) {
      let value = JSON.parse(userJson)
      _user.value = value

      const token = parseJwt(value.token as string)
      //check if token is expired

      if (Number(token.exp) * 1000 < new Date().valueOf()) {
        localStorage.removeItem('user')
        window.location.href = '/'
        return null
      }
      if (window.location.pathname === '/') {
        try {
          if (_user.value?.permissions?.length === 0) {
            window.location.href = '/jobs'
          } else {
            window.location.href = '/admin/dashboard'
          }
        } catch (error) {
          localStorage.removeItem('user')
          window.location.href = '/'
          return null
        }
        return null
      }
      setTimeout(verifyPWA, 2000)
      return value
    }
    if (window.location.pathname.toLowerCase().startsWith('/public'))
      return null
    if (window.location.pathname !== '/') window.location.href = '/'
    return null
  } else {
    return _user.value
  }
})
interface IFetchApiConfig {
  method: HttpMethods
  headers: Record<string, string>
  body: string
}

const _arrayPicture: Ref<IPictureData[]> = ref([])
export const arrayPicture = computed(() => {
  return _arrayPicture.value
})

// export async function refreshLoadClockInSingleJobAsync(idJob: number) {
//   const clockinsRequest = await fetch(
//     `${process.env.VUE_APP_API_SERVER}/api/user/getavailableclockin/${idJob}`,
//     {
//       headers: {
//         Authorization: (user.value as IUser).token as string,
//       },
//       cache: 'no-store',
//     }
//   )
//   const data = (await clockinsRequest.json()) as IAvailableClockins
//   if (data.jobs.length !== 1) return //should always be 1
//   const found = _clockins.value?.some((el, index, array) => {
//     if (el.id === idJob) {
//       array.splice(index, 1, data.jobs[0])
//       return true
//     }
//     return false
//   })

//   if (!found) _clockins.value?.push(data.jobs[0])
// }

let _supervisedJobs: Ref<IJobComplete[] | null> = ref(null)
// export let supervisedJobs = computed(() => {
//   if (_supervisedJobs.value != null) {
//     return _supervisedJobs.value as jobComplete[]
//   } else {
//     // setTimeout(async () => {
//     loadSupervisedJobsAsync()
//     // }, 100)
//     return null
//   }
// })

// export async function loadSupervisedJobsAsync() {
//   const response = await fetch(
//     process.env.VUE_APP_API_SERVER + '/api/job/getjobSupervisor',
//     {
//       method: 'POST',
//       headers: {
//         Authorization: (user.value as IUser).token as string,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({}),
//     }
//   )
//   let data = (await response.json()) as IGetJobSupervisor
//   _supervisedJobs.value = data.jobs
//   // data.jobs.forEach(async (item) => await loadTimeSheetsAsync(item.id))
// }

export async function loadTimeSheetsAsync(id: number) {
  const results = await doRequest<ILoadTimeSheets>(
    '/api/time/getJobTimesheet',
    'POST',
    { idJob: id }
  )
  if (_supervisedJobs.value != null) {
    const existing = _supervisedJobs.value.find((item) => item.id === id)
    if (existing !== undefined) {
      existing.timesheets = results
    }
  }
  return
}

export async function loadAllJobsAsync(dateStart: number, dateEnd: number) {
  const data = await doRequest<{ jobs: IJobComplete[] }>(
    '/api/job/getJobs',
    'POST',
    {
      dateEnd: dateEnd,
      dateStart: dateStart,
      idJob: 0,
    }
  )
  data.jobs.forEach((el) => {
    el.color = hex2rgba(el.color)
  })
  return data
}

const _publicJobs: Ref<IJobComplete[]> = ref([])

export const publicJobs = computed(() => _publicJobs.value)

const _isPublicJobsLoading: Ref<boolean> = ref(false)

export const isPublicJobsLoading = computed(() => _isPublicJobsLoading.value)

export async function loadPublicJobs(startDate: Date, endDate: Date) {
  _isPublicJobsLoading.value = true
  const data = await doRequest<IGetJobSupervisor>(
    '/api/job/getPublicJobs',
    'POST',
    {
      dateEnd: endDate.valueOf(),
      dateStart: startDate.valueOf(),
      idJob: 0,
    }
  )

  data.jobs.forEach((item) => {
    item.color = hex2rgba(item.color)

    const found = _publicJobs.value.findIndex(
      (existing) => existing.id == item.id
    )

    if (found == -1) {
      _publicJobs.value.push(item)
    } else {
      _publicJobs.value[found] = item
    }
  })
  _isPublicJobsLoading.value = false
  return _publicJobs.value
}

export interface IInvoice {
  cancelled: number
  endDate: number
  fullName: string
  idInvoice: number
  startDate: number
  status: number
  total: number
  pdf?: {
    data: number[]
    type: string
  }
}

export interface ABADownload {
  abaFile: string
  xlsxFile: string
  error: boolean
}

export interface IInvoiceCheckBox extends IInvoice {
  checked: boolean
}

export const enum StatusInvoice {
  clear = 0,
  oneDollarGenerated, // not needed
  fullGenerated, // admin of his stuff
}

export interface IDetailInvoice extends IInvoice {
  pdf: {
    data: Array<number>
    type: string
  }
}

export async function loadInvoicesAsync(
  startDate: Date,
  endDate: Date,
  invoiceId?: number | undefined | null
): Promise<IInvoice[]> {
  if (invoiceId == undefined || invoiceId == null) {
    invoiceId = 0
  }
  return await doRequest<IInvoice[]>('/api/time/getMyInvoices', 'POST', {
    dateEnd: endDate.valueOf(),
    dateStart: startDate.valueOf(),
    idInvoice: invoiceId,
  })
}

const _crewfilter: Ref<IAvailableCrewFilter[] | null> = ref(null)
export async function getCrewFilter() {
  if (_crewfilter.value == null) {
    const response = await doRequest<IAvailableCrewFilter[]>(
      '/api/user/getAvailableCrewFilter',
      'POST',
      {}
    )
    response.forEach((el) => (el.valuecomboparsed = el.valuecombo.split(';')))
    return response
  }
  return _crewfilter.value
}

let _availableCrew: Ref<IAvailableCrew[] | null> = ref(null)
export const isAvailableCrewLoading = ref(false)

export let availableCrew = computed(() => {
  if (_availableCrew.value == null || _availableCrew.value.length === 0) {
    updateAvailableCrew()
  }
  return _availableCrew.value
})

export let filtertypes = computed(() => {
  if (_availableCrew.value == null || _availableCrew.value.length === 0) {
    updateAvailableCrew()
  }
  return _availableCrew.value
})

export async function updateAvailableCrew(
  state: string = 'All',
  pcode: string = ''
) {
  isAvailableCrewLoading.value = true
  const response = await doRequest<IAvailableCrew[]>(
    '/api/user/getAvailableCrew',
    'POST',
    {
      activeStatus: [true],
      state, //get from all states
      pcode,
    }
  )
  let filters = await getCrewFilter()
  response.forEach(async (el) => {
    el.filterValues = []
    let availableFilters = el.filterValuesUnparsed.split(',')
    availableFilters.forEach(async (el2) => {
      if (el2 == '') return
      let idvalue = el2.split(':')
      let foundFilter = filters.find((el3) => el3.id == Number(idvalue[0]))
      if (!foundFilter) return
      if (foundFilter.type == EFilterType.combobox) {
        el.filterValues.push({
          id: Number(idvalue[0]),
          value: idvalue[1].split(';'),
        })
      } else {
        el.filterValues.push({
          id: Number(idvalue[0]),
          value: idvalue[1],
        })
      }
    })
  })
  // export interface IAvailableCrew {
  //   id: number
  //   fullName: string
  //   email: string
  //   phone: string
  //   active: boolean
  //   hasCV: number
  //   subgroups: string
  //   filterValuesUnparsed: string
  //   filterValues: { id: number; value: string[] }[]
  //   state: 'VIC' | 'NSW' | 'QLD' | 'ACT' | 'WA' | 'NT' | 'SA' | 'TAS'
  // }
  _availableCrew.value = response

  isAvailableCrewLoading.value = false
}
//LAZY LOAD IMPLEMENTATION
let _prepends: Ref<IPrepends[] | null> = ref(null)

export let prepends = computed(() => {
  if (_prepends.value == null) {
    doRequest<{ prepends: IPrepends[] }>(
      '/api/job/getAvailablePrepend',
      'GET',
      undefined
    ).then((json) => {
      _prepends.value = json.prepends
    })
  }
  return _prepends.value
})

let _pages: Ref<UserPage[]> = ref([])
export let pages = computed(() => {
  if (_pages.value != null) {
    if (_pages.value.length === 0) {
      const pagesJson = localStorage.getItem('userpages')
      if (pagesJson !== null) {
        //Needed to avoid recursion on setter
        setTimeout(
          (json: string) => {
            _pages.value = JSON.parse(json) as UserPage[]
          },
          100,
          pagesJson
        )
      }
    }
    return _pages.value
  } else {
    return []
  }
})

const _profile: Ref<IUser | null> = ref(null)

export const profile = computed(() => {
  if (_profile.value === null) {
    setTimeout(async () => {
      const userData = await doRequest<IUser>(
        '/api/user/getMe',
        'GET',
        undefined
      )
      console.log('User', user.value)
      let userId = localStorage.getItem('userId')
      if (userId != null && userId != '' && userId != undefined) {
        userData.id = parseInt(userId)
      }
      _profile.value = userData
    }, 100)
    return null
  }
  return _profile.value
})

export const showPopupInstall = ref(false)
export const showPopupPush = ref(false)

export async function requestNotificationPermission() {
  if ('Notification' in window) {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      showPopupPush.value = false
      verifyPWA()
    }
  }
}

export async function verifyPWA() {
  try {
    const currentOS = detectOS()
    let NotificationPermission = ''
    if (isAppInstalled()) {
      showPopupInstall.value = false
      if (!('Notification' in window)) {
        //Browser doesnt support it
        showPopupPush.value = false
        return
      }
      NotificationPermission = Notification.permission
      if (
        NotificationPermission === 'default' ||
        NotificationPermission === 'denied'
      ) {
        showPopupPush.value = true
      }
    } else {
      if (currentOS !== 'Other') {
        showPopupInstall.value = true
      }
    }

    let registerFirebase = true
    if (NotificationPermission !== 'granted') registerFirebase = false
    if (currentOS === 'iOS' && !isAppInstalled()) registerFirebase = false
    if (window.location.hostname === 'localhost') registerFirebase = true
    if (registerFirebase) {
      doFirebaseRegister()
    }
  } catch (error) {
    console.log(error)
  }
}

export const topNotification = ref('')

async function doFirebaseRegister() {
  if (firebaseApp !== undefined) {
    //already initialized because its using VAR (global)
    return
  }

  if ('default' == Notification.permission) {
    Notification.requestPermission()
  }

  if (
    'denied' == Notification.permission ||
    'default' == Notification.permission
  ) {
    return
  }

  const firebaseConfig = {
    apiKey: 'AIzaSyC7ekuGlWdr8sWkWBcMzlerN1xf6qkySco',
    authDomain: 'pushprimehire.firebaseapp.com',
    projectId: 'pushprimehire',
    storageBucket: 'pushprimehire.appspot.com',
    messagingSenderId: '33985431746',
    appId: '1:33985431746:web:20f55bd458562149b0535b',
    measurementId: 'G-0FJH4J4C16',
  }

  // Initialize Firebase
  var firebaseApp = (window as any).firebase.initializeApp(firebaseConfig)
  const vapidKey =
    'BOV2Epb9cmJeyKzg8EjW82Vj1DorCB0S5UD2-0iukLWLr9IB1Y_V1xuYrh--TuGaMUJiK5KBApNvIKhpYpG8fxw'
  const messaging = (window as any).firebase.messaging()
  messaging.onMessage(async (payload: any) => {
    console.log('Message received', payload)
    if (payload.data.title) topNotification.value = payload.data.title
    // If native notification:
    // let worker:ServiceWorkerRegistration|undefined;
    // const el = await navigator.serviceWorker.getRegistrations()
    //   if (!el) return;
    //   el.forEach(el2=>{
    //       if (el2 && el2.active && el2.active.state==='activated' && el2.active.scriptURL==='https://app.primehire.com.au/firebase-messaging-sw.js'){
    //         worker = el2;
    //       }
    //   });

    // if (!worker) return;
    // if (payload.data.doCloseAll){
    //   worker.getNotifications().then(el3=>{
    //     el3.forEach(el4=>{
    //       el4.close()
    //     })
    // })
    //   return;
    // }
    // if (payload.data.doClose){
    //   //check on tag and close just if tag matches
    //   worker.getNotifications().then(el3=>{
    //     el3.forEach(el4=>{
    //      if (el4.tag===payload.data.tag)
    //       el4.close()
    //     })
    // })
    //   return;
    // }

    // const notificationTitle = payload.data.title;
    // const notificationOptions = payload.data;
    // notificationOptions.data = notificationOptions;
    // worker.showNotification(notificationTitle, notificationOptions);//this notification can't be clicked but can be closed
    // //new Notification(notificationTitle, notificationOptions); // this notification can be clicked but can't be closed by serviceWorker (might be better)
  })
  const token = await messaging.getToken({ vapidKey })
  console.log(token)
  const response = await doRequest<any>(
    '/api/user/setNotificationToken',
    'POST',
    {
      token,
    }
  )
}

export async function loadUserAsync(
  email: string | undefined,
  password: string | undefined,
  hashLogin: string | undefined
) {
  //do not use dorequest on this function, it is called while unauthenticated
  if (email && password) {
    const response = await fetch(
      `${process.env.VUE_APP_API_SERVER}/api/auth/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }
    )

    let data = (await response.json()) as ILoginResponse
    if (data.error) return data
    //copy token to user object
    data.user.token = data.token
    const parsedToken = parseJwt(data.token) as any
    data.user.permissions = parsedToken.permissions
    data.user.managerOf = parsedToken.managerOf
    localStorage.setItem('user', JSON.stringify(data.user))
    localStorage.setItem('userId', data.user.id.toString())
    _user.value = data.user
    _pages.value = data.userPages
    //console.log("pages", data.userpages);

    const userRequest = await fetch(
      `${process.env.VUE_APP_API_SERVER}/api/user/getMe`,
      { headers: { Authorization: (user.value as IUser).token as string } }
    )
    const userData = (await userRequest.json()) as IUser
    _profile.value = userData
    return data
  } else {
    const response = await fetch(
      `${process.env.VUE_APP_API_SERVER}/api/auth/otllogin/${hashLogin}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    let data = (await response.json()) as ILoginResponse
    if (data.error) return data
    //copy token to user object
    data.user.token = data.token
    const parsedToken = parseJwt(data.token) as any
    data.user.permissions = parsedToken.permissions
    data.user.managerOf = parsedToken.managerOf
    localStorage.setItem('user', JSON.stringify(data.user))
    localStorage.setItem('userId', data.user.id.toString())
    _user.value = data.user
    _pages.value = data.userPages
    //console.log("pages", data.userpages);

    const userRequest = await fetch(
      `${process.env.VUE_APP_API_SERVER}/api/user/getMe`,
      { headers: { Authorization: (user.value as IUser).token as string } }
    )
    const userData = (await userRequest.json()) as IUser
    _profile.value = userData
    return data
  }
}

export async function updateUserProfileAsync(profile: IUser) {
  if (!profile.password) profile.password = ''
  const birthday = new Date(profile.birthday).valueOf()
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/user/saveProfile2',
    'POST',
    { ...profile, birthday }
  )
}

export async function createProfile(profile: IUser, hash: string) {
  return await doUnauthenticatedRequest<IDefaultSnackbarResponse>(
    '/api/public/createProfile',
    'POST',
    { ...profile, hash }
  )
}

export async function adminSaveProfile(profile: IUserAdmin) {
  if (!profile.password) profile.password = ''

  return await doRequest<IDefaultSnackbarResponse>(
    '/api/user/saveUserDetails',
    'POST',
    profile
  )
}

// let _myJobs: Ref<IJob[] | null> = ref(null)

// export let myJobs = computed(() => {
//   if (_myJobs.value == null) {
//     setTimeout(loadUserDashboardAsync, 100)
//     return []
//   } else {
//     return _myJobs.value
//   }
//})

// let _availableJobs: Ref<IFutureJobs[] | null> = ref(null)

// export let availableJobs = computed(() => {
//   if (_availableJobs.value == null) {
//     //TODO:
//     // setTimeout(loadUserDashboardAsync, 100)

//     return []
//   } else {
//     return _availableJobs.value
//   }
// })

//TODO:
// async function loadUserDashboardAsync() {
//   if (user.value != null && user.value.token != null) {
//     let token = user.value?.token
//     if (typeof token === 'undefined') {
//       throw new Error("Token doesn't exist")
//     } else {
//       const results = await fetch(
//         `${process.env.VUE_APP_API_SERVER}/api/job/getMyDashboard`,
//         { headers: { Authorization: token } }
//       )
//       const json = (await results.json()) as IDashboardResponse
//       _myJobs.value = json.myJobs
//       _availableJobs.value = json.futureAvailableJobs

//       // const clockinsRequest = await fetch(`${process.env.VUE_APP_API_SERVER}/api/user/getavailableclockin`, { headers: { "Authorization": token } });
//       // _clockins.value = await clockinsRequest.json() as IAvailableClockins;
//     }
//   }
// }

export async function generateInvoices(
  idUser: number,
  dateStart: number,
  dateEnd: number
) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/time/generateInvoice',
    'POST',
    {
      idUser,
      dateStart,
      dateEnd,
    }
  )
}

export async function createNewJob() {
  const offset = new Date().getTimezoneOffset() * 60 * 1000
  const jobDate = normalizeMinutes(new Date().valueOf() - offset)
  return await doRequest<IDefaultSnackbarResponse & { jobId: number }>(
    '/api/job/insertNewJobs',
    'POST',
    {
      job: {
        id: -1,
        reqCrew: [],
        prepend: user.value!.managerOf,
        title: 'Job Preview',
        description: 'Job Description',
        date: 0,
        multipleDates: [jobDate],
        state: 1,
        hidePublic: false,
        onSiteResponsibleName: '',
        onSiteResponsibleNumber: '',
        location: '',
        status: 1,
        color: '#90a4ae',
        selectedSupervisors: [],
        emailTimesheet: [],
      },
    }
  )
}

export async function getJobOptionsAsync() {
  if (user.value) {
    const data = await doRequest<IJobExtraData>(
      'https://backend2.primehire.com.au/api/job/getAvailableOptions',
      'POST',
      {
        prepend: '',
        state: 1,
      }
    )
    return data
  }
}

export async function getJobDetailsAsync(id: number) {
  const data = await doRequest<{ job: IJobComplete; extraData: IJobExtraData }>(
    '/api/job/getJobDialog',
    'POST',
    { idJob: id }
  )

  if (data.job.color.length === 9) {
    //color picker wants 7 characters (when you chain click on it, it becomes black if it is not like this)
    data.job.color = data.job.color.substring(0, 7)
  }
  // data.job.color = hex2rgba(data.job.color);
  data.job.hidePublic = Boolean(data.job.hidePublic) //convert the number that comes to boolean
  return data
}

export async function saveJob(job: IJobComplete) {
  const data = await doRequest<
    {
      usersWithDoubleShift: {
        id: number
        name: string
      }[]
    } & IDefaultSnackbarResponse
    //v2 ignore the crew list, but needs reqCrew
  >('/api/job/saveUpdatedJobv2', 'POST', {
    job: { ...job, multipleDates: [], hidePublic: job.hidePublic ? 1 : 0 },
  }) //compatibility with old api
  return data
}

export async function saveJobUserList(
  idReqCrew: number,
  statusUser: IAvailableCrewSelect
) {
  const data = await doRequest<
    {
      isOverfilled: boolean
      UserhasDoubleShift: boolean
    } & IDefaultSnackbarResponse
  >('/api/job/saveJobUserList', 'POST', {
    idReqCrew,
    statusUser,
  })
  return data
}

export let workers: Ref<IWorkers[]> = ref([])

export async function sendChat(
  id: number,
  lastUpdate: number,
  messsage: string
) {
  const payload = { idJob: id, lastUpdate: lastUpdate, message: messsage }
  return await doRequest<IChatResponse>('/api/job/sendchat', 'POST', payload)
}

export async function loadChatsAsync(id: number) {
  return await doRequest<IChatResponse>('/api/job/getchat', 'POST', {
    idJob: id,
    limit: 20,
    lastUpdate: -1,
    next: true,
  })
}

export async function doClock(id: number, code: string) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/time/doclock',
    'POST',
    { id: id, code: code }
  )
}

export async function getInvoiceDetails(idInvoice: number) {
  const data = await doRequest<IDetailInvoice[]>(
    '/api/time/getInvoice',
    'POST',
    {
      dateStart: 0,
      dateEnd: 0,
      idInvoice,
    }
  )
  return data
}

export async function getABAFileData(
  listInvoicesID: number[],
  dateABA: string,
  typeABA: number
) {
  const data = await doRequest<ABADownload & IDefaultSnackbarResponse>(
    '/api/misc/downloadABA',
    'POST',
    {
      downloadList: listInvoicesID,
      dateABA,
      typeABA,
    }
  )
  return data
}

export async function getInvoices(dateStart: number, dateEnd: number) {
  const data = await doRequest<IInvoice[]>('/api/time/getInvoice', 'POST', {
    dateStart,
    dateEnd,
    idInvoice: 0,
  })
  return data
}

export async function deleteInvoice(idInvoice: number) {
  const data = await doRequest<IDefaultSnackbarResponse>(
    '/api/time/deleteInvoice',
    'POST',
    {
      idInvoice: idInvoice,
    }
  )
  return data
}

export async function generateTimesheetJob(idJob: number) {
  const data = await doRequest<{
    duplicateTime: []
    error: boolean
    pdfData: string
  }>('/api/time/generateTimesheetJob', 'POST', {
    idJob: [idJob],
    invoiceMarkJobAsFinished: false,
  })
  return data
}

async function getNotificationsAsync() {
  try {
    if (!user.value) return
    const data = await doRequest<INotificationPayload>(
      '/api/user/getNotifications',
      'GET',
      undefined
    )

    data.total = function (): number {
      return (
        this.jobsAvailable.length +
        this.listCrewInvite.length +
        this.listInviteCompany.length +
        this.unreadMessages.length
      )
    }
    _notification.value = data
  } catch (error) {
    return //ignore error display when connection oscilate.
  }
}

let _notification: Ref<INotificationPayload | null> = ref(null)
let _notificationInterval: number | null = null
export const notifications = computed(() => {
  if (_notification.value === null) {
    getNotificationsAsync()
    if (_notificationInterval !== null) clearInterval(_notificationInterval)
    _notificationInterval = setInterval(getNotificationsAsync, 10000)
  }
  return _notification.value
})

const _groups = ref<IGroup[]>([])

export const groups = computed(() => {
  if (_groups.value.length === 0) {
    setTimeout(getGroupsAsync, 10)
  }
  return _groups.value
})

async function getGroupsAsync() {
  _groups.value = await doRequest<IGroup[]>('/api/misc/getGroups', 'POST', {
    forceAll: true,
  })
}

interface IState {
  id: number
  name: string
}

const _states: Ref<Array<IState>> = ref<IState[]>([])

export const states = computed(() => {
  if (_states.value.length === 0) {
    setTimeout(getStatesAsync, 10)
  }
  return _states.value
})

async function getStatesAsync() {
  let data = await doRequest<IState[]>(
    '/api/public/getstates',
    'GET',
    undefined
  )
  _states.value = data.filter((item) => item.id !== 0)
}

export async function applyForJobAsync(jobId: number, reqCrew: number) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/job/applyfor',
    'POST',
    { jobId: jobId, reqCrew: reqCrew }
  )
}

export async function acceptJobAsync(
  jobId: number,
  idUser: number,
  reqCrew: number,
  accept: boolean
) {
  return await doRequest<{
    error: boolean
    snackbar: setSnackbar
    usersWithDoubleShift: { id: number; name: string }[]
  }>('/api/job/applyfor', 'POST', { jobId, accept, idUser, reqCrew })
}

export async function addClockSupervisor(idJob: number, idUser: number) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/time/setTimeCreate',
    'POST',
    { idJob, idUser }
  )
}

export async function listJobFiles(idJob: number) {
  return await doRequest<IJobFile>('/api/job/listJobFiles', 'POST', { idJob })
}

export async function setTime(
  idTimesheet: number,
  value: number,
  type: TypeUpdateSetTime,
  force: boolean
): Promise<IDefaultSnackbarResponse> {
  return await doRequest('/api/time/setTime', 'POST', {
    id: idTimesheet,
    value,
    type,
    force,
  })
}

export async function setTimeDelete(idTimesheet: number) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/time/setTimeDelete',
    'POST',
    { id: idTimesheet }
  )
}

export async function downloadFileJobSignature(idFile: number, idJob: number) {
  //Not using DoRequest for files download!
  const response = await fetch(
    process.env.VUE_APP_API_SERVER + '/api/job/downloadFileJobSignature',
    {
      method: 'POST',
      headers: {
        Authorization: (user.value as IUser).token as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idFile: idFile,
        idJob: idJob,
        fullDownload: true,
      }),
    }
  )
  let data = (await response.blob()) as File
  return data
}
// const _clockins: Ref<IClockInAvailable[] | null> = ref(null)
const _dashboard: Ref<IFullDashboard> = ref({
  availableClockin: [],
  invitedIds: [],
  jobs: [],
  supervisedIds: [],
  isLoaded: false,
})

export const dashboard = computed(() => {
  return _dashboard.value
})

export async function getFullDashboard() {
  if (user.value?.managerOf !== '') {
    _dashboard.value = {
      availableClockin: [],
      invitedIds: [],
      jobs: [],
      supervisedIds: [],
      isLoaded: true,
    }
    return
  }
  _dashboard.value = {
    ...(await doRequest<IFullDashboard>(
      '/api/job/getMyDashboardv2',
      'GET',
      undefined
    )),
    isLoaded: true,
  }
}

export async function getJobDetail(idJob: number) {
  return await doRequest<IjobDetails>('/api/job/getJobDetail', 'POST', {
    id: idJob,
  })
}

export const fullDashboard: Ref<IFullDashboard[]> = ref([])

export async function finishJobSignature(
  idJob: number,
  signedBy: string,
  times: ISaveSignatureTimes[],
  signature: string
) {
  const response = await fetch(
    process.env.VUE_APP_API_SERVER + '/api/time/finishJobSignature',
    {
      method: 'POST',
      headers: {
        Authorization: (user.value as IUser).token as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idJob: idJob,
        signedBy: signedBy,
        times,
        signature,
      }),
    }
  )
  return (await response.json()) as IDefaultSnackbarResponse
}

export const backendURL = process.env.VUE_APP_API_SERVER

export async function getAdminDashboardJobs(
  startDate: Date | number,
  endDate: Date | number
) {
  const data = await doRequest<{
    graphData: any
    jobCompleteTimes: Array<IJobCompleteClocks>
    toDoList: Array<ITodoList>
  }>('/api/job/getDashboardJobs', 'POST', {
    dateEnd: typeof endDate === 'number' ? endDate : endDate.valueOf(),
    dateStart: typeof startDate === 'number' ? startDate : startDate.valueOf(),
  })
  data.jobCompleteTimes.forEach((item) => {
    item.color = hex2rgba(item.color)
  })
  return data
}

export async function getLatestChatAdminAsync() {
  if (user.value?.managerOf !== '') return [] as IAdminChatResponse[]
  const response = await doRequest<IAdminChatResponse[]>(
    '/api/user/getLatestsChatAdmin',
    'POST',
    {
      limit: 5,
      skipIds: [],
    }
  )
  let data = response
  return data
}

export async function doUnauthenticatedRequest<T>(
  url: string,
  method: 'POST' | 'GET',
  data: unknown
) {
  if (method === 'GET') {
    const response = await fetch(process.env.VUE_APP_API_SERVER + url, {
      method: 'GET',
    })
    return (await response.json()) as T
  } else {
    const response = await fetch(process.env.VUE_APP_API_SERVER + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return (await response.json()) as T
  }
}

export async function doRequest<T>(
  url: string,
  method: 'POST' | 'GET',
  data: unknown
): Promise<T> {
  try {
    if (method === 'GET') {
      const response = await fetch(process.env.VUE_APP_API_SERVER + url, {
        method: 'GET',
        headers: {
          Authorization: (user.value as IUser)!.token as string,
        },
      })
      if (response.status === 401) {
        //try to re-log and re-do the request
        const email = localStorage.getItem('login_user') || ''
        const password = localStorage.getItem('login_pass') || ''
        if (!email || !password) {
          // if remember password...
          window.location.href = '/'
        }
        const authenticated = await loadUserAsync(email, password, undefined)
        if (!authenticated.error)
          return (await doRequest<T>(url, method, data)) as T //re-do the request
      }
      return (await response.json()) as T
    } else {
      const response = await fetch(process.env.VUE_APP_API_SERVER + url, {
        method: 'POST',
        headers: {
          Authorization: (user.value as IUser).token as string,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (response.status === 401) {
        //try to re-log and re-do the request
        const email = localStorage.getItem('login_user') || ''
        const password = localStorage.getItem('login_pass') || ''
        if (!email || !password) {
          window.location.href = '/'
        }
        const authenticated = await loadUserAsync(email, password, undefined)
        if (!authenticated.error)
          return (await doRequest<T>(url, method, data)) as T //re-do the request
      }
      return (await response.json()) as T
    }
  } catch (error: unknown) {
    console.log(error)
    const payload = {
      endpoint: url,
      method: method,
      payload: data,
      error: '',
      stack: '',
    }
    if (error instanceof Error) {
      payload.error = error.message
      if (error.stack) {
        payload.stack = error.stack
      }
    }
    window.analytics.track('Error: API', payload)
    return null as any as T
  }
}

export async function getJobLogsAsync(id: number) {
  return await doRequest<ILogItem[]>('/api/misc/viewLogJob', 'POST', { id })
}

export async function saveContractSignature(signature: string) {
  return await doRequest<IDefaultSnackbarResponse>(
    '/api/misc/saveSignature',
    'POST',
    { signature }
  )
}

export async function getDashboardAdminData(dateStart: Date, dateEnd: Date) {
  const response = await doRequest<IDashboardAdmin>(
    '/api/job/getDashboardAdminData',
    'POST',
    {
      dateEnd: dateEnd.valueOf(),
      dateStart: dateStart.valueOf(),
    }
  )
  return response
}

export async function getCheckTimeAsync(
  dateStart: Date,
  dateEnd: Date,
  showAllUsers: boolean = false
) {
  const response = await doRequest<IUserTimeCheck[]>(
    '/api/time/getCheckTime',
    'POST',
    {
      dateEnd: dateEnd.valueOf(),
      dateStart: dateStart.valueOf(),
      showAllUsers: showAllUsers,
    }
  )

  let data = response as Record<string, any>[]
  //Fix strings
  data.forEach((item) => {
    item.totalBreak = parseInt(item.totalBreak)
    item.totalWorked = parseInt(item.totalWorked)
  })
  return data as IUserTimeCheck[]
}

export async function getUserTimeCheckAsync(
  idUser: number,
  dateStart: Date,
  dateEnd: Date
) {
  const response = await fetch(
    process.env.VUE_APP_API_SERVER + '/api/time/getUserInterval',
    {
      method: 'POST',
      headers: {
        Authorization: (user.value as IUser).token as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idUser: idUser,
        end: dateEnd.valueOf(),
        start: dateStart.valueOf(),
      }),
    }
  )
  let data = (await response.json()) as IUserTimeList[]
  return data
}

export async function getResumeAsync(id: number) {
  const response = await fetch(
    process.env.VUE_APP_API_SERVER + '/api/user/getResume',
    {
      method: 'POST',
      headers: {
        Authorization: (user.value as IUser).token as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idUser: id,
      }),
    }
  )
  let data = (await response.text()) as string
  return data
}

export type IAvailablePermissions =
  | 'dashboard:read'
  | 'dashboard:write'
  | 'crew:read'
  | 'crew:approve'
  | 'crew:write'
  | 'job:read'
  | 'job:write'
  | 'timesheet:read'
  | 'timesheet:write'
  | 'crewinvoice:read'
  | 'crewinvoice:write'
  | 'settings:read'
  | 'settings:write'
  | 'group:read'
  | 'group:write'
  | 'customer:read'
  | 'customer:write'
  | 'log:read'
