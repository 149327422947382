<template>
    <div class="field" :class="{ 'is-invalid': !isValid }">
        <label class="label">{{ props.label }}</label>
        <div class="control has-icons-right" :class="{ 'has-icons-left': props.icon }">
            <span class="icon is-small is-left">
                <i :class="props.icon"></i>
            </span>
            <textarea v-if="multiline" ref="textarea" class="textarea" :disabled="props.disabled" :type="props.type"
                :pattern="props.pattern" :minlength="props.minlength" :maxlength="props.maxlength"
                :required="props.required" :placeholder="props.label" v-model="value" @blur="setValue"></textarea>
            <input v-else ref="input" class="input" :class="isValid" :disabled="props.disabled" :type="props.type" :pattern="props.pattern"
                :minlength="props.minlength" :maxlength="props.maxlength" :required="props.required"
                :placeholder="props.label" v-model="value" @blur="setValue">
            <template v-if="validationChecked">
                <span v-if="isValid" class="icon is-small is-right is-status">
                    <i class="fas fa-check"></i>
                </span>
                <span v-else class="icon is-small is-right is-status">
                    <i class="fas fa-exclamation-square"></i>
                </span>
            </template>
        </div>
        <p class="help" v-if="props.helpMessage">{{ props.errorMessage }}</p>
        <p class="help is-danger" v-else-if="!isValid && props.errorMessage">{{ props.errorMessage }}</p>
    </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue';

const value = defineModel({ default: '' });
const isValid  = defineModel('isValid', { default: true });

//PREVENT VALIDATION ON INITIAL LOAD
const validationChecked = ref(false);

const props = defineProps<{
    icon?: string,
    label?: string,
    required?: boolean,
    multiline?: boolean,
    minlength?: number,
    maxlength?: number,
    helpMessage?: string,
    errorMessage?: string,
    pattern?: string,
    type?: string,
    disabled?: boolean
}>();

const input = ref<HTMLInputElement | null>(null);
const textarea = ref<HTMLTextAreaElement | null>(null);
const form = ref<HTMLFormElement | null>(null);

const isEmpty = computed(() => value.value === '' || value.value === undefined);


// watch(value, () => {
//     if (value.value !== undefined) {
//         if (checkValidity()) {
//             //emit('update:modelValue', value.value);
//         }
//     }
// });

// watch(() => modelValue, checkValidity);
// watch(input, checkValidity);
// watch(textarea, checkValidity);


function setValue() {
    if (input && input.value !== null && input.value.value !== '') {
        if(isValid.value){
            isValid.value = input.value.checkValidity();
        }
    }
    if (textarea && textarea.value !== null && textarea.value.value !== '') {
        if(isValid.value){
            isValid.value = textarea.value.checkValidity();
        }
    }
    console.log("checkValidity", isValid.value);
    validationChecked.value = true;
}

// function checkValidity() {
//     if (input && input.value !== null && input.value.value !== '') {
//         isValid.value = input.value.checkValidity();
//     }
//     if (textarea && textarea.value !== null && textarea.value.value !== '') {
//         isValid.value = textarea.value.checkValidity();
//     }
//     console.log("checkValidity", isValid.value);
//     validationChecked.value = validationChecked.value + 1;
//     return isValid.value;
// }

</script>
<style lang="scss" scoped>
@import "../../styles/theme.scss";

.field:not(.is-invalid) {
    .is-status {
        display: inline-flex;
        color: $success !important;
    }

    textarea:focus+span.icon,
    input:focus+span.icon {
        display: none;
    }

}


.field.is-invalid {
    .is-status {
        display: inline-flex;
        color: $primary-color !important;
    }

    textarea:focus+span.icon,
    input:focus+span.icon {
        display: none;
    }

    textarea,
    input {
        border-color: $primary-color;
        box-shadow: 0 0 10px 0px $primary-light;

        &:focus {
            border-color: $primary-color;
            box-shadow: 0 0 10px 0px $primary-light;
        }
    }

}
</style>