<template>
    <div v-if="contract == null" class="form-holder">
        <img class="logo" src="../assets/ph-icon-horizontal.png" />
        <fieldset class="box" :disabled="isProcessing">
            <h1 class="title">Sign In</h1>

            <div class="field">
                <label class="label">Name</label>
                <div class="control">
                    <input v-model="email" :disabled="isProcessing" class="input" type="email"
                        @keyup.enter="passwordInput?.focus()" placeholder="e.g you@domain.com">
                </div>
            </div>

            <div class="field">
                <label class="label">Password</label>
                <div class="control">
                    <input v-model="password" ref="passwordInput" class="input" type="password"
                        placeholder="e.g. ChunkyM0nk3y" @keyup.enter="login">
                </div>
            </div>
            <div class="field">
                <p class="control">
                    <a class="button is-primary is-fullwidth" :class="{ 'is-loading': isProcessing }" @click="login">
                        Sign In
                    </a>
                </p>
            </div>
            <label class="checkbox">
                <input type="checkbox" :disabled="isProcessing" v-model="remember">
                Remember me
            </label>
            <p style="margin-top: 1em">
                Need an account? <a href="https://www.primehire.com.au/">Request account</a>
            </p>
        </fieldset>

        <Modal :timer="modalMessage.hideAfter" v-if="modalMessage.title" @onclose="modalMessage.title = ''"
            :title="modalMessage.title">
            <p>
                {{ modalMessage.message }}
            </p>
        </Modal>
    </div>
    <template v-else>
        <Transition name="left">
            <div v-if="!isGettingSignature">
                <div v-html="contract" style="width: 97vw;">
                </div>
                <a class="button is-primary is-fullwidth" @click="signContract">
                    Sign
                </a>
            </div>
        </Transition>
        <Transition name="right">
            <div class="content-holder signatureDivContainer" v-if="isGettingSignature">
                <div class="signatureDiv">
                    <canvas style="height:100%" id="signaturePad"></canvas>
                </div>
                <div class="buttons" style="margin-bottom: 5px; margin-top: auto">
                    <ButtonIconText @clicked="saveSignature" icon="fas fa-check"
                        style="margin-left: auto; margin-top: 10px" :text="'Approve'" class="is-primary" />
                    <ButtonIconText @clicked="resetCanvas" icon="fas fa-eraser"
                        style="margin-left: auto; margin-top: 10px" :text="'Clear'" class="is-outlined" />
                </div>

            </div>
        </Transition>
    </template>
</template>

<script lang="ts" setup>
import { viewRequestNavOpen } from "@/AppLayout";
import { showModal } from "@/generalFunctions";
import SignaturePad from "signature_pad";
import { Ref, computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from "vue-router";
import Modal from "../components/elements/Modal.vue";
import { doRequest, loadUserAsync, profile, saveContractSignature, user } from '../data/state';
//Moved to segment deployment
//import LogRocket from 'logrocket'
import ButtonIconText from "@/components/elements/ButtonIconText.vue";
interface ModalMessage {
    title: string;
    message: string;
    hideAfter: number;
}
let passwordInput: Ref<HTMLInputElement | null> = ref(null)
let remember: Ref<boolean> = ref(true);
let modalMessage: Ref<ModalMessage> = ref({ title: '', message: '', hideAfter: 1000 })
const isGettingSignature = ref(false)
let _email = ref("");
let email = computed({
    get() {
        return _email.value.trim();
    },
    set(value) {
        _email.value = value.trim();
    }
});

const contract = ref<string | null>(null);
async function saveSignature() {
    const signature = await saveContractSignature(signaturePad.value!.toDataURL())
    if (!signature.error) {
        router.push('/jobs')
    } else {
        showModal(signature)
    }
}
async function signContract() {
    let i = 0;
    let allChecked = true;
    let currElement = document.getElementById(
        'required' + i
    ) as HTMLInputElement;
    while (currElement) {
        if (!currElement.checked) {
            allChecked = false;
            break;
        }
        i++;
        currElement = document.getElementById(
            'required' + i
        ) as HTMLInputElement;
        //
    }
    if (!allChecked) {
        showModal({
            error: true, snackbar: {
                active: true, color: 'primary', text: 'Please make sure you read the whole contract before proceeding', timeout: 5000
            }
        })
        return;
    }
    isGettingSignature.value = true;
    await nextTick();
    setTimeout(() => resetCanvas(), 1000)

}
const signaturePad: Ref<null | SignaturePad> = ref(null);
function resetCanvas() {
    const canvas = document.getElementById("signaturePad") as HTMLCanvasElement;
    if (!canvas) {
        console.log("canvas not found");
        return;
    }
    signaturePad.value = new SignaturePad(canvas, {
        backgroundColor: "rgba(255,255,255,1)",
    });
    if (!canvas) {
        return;
    }
    canvas.width = canvas.parentElement!.offsetWidth;
    canvas.height = canvas.parentElement!.offsetHeight;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    // Drawing a vertical line at x = 100
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = 'black';
    ctx.beginPath();
    ctx.moveTo(50, 0);
    ctx.lineTo(50, canvas.height);
    ctx.stroke();
    ctx.save(); // Save the current state
    ctx.translate(30, 200); // Move the origin to just left of the line
    ctx.rotate(Math.PI / 2);
    ctx.textAlign = "center";
    ctx.font = "20px Arial";
    ctx.fillText(user.value!.firstName + ' ' + user.value!.lastName, 0, 0);
    ctx.restore(); // Restore the original state
}

let password: Ref<string> = ref("");
let isProcessing: Ref<boolean> = ref(false);
let router = useRouter();


onMounted(() => {
    viewRequestNavOpen.value = false;
    email.value = localStorage.getItem('login_user') || '';
    password.value = localStorage.getItem('login_pass') || '';
    if (password.value !== '' && email.value !== '') {
        // login();
    }
})

async function login() {
    isProcessing.value = true;

    const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.value.match(validRegex)) {
        alert('Invalid email address!');
        isProcessing.value = false;
        return false;
    }

    if (remember.value) {
        localStorage.setItem('login_user', email.value);
        localStorage.setItem('login_pass', password.value);
    } else {
        localStorage.setItem('login_user', '');
        localStorage.setItem('login_pass', '');
    }
    isProcessing.value = true;
    try {
        const data = await loadUserAsync(email.value, password.value, undefined);
        if (data.error) {
            modalMessage.value.title = 'Error';
            modalMessage.value.message = data.snackbar.text;
            modalMessage.value.hideAfter = 5000;
            isProcessing.value = false;
            return;
        }
        try {
            window.analytics.identify(data.user.id.toString(), JSON.parse(JSON.stringify(profile.value)))
            //Moved to segment deployment
            //LogRocket.identify(data.user.id.toString(), JSON.parse(JSON.stringify(profile.value)));
        } catch (error) {

        }

        viewRequestNavOpen.value = true;
        if (data.redirect.startsWith('/us/contract/')) {
            const contractRequest = await doRequest<any>("/api/user/getCompanyContractData/1", "GET", undefined);
            const html = contractRequest.contract;
            contract.value = html;
        } else {
            if (data.user.permissions && data.user.permissions.length > 0) {
                window.location.href = ('/admin/dashboard');
            } else if (user.value?.managerOf) {
                //router.push customerPage
            } else {
                router.push("/jobs");
            }
        }
    } catch (err) {
        console.error(err);
        isProcessing.value = false;
        console.error(err);
    }
    isProcessing.value = false;
}
</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";

.form-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: center;
}

fieldset {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    width: 300px;
}

img.logo {
    position: absolute;
    top: 0;
    margin: 5em;
    width: 100px;
}

.signatureDivContainer {
    // width: 100vw; /* viewport height */
    height: 100vh;
    /* viewport width */
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%) rotate(90deg);
}

.signatureDiv {
    width: 100%;
    /* fills the rotated container width */
    height: 90%;
    /* fills the rotated container height */
    background-color: transparent;
    /* Just for visibility */
    /* Add other styles as needed */
}

.signaturePad {
    width: 100%;
    /* fills the rotated container width */
    height: 100%;
    /* fills the rotated container height */
    border: 1px solid #000;
    border-radius: 5px;
}
</style>
