<template>
    <span class="tag job-status" style="text-transform: capitalize;" :class="'status-' + applicationStatus">
        {{ applicationStatusText }}
    </span>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { IjobDetails, IpublicJob, JobStatus, statusCrew } from '@/types/jobs';
import { user } from '@/data/state';




const applicationStatus = defineModel('applicationStatus', { type: String as () => JobStatus, required: true });
const applicationStatusText = defineModel('applicationStatusText', { type: String, required: true });

// // confirmed filled pending open
// const status = defineModel('status', { type: IStatusPill, default: 'enabled' });

// const props = defineProps<{ job: IpublicJob | IjobDetails }>();

// function formatStatusCrew(status: statusCrew): string {
//     switch (status) {
//         case statusCrew.NotDefined:
//             return "Not Defined";
//         case statusCrew.Pending:
//             return "Pending";
//         case statusCrew.Confirmed:
//             return "Confirmed";
//         case statusCrew.Denied:
//             return "Not Available";
//         default:
//             throw new Error("Invalid status");
//     }
// }

// const applicationStatus = computed<JobStatus>(() => {
//     if (!props.job.reqCrew) return "filled"
//     if ((props.job as IpublicJob).isIn || (props.job as IjobDetails).reqCrew.some(el => el.userList?.some(el2 => el2.id === user.value!.id))) {
//         return "confirmed"
//     }

//     if (props.job.hasApplied && !props.job.processed) {
//         return "pending" //'Waiting for Approval'
//     }
//     //already checked isIn before
//     if (props.job.hasApplied && props.job.processed /*&& !props.job.isIn*/) {
//         return "filled" // Don't say "rejected"
//     }

//     const hasSpace = props.job.reqCrew.some(el => {
//         if (el.amount > el.confirmed)
//             return true
//         return false
//     });
//     if (!hasSpace) {
//         return "filled"
//     } else {
//         return "open"
//     }
// });

</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

.tag {
    text-transform: capitalize;
    background-color: transparent;
    border: 1px solid $grey-light;
    color: $grey-dark;

    &.status-open {
        background-color: $primary-color;
        border-color: $primary-dark;
        color: white;
    }

    &.status-pending {
        background-color: $warning;
        border-color: $warning-dark;
        color: $warning-dark;
    }

    &.status-invited {
        background-color: hsl(48, 100%, 67%);
        border-color: $warning-dark;
        color: $warning-dark;
    }

    &.status-confirmed {
        background-color: $success;
        border-color: $success-dark;
        color: $white;
    }

    &.status-filled {
        background-color: $grey-lighter;
        color: $grey-dark;
        border-color: $grey-light;
    }
}
</style>