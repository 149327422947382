<template>
  <div class="flex-container" style="width: 100%; height: 100%;">
    <div class="avatar-container" style="margin: 0 0 0 0; cursor: pointer;" @click="goBack">
      <IconText icon="fas fa-arrow-left" class="backbutton" style="flex-grow: 0;" />
      <Avatar :size="48" :name="timesheet?.userName" :src="urlPicture(timesheet.idUser)" />
    </div>
    <!-- {{ JSON.stringify(timesheet) }} -->

    <div class="fieldset">
      <div class="field" v-if="availableGroups.length>1">
        <label class="label">Group</label>
        <div class="select control is-fullwidth">
          <select v-model="selectedGroup" @change="changeSelectedGroup">
            <option v-for="availableGroup in availableGroups" :value="availableGroup.id">{{ availableGroup.name }}</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label">Started</label>
        <div class="control">
          <InputDateTimev2 v-model:date="timeStart" :disabled="Boolean(timesheet.idInvoice || timesheet.idSignature || isCompanyManager)" />
          <!-- <input :disabled="Boolean(timesheet.idInvoice || timesheet.idSignature || isCompanyManager)" class="input"
            v-model="formattedStartTime" type="datetime-local" @blur="changedStartTime" step="900" /> -->
        </div>
      </div>
      <div class="field">
        <label class="label">Ended</label>
        <div class="control">
          <InputDateTimev2 :defaultTime="timeStart+4*60*60*1000" v-model:date="timeEnd" :disabled="Boolean(timesheet.idInvoice || timesheet.idSignature || isCompanyManager)" />
          <!-- <input :disabled="Boolean(timesheet.idInvoice || timesheet.idSignature || isCompanyManager)" class="input"
            v-model="formattedEndTime" @blur="changedEndTime" type="datetime-local" step="900" /> -->
        </div>
      </div>
      <div class="field">
        <label class="label">Break</label>
        <div class="select control is-fullwidth">
          <select v-model="formattedBreak" @blur="changeBreak"
            :disabled="Boolean(timesheet.idInvoice || timesheet.idSignature || isCompanyManager)">
            <option :value="0">No Break</option>
            <option :value="30 * 60 * 1000">30 Minutes</option>
            <option :value="45 * 60 * 1000">45 Minutes</option>
            <option :value="60 * 60 * 1000">60 Minutes</option>
            <option :value="75 * 60 * 1000">75 Minutes</option>
            <option :value="90 * 60 * 1000">90 Minutes</option>
            <option :value="120 * 60 * 1000">120 Minutes</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label">Total Hours Worked</label>
        <div class="control is-static">
          <span class="input" disabled>{{ hoursWorked>0 ? hoursWorked.toFixed(2)+' hrs.': (timeEnd===0)?'Waiting for Clock out':(timeEnd+props.tzOffset)+'Check time'}} </span>
        </div>
      </div>
      <!-- timesheet.idInvoice cant be deleted from here, need to manually delete the invoice -->
      <ButtonIconText style="margin-bottom: 0.5em;" v-if="isAdminManager && (timesheet.idSignature)"
        @clicked="removeSignature" icon="fas fa-unlock" text="Unlock to Edit" class="is-outlined is-fullwidth" :disabled="isCompanyManager" />
      <ButtonIconText :text-color="'black'" @clicked="deleteTimesheet" icon="fas fa-trash" text="Unclock User"
        class="is-outlined is-fullwidth" :disabled="isCompanyManager" />
    </div>
    <div class="buttons" style="margin-top: auto">
      <ButtonIconText @clicked="goBack" icon="fas fa-arrow-to-left" text="Back" class="is-fullwidth mb-3 is-primary" />
    </div>

  </div>
</template>
<script lang="ts" setup>
import {
  showModal,
} from "@/generalFunctions";
import routerAdmin from "@/pages/_indexAdmin";
import router from "@/pages/_index";

import { getJobDetail, getJobDetailsAsync, user } from "@/data/state";
import { ItimesheetJob, TypeUpdateSetTime, statusCrew } from "@/types/jobs";
import { computed, onMounted, nextTick , ref, Ref, watch } from "vue";
import {
  backendURL,
  setTime,
  setTimeDelete
} from "../data/state";
import Avatar from "./elements/Avatar.vue";
import ButtonIconText from "./elements/ButtonIconText.vue";
import IconText from "./elements/IconText.vue";
import InputDateTimev2 from "./elements/InputDateTimev2.vue";
import { init } from "logrocket";
const props = defineProps<{ tzOffset: number, timesheet: ItimesheetJob[] | undefined, timesheetId: number }>();

const isCompanyManager = computed(() => user.value != null && user.value.managerOf.length > 0);

const isAdmin = computed(() => {
  return routerAdmin.currentRoute.value.path.split('/')[1] === 'admin';
});

let isUpdating = false;
const formattedStartTime = ref("");
const formattedBreak = ref(0);
const selectedGroup = ref(0);
const isAdminManager = defineModel('isAdminManager', { type: Boolean, default: false })
async function goBack() {
  await updateBeforeClose();
  let theRouter = isAdmin.value ? routerAdmin : router;
  // Get the current route
  let currentRoute = theRouter.currentRoute.value;
  // Construct the route object
  let routeObject = {
    name: currentRoute.name!,
    params: { ...currentRoute.params },
    // query: { ...currentRoute.query }, // Set timesheetId to null in the query
  };
  theRouter.push(routeObject);
}

const emit = defineEmits(['refreshJob']);

async function removeSignature() {
  if (timesheet.value.idSignature) {
    const userResponse = confirm("Are you sure you want to DELETE this signature?");
    if (!userResponse) { // if click no, cancel
      return
    }
  }
  isUpdating = true;
  const response = await setTime(timesheet.value.id, timesheet.value.break, TypeUpdateSetTime.BreakTime, true)
  if (response.error) {
    showModal(response)
  } else {
    timesheet.value.idSignature = null;
  }

  isUpdating = false;
}
async function deleteTimesheet() {
  if (timesheet.value.idInvoice || timesheet.value.idSignature) {
    const userResponse = confirm("Are you sure you want to DELETE this clock? Note: Its signature will also be permanently removed.");
    if (!userResponse) { // if click no, cancel
      return
    }
  }
  isUpdating = true;
  await setTimeDelete(
    timesheet.value!.id
  );
  isUpdating = false;
  goBack();
}

async function updateBeforeClose() {
  while (isUpdating) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
  emit('refreshJob');
}

function urlPicture(idUser: number) {
  return backendURL + '/api/user/getUserPicture/' + idUser
}

const timesheet = computed(() => {
  return props.timesheet!.filter(el => el.id === props.timesheetId)[0]!
});



let hoursWorked = computed(() => {
  if (timesheet.value !== null) {
    return (
      (timesheet.value.end - timesheet.value.start - formattedBreak.value) /
      1000 /
      60 /
      60
    );
  }
  return 0;
});

async function changeSelectedGroup(){
  isUpdating = true;
  const resultSetTime = await setTime(
    timesheet.value!.id,
    selectedGroup.value,
    TypeUpdateSetTime.GroupID,
    false
  );
  if (resultSetTime.error) {
    showModal(resultSetTime)
  }
  isUpdating = false;
}
async function changeBreak() {
  isUpdating = true;
  const resultSetTime = await setTime(
    timesheet.value!.id,
    formattedBreak.value,
    TypeUpdateSetTime.BreakTime,
    false
  );
  if (resultSetTime.error) {
    showModal(resultSetTime)
  }
  isUpdating = false;
}
const timeStart = ref(0)
const timeEnd = ref(0)
let initialized = false
const availableGroups:Ref<{id:number,name:string}[]> = ref([])
onMounted(async () => {
  initialized = false
  timeStart.value = timesheet.value.start - props.tzOffset;
  timeEnd.value = timesheet.value.end - props.tzOffset;
  if (timeEnd.value === -props.tzOffset) {
    timeEnd.value = 0;
  }
  formattedBreak.value = timesheet.value.break
  await nextTick()
  initialized = true
  const user = timesheet.value.idUser
  let job;
  if (isAdmin.value){
    job = (await (getJobDetailsAsync(Number(routerAdmin.currentRoute.value.params.idJob)))).job
    selectedGroup.value = props.timesheet?.find(el=>el.id===props.timesheetId)?.idGroup || 0
  } else {
    job = await getJobDetail(Number(router.currentRoute.value.params.jobId))    
    selectedGroup.value = job.timesheet.find(el=>el.id===props.timesheetId)?.idGroup || 0
  }
  availableGroups.value.length = 0;//clear the array
  job.reqCrew.forEach(el=>{
    if (el.userList.find(e=>e.id===user && e.status===statusCrew.Confirmed)){
      availableGroups.value.push({id:el.groupId, name:el.groupName})
    }
  })
})

watch(timeStart,changedStartTime);
watch(timeEnd,changedEndTime);

async function changedStartTime() {
  if (!initialized) return;
  isUpdating = true;
  const resultSetTime = await setTime(
    timesheet.value!.id,
    timeStart.value + props.tzOffset,
    TypeUpdateSetTime.StartTime,
    false
  );
  if (resultSetTime.error) {
    showModal(resultSetTime)
  } else {
    timesheet.value.start = timeStart.value + props.tzOffset;
  }
  isUpdating = false;
}

async function changedEndTime() {
  if (!initialized) return;
  isUpdating = true;

  const resultSetTime = await setTime(
    timesheet.value!.id,
    timeEnd.value + props.tzOffset,
    TypeUpdateSetTime.EndTime,
    false
  );
  if (resultSetTime.error) {
    showModal(resultSetTime)
  } else {
    timesheet.value.end = timeEnd.value + props.tzOffset;
  }
  isUpdating = false;
}



</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

.backbutton {
  color: $primary-color;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

input[type="datetime-local"] {
  font-family: "Open Sans", sans-serif;
}

.avatar-container {
  display: flex;
  align-items: center; // Align items vertically
  margin-bottom: 15px; // Spacing below the avatar and button
}

.field {
  margin-bottom: .50rem !important;
}
</style>