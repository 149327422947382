<template>
    <h2 class="title is-3">Messages</h2>
    <div class="group-members" v-if="(chat?.users.length || 0) > 0">
        <Avatar v-for="(user, i) in chat?.users" :key="i" :size="32" :name="user.user" :src="urlPicture(user.id)"></Avatar>
    </div>
    <div v-if="chat && chat.chat && chat.chat.length > 0" v-for="(message, i) in chat?.chat" :key="i">
        <img class="profile-picture" :src="urlPicture(message.idSender)">
        <span class="message">{{ message.chatContent }}</span>
        <span class="message-date">{{ formatDateTime('DD/MM/YY hh:mm:ss xx', message.timeSent) }}</span>
    </div>
    <span v-else style="align-self: center; margin-top: auto;" class="icon-text">
        <span class="icon">
            <i class="fas fa-comments"></i>
        </span>
        <span>No messages</span>
    </span>
    <div style="margin-top: auto" class="field has-addons is-fullwidth">
        <div class="control is-expanded">
            <input class="input" type="text" placeholder="enter message" v-model="newMessage" @keyup.enter="sendMessage">
        </div>
        <div class="control">
            <a class="button is-primary"  @click="sendMessage()">
                <span class="icon">
                    <i class="fas fa-paper-plane"></i>
                </span>
            </a>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { IChatResponse } from '@/types/chat';
import { arrayPicture, backendURL, user } from '../data/state';
// import {IChatProps} from "./Chat.vue.types";
import { formatDateTime, urlPicture } from '@/generalFunctions';
import { Ref, computed, ref } from "vue";
import { loadChatsAsync, sendChat, getJobDetail } from "../data/state";
import Avatar from './elements/Avatar.vue';
import { IJob, IjobDetails } from '@/types/jobs';

// interface IChatProps {
//     id?: number | undefined,
//     limit?: number | undefined
// }
const jobId = defineModel('jobId', { type: Number, required: true });

const _job = ref<IjobDetails | null>(null);
let job = computed(async () => {
    if (_job.value == null) {
        setTimeout(async (id: number) => {
            const data = await getJobDetail(id);
            _job.value = data;
        }, 10, jobId.value);
    }
    return _job.value;
});

let pictures = computed(() => {
    if (_job.value == null || _job.value === undefined) {
        return [];
    } else {
        return arrayPicture.value;
    }
});

let newMessage = ref('');

const _chat: Ref<IChatResponse | null> = ref(null);
let chat = computed(() => {
    if (_chat.value == null && jobId.value !== undefined) {
        setTimeout(async (id: number) => {
            const data = await loadChatsAsync(id);
            _chat.value = data;
        }, 10, jobId.value);
    }
    return _chat.value;
});

async function sendMessage() {
    if (jobId.value) {
        const response = await sendChat(jobId.value, -1, newMessage.value);
        if (response.idMessage != undefined) {
            chat.value?.chat.push({
                chatContent: newMessage.value,
                idChat: response.idMessage,
                idSender: user.value!.id,
                timeSent: new Date().valueOf(),
                userName: user.value!.firstName,
                deleted: false,
                fileId: -1
            });
        }
        newMessage.value = '';
    }
}

</script>
<style lang="scss" scoped>
@import '../styles/theme.scss';

.title {
    font-weight: 100;
}

div.group-members {
    display: flex;
    flex-direction: column;
}

table.chat {
    td {
        vertical-align: middle;
    }

    td:first-child {
        width: 40px;
    }

    width: 100%;

    td:last-child {
        width: 50px;
    }
}

.message {
    display: block;
    background-color: $primary-light;
    border-radius: 5px;
    padding: 5px;
}

.message-date {
    font-size: 0.7em;
    display: block;
    text-align: right;
}

.chat-item {
    display: flex;
    align-items: center;

    .message-date {
        font-size: 0.7em;
    }
}

img.profile-picture {
    margin: 0.5em;
    border-radius: 100%;
    height: 36px;
    width: 36px;
}


span.tag {
    background-color: $primary-light;
    color: $black;
    margin: 1em;
    display: block;

    &:nth-child(odd) {
        background-color: $grey-lightest;
        color: $black;
    }
}
</style>