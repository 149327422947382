<template>
    <div class="is-flex is-justify-content-center is-align-items-center" id="loading-wrapper" style="height: 100vh;">
        <div>
            <div id="loading-text">LOADING</div>
            <div id="loading-content"></div>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import { doRequest, loadUserAsync } from '@/data/state';
import { showModal } from '@/generalFunctions';
import { onMounted } from 'vue';
import router from './_index';
import { IDefaultSnackbarResponse } from '@/types/user';
const props = defineProps<{
    id: string, redirect: string | undefined, jobid: string | undefined,
    idReqCrew: string | undefined, acceptJob: string | undefined
}>();

async function processAcceptJob() {
    const reqCrewId = Number(props.idReqCrew!);
    const accepted = props.acceptJob === '1' ? true : false;
    const response = await doRequest<IDefaultSnackbarResponse>('/api/user/replyJob', 'POST', { id: reqCrewId, accepted: true })
    if (response.error) {
        showModal(response)
    } else {
        router.push({ name: 'jobs', params: { id: props.jobid, view: 'details' } })
    }
}

onMounted(async () => {
    let response
    try {
        response = (await loadUserAsync(undefined, undefined, props.id));    
    } catch (error) {
        router.push('/')
        return
    }
    
    
    if (response && response.error) {
        showModal(response)
        router.push({ name: 'login' });
    }
    console.log('linklogin props:',props)
    if (props.redirect && props.redirect !=='x') {
        switch (props.redirect) {
            case 'acceptJob':
                processAcceptJob();
                break;
            default:
                router.push(props.redirect)
                break;
        }
    } else if (props.jobid) {
        router.push(`/jobs/upcoming/${props.jobid}/crews`)
    } else {
        router.push('/jobs/upcoming')
    }
    // if (this.$router.app.$route.query.redirect) {
    //         respLogin = (
    //           await this.$axios.get(
    //             '/api/auth/otlloginCustomer/' + this.$router.app.$route.query.id
    //           )
    //         ).data;
    //       } else {
    //         respLogin = (
    //           await this.$axios.get(
    //             '/api/auth/otllogin/' + this.$router.app.$route.query.id
    //           )
    //         ).data;
    //       }
})
</script>
  
<style>
#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#loading-text {
    display: block;
    position: absolute;
    /* top: 50%; */
    left: 50%;
    color: rgb(20, 121, 60);
    width: 100px;
    height: 30px;
    margin: -7px 0 0 -45px;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 20px;
}

#loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid #f00;
}

#loading-content {
    border: 3px solid transparent;
    border-top-color: rgb(121, 61, 185);
    border-bottom-color: rgb(121, 61, 185);
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
  