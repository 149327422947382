<template>
    <div class="tabs is-centered">
        <ul>
            <template v-for="item in props.tabs">
                <li v-show="!(item.visible === false)" :class="{ 'is-active': item.id == selectionId }"
                    @click="selectionId = item.id">
                    <a>
                        <span v-if="props.showIcons && item.icon !== undefined && item.icon !== null" class="icon is-small">
                            <i :class="item.icon"></i>
                        </span>
                        <span>{{ item.text }}</span>
                        <span v-if="item.count !== undefined && item.count !== null && item.count > 0"
                            class="tag is-rounded">{{ item.count }}</span>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script lang="ts" setup>

import { computed, onMounted } from "vue";

export interface ITabObject {
    id: string,
    text: string,
    icon?: string,
    count?: number,
    visible?: boolean//true is default
}


let props = defineProps<{ tabs: ITabObject[], selectedTabId: string, showIcons?: boolean}>();
let emit = defineEmits<{
    'update:selectedTabId': [tab: string]
}>();

let selectionId = computed({
    get() {
        return props.selectedTabId;
    },
    set(value) {
        emit('update:selectedTabId', value);
    }
})

</script>
<style lang="scss" scoped>
@import "../../styles/theme.scss";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";

.tabs {
    flex-grow: 0;
    flex-shrink: 0;


    li {
        color: $grey-light;

        span.tag {
            margin-left: 5px;
            font-weight: 600;
        }

        @include mobile {

            // span.tag,
            // span.icon {
            //     //display: none;
            // }
        }


        span.icon {
            color: $grey;
        }

        &.is-active {
            color: $primary;

            span.tag {
                background-color: $primary;
                color: $white;
            }

            span.icon {
                color: $primary;
            }
        }

    }
}
</style>