<template>
<div class="columns">
    <div class="column">
        <button class="button is-rounded" @click="setDates(-1)">
            <i style="margin: 0;" class="fas fa-arrow-left"></i>
        </button>
    </div>
    <div class="column is-centered">
        <span class="title is-3 date-name" @click="setDates(0)">
            {{ date.toLocaleString('default', { month: 'short', year: "2-digit" }) }}
        </span>
    </div>
    <div class="column">
        <button class="button is-rounded" @click="setDates(1)">
            <i style="margin:0;" class="fas fa-arrow-right"></i>
        </button>
    </div>
</div>

    <div class="calendar" :style="{ 'width':'100%', 'height':'auto','grid-template-rows': `50px repeat(${viewDays.length / 7}, 40px)` }">
        <span v-for="day in dayNames" class="day-header">{{ day.substring(0, 1) }}</span>
        <div class="day" v-for="day in viewDays" :key="day.valueOf()" style="cursor: pointer; height: 40px;"
            @click="toggleDay(day)"
            :class="{ 'is-other-month': day.getMonth() != date.getMonth(), isSelectedDay: selectedDays.includes(day.valueOf())||selectedDays.includes(day.valueOf()+3600000) }">
            <div style="display: flex; height: 100%;">
                <span class="day-badge">{{ day.getDate() }}</span>
                <span class="day-text">{{ dayOfWeek(day) }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
const selectedDays = defineModel<Array<number>>('selectedDays', { required: true })

function isDST(d:Date) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

function toggleDay(day: Date) {
    let dayToAdd = day.valueOf();
    const index = selectedDays.value.indexOf(dayToAdd);
    if (index > -1) {
        selectedDays.value.splice(index, 1); // Remove value if found
    } else {
        console.log('dayToAdd',dayToAdd)
        selectedDays.value.push(dayToAdd); // Add value if not found
    }
}



const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];

const date = ref(new Date(new Date().setUTCDate(1)));
date.value.setUTCHours(0, 0, 0, 0)
function setDates(i: number) {
    const d = new Date(date.value.setMonth(date.value.getMonth() + i));
    date.value = d;
    // refreshCalendar();
}

const viewStartDate = computed(() => {
    const tempDate = new Date(date.value.valueOf());
    //TODO: should be some kind of local handing here
    //tempDate.setDate(1 - tempDate.getDay() +1);
    tempDate.setDate(1 - tempDate.getDay() + 1);
    return tempDate;
});

const viewEndDate = computed(() => {
    const tempDate = new Date(date.value.valueOf());
    tempDate.setMonth(tempDate.getMonth() + 1);
    let offset = tempDate.getDay() % 7;
    //TODO: should be some kind of local handing here
    //    tempDate.setDate(tempDate.getDate() + (6 - offset));
    tempDate.setDate(tempDate.getDate() + (7 - offset));
    return tempDate;
});

let viewDays = computed(() => {
    let d = new Date(viewStartDate.value.valueOf());
    let array: Array<Date> = [];
    while (d.valueOf() <= viewEndDate.value.valueOf()) {
        const dateToAdd = new Date(d.valueOf());
        dateToAdd.setUTCHours(0, 0, 0, 0);//to ensure its zero during DLS
        array.push(dateToAdd);
        d = new Date(d.setDate(d.getDate() + 1));
    }
    return array;
});

function dayOfWeek(date: Date): string {
    switch (date.getDay()) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
    return "";
}

</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';



.day-header {
    display: inline-block;
    height: 50px;
    font-weight: 500;
    text-align: center;
    padding: 0.5em;
    border-bottom: 1px solid $grey-lightest;
}

.calendar {
    height: calc(100vh - 10em);
    display: grid;
    width: 80%;
    grid-template-columns: repeat(7, 1fr);

    .day-text {
        display: none;
        margin-left: 2px;
        font-weight: 500;
    }


    >.day {
        &.is-other-month {
            background-color: $grey-lightest;
        }

        &.isSelectedDay {
            background-color: $grey-dark;
        }

        border: 1px solid $grey-lightest;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        min-height: 20px;



        .day-badge {
            margin: auto;
            border-radius: 100%;
            background-color: $grey-lighter;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
    }
}



    .icon {
        margin: 1em;
        cursor: pointer;
    }

/* Additional CSS for styling the 3-column container */
.columns {
    display: flex;
}

.column {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Optional: Adjust spacing between columns */
.column:not(:last-child) {
    margin-right: 0.5em;
}

/* Optional: Style buttons */
.button {
    padding: 0.5em 1em;
    font-size: 1rem;
}

/* Optional: Style icon inside button */
.button i {
    margin-right: 0.5em;
}

</style>