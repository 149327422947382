<template>
    <div class="tooltip-container">
        <slot></slot>
        <span class="tooltip-text" :style="`width: ${widthText ? widthText : '200px'}`" v-html="props.text"></span>
    </div>
</template>
<script lang="ts" setup>
let props = defineProps<{ text: String, widthText?: string }>();

</script>

<style>
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: help;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Positioning */
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 5px);
    left: 50%;
    margin-left: -60px;

    /* Fade in */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>