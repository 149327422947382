<template>
  <div class="countdown-button">
    <div class="radial-container">
      <svg class="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <circle cx="50" cy="50" r="40" stroke="lightgray" stroke-width="4" fill="none" />

        <circle class="timer-stroke" cx="50" cy="50" r="40" stroke="red" stroke-width="4" fill="none" />
      </svg>
      <slot></slot>
      <!-- <span class="time"></span> -->
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  timer: number
}>();

import { onMounted } from "vue";
const emit = defineEmits(['click']);
onMounted(() => {
  document?.querySelector<HTMLElement>('.timer-stroke')?.style.setProperty('--duration', props.timer + 'ms');
  requestAnimationFrame(() => {
    document?.querySelector<HTMLElement>('.timer-stroke')?.classList.add('anim-start');
  });
  setTimeout(() => {
    emit('click');
  },props.timer);
})

</script>

<style lang="scss" scoped>
.countdown-button {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
  width: auto;
}

.radial-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 5em;
}

.timer-stroke {
  stroke-dasharray: 251;
  stroke-dashoffset: 251;
}

.anim-start {
  animation: dash var(--duration, 5000ms) linear forwards;
}

.circle {
  transform: rotate(-90deg);
  position: absolute;
  width: 6em;
  height: 6em;
}


@keyframes dash {
  from {
    stroke-dashoffset: 251;
  }

  to {
    stroke-dashoffset: 0;
  }
}
</style>