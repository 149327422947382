<template>
    <Tabs :tabs="tabs" v-model:selectedTabId="selectedTabId" :show-icons="false"
        @update:selected-tab-id="selectedId = null" />
    <label class="checkbox">
        <input type="checkbox" v-model="showProcessed">
        Show Unprocessed Only
    </label>
    <div class="field is-grouped" style="width: 100%">
        <div class="control has-icons-left is-expanded" style="align-self: stretch; flex-grow: 1;">
            <input :class="{ 'is-focused': searchString.length > 0 }" ref="searchInput" class="input is-rounded"
                type="text" v-model="searchString" placeholder="Search..." />
            <Icon icon="fas fa-search" class="is-left" />
        </div>
    </div>
    <IsLoadingIcon v-if="isLoading == true" style="margin: auto;" />
    <table class="table" v-else-if="selectedTabId == 'workers'">
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>State</th>
            </tr>
        </thead>
        <tbody v-if="registrants.length > 0">
            <tr v-for="(registrant, index) in registrants.filter(reg => {
        return ((reg.companyName.length == 0) && (reg.firstName + reg.lastName + reg.email + reg.phone).toLowerCase().includes(searchString.toLowerCase()))
    })
                " :key="index" @click="setSelection(registrant.id)"
                :class="{ 'is-selected': registrant.id == selectedId }">
                <td>{{ registrant.firstName }} {{ registrant.lastName }}</td>
                <td>{{ registrant.email }}</td>
                <td>{{ registrant.phone }}</td>
                <td>{{ postcodeToState(registrant.postcode) }}
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table" v-else-if="selectedTabId == 'companies'">
        <thead>
            <tr>
                <th>Company</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody v-if="registrants.length > 0">
            <tr v-for="(registrant, index) in registrants.filter(reg => reg.companyName.length > 0 && (reg.firstName + reg.lastName + reg.companyName + reg.email + reg.phone).toLowerCase().includes(searchString.toLowerCase()))"
                :key="index" @click="setSelection(registrant.id)"
                :class="{ 'is-selected': registrant.id == selectedId }">
                <td>{{ registrant.companyName }}</td>
                <td>{{ registrant.email }}</td>
                <td>{{ registrant.phone }}</td>
                <td>{{ registrant.registeredAt == 0 ? '-' : new Date(registrant.registeredAt).toLocaleTimeString() }}
                </td>
            </tr>
        </tbody>
    </table>
    <PopOver v-if="selectedId" :is-open="true" @update:is-open="selectedId = null">
        <h1 class="title is-4">Registrant</h1>
        <ObjectTable :record="selection" />
        <ButtonIconText v-if="resume && selection && selection.companyName.length == 0" class="is-fullwidth" icon="fas fa-check" text="View Resume" @clicked="showResume=true" style="margin-bottom: 8px;" />
        <iframe v-if="showResume" :src="resume"
            style="width: 100%; height: 100%;"></iframe>
        <div class="buttons" style="margin-top: auto">
            <template v-if="selectedTabId == 'workers'">
                <ButtonIconText class="is-primary is-fullwidth" icon="fas fa-check" text="Approve"
                    @clicked="approveUser(true)" />
                <ButtonIconText class="is-fullwidth" icon="fas fa-times" text="Decline" @clicked="approveUser(false)" />
            </template>
            <template v-else>
                <ButtonIconText class="is-primary is-fullwidth" icon="fas fa-check" text="Conclude" @clicked="approveUser(false)" />
            </template>

        </div>
    </PopOver>
</template>
<script lang="ts" setup>
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router';
import { doRequest, user } from '@/data/state';
import IsLoadingIcon from '@/components/elements/IsLoadingIcon.vue';
import Icon from '@/components/elements/Icon.vue';
import Tabs, { ITabObject } from '@/components/elements/Tabs.vue';
import PopOver from '@/components/elements/PopOver.vue';
import ObjectTable from '@/components/elements/ObjectTable.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
import { IDefaultSnackbarResponse } from '@/types/user';
import { showModal } from '@/generalFunctions';

const tabs: ITabObject[] = [
    { id: 'workers', text: 'Workers' },
    { id: 'companies', text: 'Companies' }
];
const showResume = ref(false);
const selectedTabId = ref('workers');
const searchString = ref(``)

const props = defineProps({
    id: String
});

const router = useRouter();

const resume = ref<string | undefined>(undefined);

async function approveUser(approved: boolean) {
    if (selectedId.value != null) {
        const userApproved = await doRequest<IDefaultSnackbarResponse & { link: string }>('/api/user/processPreregister', 'POST', { id: selectedId.value, approve: approved });
        if (userApproved.error) {
            showModal(userApproved)
        } else {
            showModal({ error: false, snackbar: { active: true, text: `User has been approved.
In case he/she doesn't receive the link for registration, send the following link:
<input
  class="input is-link"
  style="width: 100%"
  type="text"
  readonly
  value="${userApproved.link}"
/>`, color: 'primary', timeout: 5000 } });
        }
        selectedId.value = null;
        getData();
    }
}

type PreregisterData = {
    id: number;
    firstName: string;
    lastName: string;
    companyName: string;
    phone: string;
    email: string;
    message: string;
    postcode: string;
    website: string;
    registeredAt: number
};

const selectedId = computed({
    get() {
        if (props.id && !isNaN(parseInt(props.id))) {
            return parseInt(props.id);
        } else {
            return null;
        }
    },
    set(value) {
        if (value == null) {
            router.push({ name: 'registrants' });
        } else {
            router.push({ name: 'registrants', params: { id: value } });
        }
    }
});

watch(selectedId, async () => {
    showResume.value = false;
    if (selectedId.value != null) {
        const data = await doRequest<string>('/api/user/getPreregisteredResume', 'POST', { id: selectedId.value });
        resume.value = data;
    } else {
        return resume.value = undefined;
    }
});

const selection = computed(() => registrants.find((registrant: PreregisterData) => registrant.id == selectedId.value));

//const selection = ref<PreregisterData|null>(null);
const isLoading = ref(false);

function postcodeToState(postcode: string) {
    const postcodeMappings = [
        { low: 1000, high: 1999, state: "NSW" },
        { low: 2000, high: 2599, state: "NSW" },
        { low: 2619, high: 2899, state: "NSW" },
        { low: 2921, high: 2999, state: "NSW" },
        { low: 200, high: 299, state: "ACT" },
        { low: 2600, high: 2618, state: "ACT" },
        { low: 2900, high: 2920, state: "ACT" },
        { low: 3000, high: 3999, state: "VIC" },
        { low: 8000, high: 8999, state: "VIC" },
        { low: 4000, high: 4999, state: "QLD" },
        { low: 9000, high: 9999, state: "QLD" },
        { low: 5000, high: 5799, state: "SA" },
        { low: 5800, high: 5999, state: "SA" },
        { low: 6000, high: 6797, state: "WA" },
        { low: 6800, high: 6999, state: "WA" },
        { low: 7000, high: 7799, state: "TAS" },
        { low: 7800, high: 7999, state: "TAS" },
        { low: 800, high: 899, state: "NT" },
        { low: 900, high: 999, state: "NT" },
    ];

    const postcodeNum = parseInt(postcode);
    const mapping = postcodeMappings.find(item => postcodeNum >= item.low && postcodeNum <= item.high);
    return mapping ? mapping.state : "Unknown";
}

function setSelection(id: number | null) {
    if (selectedId.value === id) {
        selectedId.value = null;
    } else {
        selectedId.value = id;
    }
}

const showProcessed = ref(true);
const registrants: PreregisterData[] = reactive([]);

watch(showProcessed, () => {
    getData();
});

async function getData() {
    isLoading.value = true;
    const data = await doRequest<PreregisterData[]>('/api/user/getpreregister', 'POST', { showAll: !showProcessed.value });
    if (data) {
        registrants.splice(0, registrants.length, ...data);
        // data.forEach((registrant: PreregisterData) => {
        //     registrants.push(registrant);
        // });
    }
    isLoading.value = false;
}

onMounted(() => {
    getData();
});


</script>
<style lang="scss" scoped>
.iframe {
    border: none;
    margin-top: 2em;
    margin-bottom: 2em;
}
</style>