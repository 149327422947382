<template>
    <h3 class="title is-3">Email Recipients</h3>
    <div class="field" v-if="job && job.emailTimesheet" v-for="email in props.emails?.filter(x => x != '')">
        <div class="control">
            <label class="checkbox">
                <input type="checkbox" :value="email" v-model="job.emailTimesheet" />
                {{ email }}
            </label>
        </div>
    </div>
    <div style="margin-top: auto">
        <InputValidate class="is-expanded" v-model="tempEmail"
            pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" />
        <ButtonIconText class="is-fullwidth is-primary" :disabled="tempEmail.length == 0" text="Add Email"
            icon="fas fa-plus" @clicked="addEmail" />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { IJobComplete } from '@/types/jobs';
import ButtonIconText from '../elements/ButtonIconText.vue';
import InputValidate from '../forms/InputValidate.vue';
const props = defineProps<{ job?: IJobComplete, emails?: string[] }>();
const tempEmail = ref<string>('');



function addEmail() {
    if (props.job && props.job.emailTimesheet && tempEmail.value.length > 0) {
        props.job.emailTimesheet.push(tempEmail.value);
        props.emails?.push(tempEmail.value);
        tempEmail.value = '';
    }
}

</script>
<style lang="scss" scoped></style>