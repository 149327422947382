<template>
    <span class="icon-text">
        <Icon style="margin: auto;" :icon="props.icon" :iconColor="props.iconColor" />
        <span v-if="props.text !== undefined" class="icon-text" :style="textColor">
            {{ props.text }}
        </span>
    </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Icon from './Icon.vue';
const props = defineProps<{ icon?: string, text?: string, iconColor?: string, textColor?: string }>();
// const emits = defineEmits(['onclick']);

const iconColor = computed(() => {
    if (props.iconColor == null || props.iconColor == undefined || props.iconColor == '') {
        return null;
    } else {
        return { color: props.iconColor };
    }
});

const textColor = computed(() => {
    if (props.textColor == null || props.textColor == undefined || props.textColor == '') {
        return null;
    } else {
        return { color: props.textColor };
    }
});

</script>