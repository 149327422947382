<template>
    <!-- this is on the admin view -->
    <IsLoadingIcon v-if="isLoading" style="margin: auto" />
    <div v-else-if="!job">
        <h1 class="title is-1">Job not found</h1>
    </div>
    <template v-else>
        <div id="saveIndicator" :class="classSaveIconParent" class="icon is-small" style="margin-left: 1em;">
            <i id="saveIcon" :class="classSaveIcon"></i> {{ saveIconText }}
        </div>

        <hr class="bar" :style="{ 'background-color': job.color }" />
        <h2 class="title is-2">{{ job.title }}</h2>
        
        <p class="block warning" v-if="job.reqCrew.length===0 && isWithin48Hours && isCompanyManager">
            <div style="color: hsl(0, 79%, 40%); font-weight: bold;">
                <Icon icon="fas fa-exclamation-triangle" /> 
                Requesting a new Job
            </div>
            <div>
                Fill all the details before proceeding to the next tab
            </div>
        </p>
        <p class="block warning" v-else-if="isWithin48Hours && isCompanyManager">
            <div style="color: hsl(0, 79%, 40%); font-weight: bold;">
                <Icon icon="fas fa-exclamation-triangle" /> 
                48 Hour Window
            </div>
            <div>
                Crew changes can not be changed within 48 hours of the job start time.
            </div>
        </p>
        <Tabs :tabs="tabs" v-model:selected-tab-id="selectedTab" />
        <fieldset v-if="selectedTab == 'info' || selectedTab == ''">

            <div class="field" v-if="job">
                <label class="label">Organizer</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="job.prepend" :disabled="isCompanyManager">
                            <option value="-1" disabled>Select organizer</option>
                            <option v-for="prepend in prepends" :key="prepend.text" :value="prepend.text">
                                {{ prepend.fullName }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field" v-if="job && job.id">
                <label class="label">Color</label>
                <div class="control">
                    <input type="color" class="input" v-model.lazy="job.color" :disabled="isCompanyManager" />
                </div>
            </div>

            <div class="field">
                <label class="label">Title</label>
                <div class="control">
                    <input class="input" type="text" placeholder="e.g Alex Smith" v-model.lazy="job.title">
                </div>
            </div>



            <div class="field">
                <label class="label">Confirmed</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="job.status" :disabled="isCompanyManager">
                            <option value="-1" disabled>Select status</option>
                            <option :value="jobStatus.Pending">Pending</option>
                            <option :value="jobStatus.Confirmed">Confirmed</option>
                            <option :value="jobStatus.Finished">Finished</option>
                            <option :value="jobStatus.Cancelled">Cancelled</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="field">
                <label class="label">Date</label>
        <div class="control has-icons-left has-icons-right">
            <InputDateTimev2 v-model:date="jobDate" :disabled="!canChangeDetailsJob" />
            <span class="icon is-small is-left">
                <i class="fas fa-hashtag"></i>
            </span>
            <span v-if="!isValidDate" class="icon is-small is-right red">
                <i class="fas fa-exclamation-triangle"></i>
            </span>
        </div>
        <p v-if="!isValidDate" class="help is-danger">This date is invalid. The minutes must be a multiple of 15 (00, 15, 30, or 45).</p>
                
            </div>



            <div class="field">
                <label class="label">Location</label>
                <div class="control">
                    <input class="input" type="text" placeholder="e.g Alex Smith" v-model.lazy="job.location"
                        :disabled="!canChangeDetailsJob">
                </div>
            </div>

            <div class="field" v-if="job.state && states.length > 0">
                <label class="label">State</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model.number="job.state" :disabled="!canChangeDetailsJob">
                            <option value="-1" disabled>Select state</option>
                            <option v-for="state in states" :key="state.id" :value="state.id"
                                :disabled="!canChangeDetailsJob">{{ state.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field" v-if="!isCompanyManager">
                <label class="label">Public Listing</label>
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="job.hidePublic">
                        Hide Job from public list.
                    </label>
                </div>
                <ButtonIconText @clicked="copyWhatsappConfirmTemplate" icon="fas fa-pencil" text="Copy Whatsapp Group Confirm"
                            class="is-small is-rounded" style="align-self: flex-end;" />
                <ButtonIconText @clicked="copyInviteTemplate" icon="fas fa-pencil" text="Copy Whatsapp Invite"
                            class="is-small is-rounded" style="align-self: flex-end;" />
            </div>

            <div class="field">
                <label class="label">Notes</label>
                <div class="control">
                    <textarea class="textarea" type="text" placeholder="e.g Alex Smith"
                        v-model.lazy="job.description" />
                </div>
            </div>


            <!-- <div class="field">
                <label class="checkbox">
                    <input type="checkbox" v-model="job.hidePublic">
                    Hide Job from public list
                </label>
            </div> -->
        </fieldset>
        <fieldset v-else-if="selectedTab == 'crew'" v-show="!(formSubView === 'jobGroups' && editingReqCrew)">
            <div class="field">
                <label class="label">On Site Responsible</label>
                <div class="control has-icons-left">
                    <div class="select is-fullwidth">
                        <select v-model="selectedOnsiteResponsibleId">
                            <option :value="-1" disabled>Select responsible</option>
                            <option v-for="responsible in responsibles" :key="responsible.id" :value="responsible.id">
                                {{ responsible.onSiteResponsibleName }}</option>
                            <option :value="-2"><b>Add New</b></option>
                        </select>
                    </div>
                    <Icon class="is-small is-left" icon-color="hsl(0, 79%, 40%)" icon="fas fa-user-headset" />
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <a class="button is-small is-fullwidth is-rounded" :href="'tel:' + job.onSiteResponsibleNumber">
                        <IconText icon="fas fa-phone"
                            :text="`Call ${job.onSiteResponsibleName} (${job.onSiteResponsibleNumber})`"
                            class="is-small is-rounded" style="margin-left: 1em;" />
                    </a>
                </div>
            </div>
            <!-- {{ job.reqCrew }} -->
            <label class="label">Supervisors</label>
            <div 
                v-for="(crew, i) in [...new Map(job.reqCrew.flatMap(el => el.userList).map(item => [item.id, item])).values()].filter(el=>el.status!= statusCrew.Denied).sort((a,b)=>a.fullName.localeCompare(b.fullName))" 
                :key="crew.id" 
                class="control">
                <label class="checkbox">
                    <input type="checkbox" v-model="job.selectedSupervisors" :value="crew.id" :disabled="isCompanyManager">
                    <icon-text v-if="crew.status===statusCrew.Pending" style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-clock"
                        icon-color="gray"></icon-text>
                    <icon-text v-if="crew.status=== statusCrew.Confirmed" style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-check"
                        class="is-success" icon-color="green"></icon-text>
                </label>
            </div>
                
                <!-- <div class="field" v-if="job.selectedSupervisors && crew.userList.length > 0" :key="i">
                    <div v-for="(supervisor, i) in crew.userList" :key="i" class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-model="job.selectedSupervisors" :value="supervisor.id"
                                :disabled="isCompanyManager">
                            {{ supervisor.fullName }}
                        </label>
                    </div>
                </div> -->

            <template v-for="(item, i) in job.reqCrew">
                <template v-if="!(item.amount <= 0 && item.userList.length === 0)">
                    <div :key="i" style="display: flex; justify-content: space-between;">
                        <span style="font-weight: bold">{{ item.groupName }} {{
        `${item.userList.filter(el => el.status === 2).length}/${item.amount}` }}
                            <b v-if="(item.startTime !== job.date)"
                                class="tag has-text-primary is-medium is-rounded is-outlined">{{
        ' *Start at: ' + formatDateTime('DD/MM/YY hh: mmxx', item.startTime) + ' * ' }}</b>
                        </span>

                        <ButtonIconText @clicked="editGroupCrew(item.id)" icon="fas fa-pencil" text="Edit"
                            class="is-small is-rounded" style="align-self: flex-end;"
                            :disabled="!canChangeDetailsJob" />
                    </div>
                    <ul :key="i">
                        <template v-if="item.userList.filter(item => item.status == statusCrew.Confirmed).length > 0">
                            <li class="li-header">Confirmed</li>
                            <li style="list-style-type: none"
                                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.Confirmed)"
                                :key="i">
                                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-check"
                                    class="is-success" icon-color="green"></icon-text>
                            </li>
                        </template>

                        <template
                            v-if="item.userList.filter(item => item.status == statusCrew.NotDefined || item.status == statusCrew.Pending).length > 0">
                            <li class="li-header">Pending</li>
                            <li style="list-style-type: none"
                                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.NotDefined || item.status == statusCrew.Pending)"
                                :key="i">
                                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-clock"
                                    icon-color="gray"></icon-text>
                            </li>
                        </template>

                        <template v-if="item.userList.filter(item => item.status == statusCrew.Denied).length > 0">
                            <li class="li-header">Not Available</li>
                            <li style="list-style-type: none"
                                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.Denied)"
                                :key="i">
                                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-times"
                                    icon-color="darkred"></icon-text>
                            </li>
                        </template>
                    </ul>
                    <div :key="i" v-if="job.reqCrew.length > 1" class="divider"></div>
                    <!-- <ul>
                        
                        <li class="li-header">Confirmed</li>
                        <li style="list-style-type: none"
                            v-for="(crew, i) in group.userList.filter(item => item.status == statusCrew.Confirmed)"
                            :key="i">
                            <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-check"
                                class="is-success" icon-color="green"></icon-text>
                        </li>
                        <li class="li-header">Pending</li>
                        <li style="list-style-type: none"
                            v-for="(crew, i) in group.userList.filter(item => item.status == statusCrew.NotDefined || item.status == statusCrew.Pending)"
                            :key="i">
                            <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-clock"
                                icon-color="gray"></icon-text>
                        </li>
                        <li class="li-header">Not Available</li>
                        <li style="list-style-type: none"
                            v-for="(crew, i) in group.userList.filter(item => item.status == statusCrew.Denied)" :key="i">
                            <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-times"
                                icon-color="darkred"></icon-text>
                        </li>
                    </ul> -->
                    <ButtonIconText :key="i" @clicked="inviteCrew(item)" icon="fas fa-users" text="Invite Worker"
                        class="is-small is-rounded" style="margin-bottom: 1em; align-self: center;" :disabled="isCompanyManager" />
                </template>
            </template>
            <ButtonIconText @clicked="editGroupCrew(0)" icon="fas fa-users" text="Add Group" class="is-primary"
                style="margin-top: auto" :disabled="!canChangeDetailsJob" />
        </fieldset>
        <TimeCardDetailAdmin @refresh-job="getJobMetaData" v-else-if="selectedTab == 'timesheets'"
            :timesheets="timesheets" :idJob="jobId!" :req-crews="job.reqCrew" :tz-data="tzOffset" />
        <LinkList v-else-if="selectedTab == 'actions'" :items="actionsList"
            @clicked="formSubView = $event as subViewFormats" />
        <Transition>
            <PopOver v-if="formSubView === 'jobGroups' && editingReqCrew" :is-open="true" @update:is-open="saveGroup">
                <h3 class="title is-3">{{ editingGroup ? 'Editing Group' : 'Creating new Group' }}</h3>
                <div class="field">
                    <label class="label">Selected group</label>
                    <div class="control">
                        <div class="select is-fullwidth">
                            <select v-model="editingReqCrew.groupId">
                                <option v-for="group in groups.filter(el => el.active)" :key="group.id"
                                    :value="group.id">
                                    {{ group.groupName }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Required # of crew</label>
                    <template v-if="user && user.managerOf.length > 0">
                        <div class="control">
                            <input class="input" :class="{'is-danger': editingReqCrew && editingReqCrew.userList.filter(u => u.status == 2).length == editingReqCrew.amount}" type="number" v-model="editingReqCrew.amount" :min="editingReqCrew.userList.filter(u => u.status == 2).length" placeholder="Enter number">
                        </div>
                        <template v-if="editingReqCrew && editingReqCrew.userList.filter(u => u.status == 2).length == editingReqCrew.amount">
                            <p class="help">
                                <span class="tag is-danger" style="font-weight: bold">{{editingReqCrew.userList.filter(u => u.status == 2).length}} workers are already confirmed.</span>
                            </p>
                            <p class="help">
                                Email <a href="mailto:jobs@primehire.com.au">jobs@primehire.com.au</a> to reduce crew size with confirmed workers.
                            </p>
                        </template>
                    </template>
                    <template v-else>
                        <div class="control">
                            <input class="input" type="number" v-model="editingReqCrew.amount" placeholder="Enter number">
                        </div>
                    </template> 
                </div>
                <div class="field">
                    <label class="label">Start time</label>
                    <div class="control">
                        <InputDateTimev2 v-model:date="editingReqCrew.startTime"></InputDateTimev2>
                    </div>
                </div>
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'chat'" :is-open="true" @update:is-open="formSubView = ''">
                <Chat :jobId="jobId" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'logs'" :is-open="true" @update:is-open="formSubView = ''">
                <h3 class="title is-3">Logs</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Changes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(log, i) in logs" :key="i" @click="selectLog(log)"
                            :class="{ 'is-selected': selectedLog == log }">
                            <td>{{ new Date(log.logDate).toLocaleString() }}</td>
                            <td>{{ log.userName }}</td>
                            <td>
                                <span class="tag" v-for="(field, i) in log.changed" :key="i">{{ field }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ButtonIconText :disabled="!selectedLog" text="Restore" icon="fas fa-undo" class="is-primary"
                    style="margin-top: auto" @clicked="restoreFromLog" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'share'" :is-open="true" @update:is-open="formSubView = ''">
                <ShareJob :job="job" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'duplicate'" @update:is-open="formSubView = ''">
                <DuplicateJob :id="job.id" :job-date="job.date" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'massNotification'" @update:is-open="formSubView = ''">
                <MassNotification :job="job" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'emailTimesheet'" @update:is-open="formSubView = ''">
                <EmailTimeSheet :job="job" :emails="emails" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'inviteCrew' && selectedReqAndCrew" @update:is-open="closeInviteCrew">
                <InviteCrew :job="job" :req-crew="selectedReqAndCrew" :state="job.state"/>
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'uploadFile'" @update:is-open="formSubView = ''">
                <h3 class="title is-3">Upload File</h3>
                <form id="jobUpload" enctype="multipart/form-data"
                    style="align-self: center; margin-top: auto; margin-bottom: auto;">
                    <div class="file has-name is-boxed">
                        <input type="hidden" name="idJob" :value="jobId" />
                        <label class="file-label">
                            <input class="file-input" type="file" name="files" @change="setFile">
                            <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">
                                    Choose a file…
                                </span>
                            </span>
                            <span class="file-name" v-if="uploadFile != null">
                                {{ uploadFile.name }}
                            </span>
                        </label>
                    </div>
                </form>
                <p class="tag" :class="{ 'is-success': uploadStatusSuccess, 'is-danger': !uploadStatusSuccess }"
                    v-if="uploadStatus.length > 0">{{ uploadStatus }}</p>
                <ButtonIconText :disabled="uploadFile == null || isUploading" :is-loading="isUploading" text="Upload"
                    icon="fas fa-upload" class="is-primary" style="margin-top: auto" @clicked="doUploadFile" />
            </PopOver>
        </Transition>
        <Transition>
            <PopOver v-if="formSubView == 'jobFiles'" @update:is-open="formSubView = ''">
                <h3 class="title is-3">Uploaded Files</h3>
                <div class="tagsFile">
                    <span class="tagFile" v-for="(item, i) in jobFiles" :key="i">
                    <span class="outlined" style="width: 100%;" @click="downloadFile(item.id, item.filename)">
                        <!-- @click="previewFile(selectedJob.filesId?.split(',')[i])" -->
                        {{ item.filename }}
                    </span>
                    <button class="button is-small" @click="downloadFile(item.id, item.filename)">
                        <span class="icon is-small">
                        <i class="fas fa-download"></i>
                        </span>
                    </button>
                    </span>
                </div>
            </PopOver>
        </Transition>
    </template>
</template>

<script lang="ts" setup>
import { doRequest, downloadFileJobSignature, getJobDetailsAsync, getJobLogsAsync, groups, saveJob, states, updateAvailableCrew, user } from '@/data/state';
import { IJobComplete, ItimesheetJob, idLocation, jobStatus, requiredAndCrew, statusCrew } from '@/types/jobs';
import { ILogItem } from '@/types/logs';
import { IOnSiteResponsible, ISnackbar } from "@/types/user";
import { Ref, computed, onMounted, ref, watch } from 'vue';
import { formatDateTime, localDownloadFile, normalizeMinutes, showModal } from '../generalFunctions';
import DuplicateJob from './admin/DuplicateJob.vue';
import EmailTimeSheet from './admin/EmailTimeSheet.vue';
import InviteCrew from './admin/InviteCrew.vue';
import MassNotification from './admin/MassNotification.vue';
import ShareJob from './admin/ShareJob.vue';
import Chat from './Chat.vue';
import ButtonIconText from './elements/ButtonIconText.vue';
import Icon from './elements/Icon.vue';
import IconText from './elements/IconText.vue';
import InputDateTimev2 from './elements/InputDateTimev2.vue';
import IsLoadingIcon from './elements/IsLoadingIcon.vue';
import LinkList from './elements/LinkList.vue';
import PopOver from './elements/PopOver.vue';
import Tabs, { ITabObject } from './elements/Tabs.vue';
import TimeCardDetailAdmin from './TimeCardDetailAdmin.vue';
const job = ref({
    reqCrew: [],
    selectedSupervisors: [],
    timesheets: undefined,
    date: 0,
    status: jobStatus.Pending,
    hidePublic: true,
    emailTimesheet: [],
    color: '#fff',
    description: '',
    id: -1,
    location: '',
    onSiteResponsibleName: '',
    onSiteResponsibleNumber: '',
    title: '',
    state: '',
    pcode:'',
    prepend: ''
} as IJobComplete)

//const jobId = defineModel('jobId', { type: Number });
const jobId = defineModel('jobId', { type: Number, required: true, default: 0 });

watch(jobId, getJobMetaData);
const isValidDate = ref(true)
watch(()=>job.value.date,()=>{
    if (normalizeMinutes(job.value.date)!==job.value.date){
        isValidDate.value = false;
    } else {
        isValidDate.value = true;
    }
})
onMounted(getJobMetaData);
const editingGroup = ref(0);
const editingReqCrew: Ref<requiredAndCrew | undefined> = ref(undefined)
const uploadFile = ref<File | null>(null);
const isUploading = ref(false);
const uploadStatus = ref('');
const uploadStatusSuccess = ref(false);
async function setFile(event: any) {
    uploadFile.value = event.target.files[0];
}

const canChangeDetailsJob = computed(() => {
    if (isCompanyManager.value === false) return true
    return isWithin48Hours && isCompanyManager && job.value.reqCrew.length===0
});

const isWithin48Hours = computed(() => {
    if (!job.value) return false;
    const diff = job.value.date - new Date().valueOf();
    return (diff < 48 * 60 * 60 * 1000);
});

const theUser = user;
const isCompanyManager = computed(() => user.value != null && user.value.managerOf.length > 0);

async function downloadFile(fileId: number, filename: string) {
  if (isNaN(Number(fileId))) return;
  const file = await downloadFileJobSignature(fileId, job.value.id)
  localDownloadFile(file, filename);
}

const actionsList = computed(() => {
    let actions = [{
        id: 'chat',
        icon: 'fas fa-comments',
        text: 'Chat'
    }];

    if (!isCompanyManager.value) {
        actions.push({
            id: 'logs',
            icon: 'fas fa-history',
            text: 'Logs'
        });
    }

    actions.push(
        {
            id: 'duplicate',
            icon: 'fas fa-copy',
            text: 'Duplicate'
        });

    if (!isCompanyManager.value) {
        actions.push({
            id: 'massNotification',
            icon: 'fas fa-mail-bulk',
            text: 'Mass Notification'
        });
    }

    actions.push({
        id: 'emailTimesheet',
        icon: 'fas fa-file-invoice',
        text: 'Timesheet Recipients'
    });

    actions.push({
        id: 'uploadFile',
        icon: 'fas fa-file-upload',
        text: 'Upload File'
    });

    actions.push({
        id: "jobFiles",
        icon:"fas fa-file-magnifying-glass",
        text: "Files",
    })
    return actions;
});

const jobDate = computed({
    get: () => job.value!.date,
    set: (value: number) => {
        isLoading.value = true;//avoid calling saveJob

        job.value!.reqCrew.forEach(el => {
            if (el.startTime === jobDate.value) {
                //if time is equal, update without warning user
                el.startTime = value;
            } else {
                //IF DATES ARE DIFERENT, ASK ADMIN TO RE-CHECK TIMES!
                alert('CHECK STARTTIME OF THE CREW!')
                alert('CHECK STARTTIME OF THE CREW!')
            }
        })
        isLoading.value = false;
        job.value!.date = value

    }
});

async function doUploadFile() {
    isUploading.value = true;
    const form = new FormData();
    form.append('files', uploadFile.value!)
    form.append('idJob', jobId.value!.toString());
    // const results = await doRequest<{ error: boolean, idFile: number, snackbar: ISnackbar }>('/api/job/uploadJobFile', 'POST', form);
    const results = await fetch(process.env.VUE_APP_API_SERVER + '/api/job/uploadJobFile', {
        method: 'POST',
        headers: {
            Authorization: user.value?.token
        } as HeadersInit,
        body: form
    });
    const json = (await results.json()) as { error: boolean, idFile: number, snackbar: ISnackbar };
    uploadStatus.value = json.snackbar.text;
    uploadStatusSuccess.value = !json.error;
    uploadFile.value = null;
    isUploading.value = false;
}

const selectedReqAndCrew: Ref<null | requiredAndCrew> = ref(null)
const selectedTab = defineModel('selectedTab', { default: 'info', type: String });

type subViewFormats = '' | 'chat' | 'share' | 'duplicate' | 'massNotification' | 'emailTimesheet' | 'inviteCrew' | 'logs' | 'jobGroups' | 'uploadFile'|'jobFiles';
const selectedLog = ref<ILogItem | null>(null);
function selectLog(item: ILogItem) {
    if (selectedLog.value == item) {
        selectedLog.value = null;
    } else {
        selectedLog.value = item;
    }
}
const formSubView = defineModel('formSubView', { type: String, required: true });
// const subView = defineModel<subViewFormats>('');

// const subView = defineModel('subView', { type: String });





function saveGroup() {
    formSubView.value = ''
    if (editingReqCrew.value!.groupId === 0) return;
    if (editingReqCrew.value!.id > 0)
        return;//automatically saved
    job.value?.reqCrew.push(editingReqCrew.value!)
}
/**
 * 
 * @param idReqCrew pass 0 to create a new one
 */
function editGroupCrew(idReqCrew: number) {
    if (idReqCrew > 0) {
        editingReqCrew.value = job.value?.reqCrew.find(el => el.id === idReqCrew)
    } else {
        editingReqCrew.value = {
            id: 0,
            groupId: 0,
            groupName: '',
            amount: 1,
            startTime: job.value!.date,
            requireCode: 0,
            userList: [],
            deleted: false,
            confirmed: 0,
        }
    }

    editingGroup.value = idReqCrew;
    formSubView.value = 'jobGroups'
}

async function restoreFromLog() {
    if (selectedLog.value != null) {
        const json = await doRequest<any>("/api/job/getJobFromLog", "POST", {
            idLog: selectedLog.value.idLog
        });
        console.log(json,job.value)
        job.value = json.job;
        emails.value = json.extraData.emails;
        locations.value = json.extraData.locations;
        prepends.value = json.extraData.prepends;
        responsibles.value = json.extraData.responsibles;
        timesheets.value = json.extraData.timesheet;
    }
}

//const subView = ref<subViewFormats>("");

const tabs: Array<ITabObject> = [
    { id: "info", text: "Info" },
    { id: "crew", text: "Crew" },
    { id: "timesheets", text: "Timesheets" },
    { id: "actions", text: "Actions" }
]

function inviteCrew(reqCrew: requiredAndCrew) {
    updateAvailableCrew()//job.value.state,job.value.pcode
    formSubView.value = 'inviteCrew'
    selectedReqAndCrew.value = reqCrew;
}

function closeInviteCrew() {
    formSubView.value = ''
    selectedReqAndCrew.value = null
}

const selectedOnsiteResponsibleId = ref<number>(-1);
// TODO: If we bind directly to the something like onsiteresponsibleId this is unnecessary
watch(selectedOnsiteResponsibleId, async () => {
    if (!job.value) return
    if (selectedOnsiteResponsibleId.value === -2) {
        const responsibleName = prompt("Enter the name of the new responsible");
        if (!responsibleName) return;//ignore if empty
        const responsibleNumber = prompt(`Enter the phone number of the ${responsibleName}`);
        if (responsibleName === null || responsibleNumber === null) return;
        job.value.onSiteResponsibleName = responsibleName;
        job.value.onSiteResponsibleNumber = responsibleNumber;
        isLoading.value = true;
        await saveJob(job.value);
        getJobMetaData();
        isLoading.value = false;
        //Add new
    }
    if (selectedOnSiteResponsible.value != null) {
        if (job.value.onSiteResponsibleName != selectedOnSiteResponsible.value.onSiteResponsibleName) {
            job.value.onSiteResponsibleName = selectedOnSiteResponsible.value.onSiteResponsibleName;
        }
        if (job.value.onSiteResponsibleNumber != selectedOnSiteResponsible.value.onSiteResponsibleNumber) {
            job.value.onSiteResponsibleNumber = selectedOnSiteResponsible.value.onSiteResponsibleNumber;
        }
    }
});

const selectedOnSiteResponsible = computed(() => {
    if (selectedOnsiteResponsibleId.value > -1) {
        return responsibles.value.find(item => item.id == selectedOnsiteResponsibleId.value);
    } else {
        return null;
    }
});



watch(() => job.value?.prepend, () => {
    if (!job.value) return
    const findPrepend = prepends.value.find(el => el.text === job.value?.prepend)
    if (findPrepend) {
        job.value.color = findPrepend.defaultColor.substring(0, 7).toLowerCase(); //dont use alpha channel
    }
})

const isLoading = ref(true)

const classSaveIcon = ref('')
const saveIconText = ref('')
const classSaveIconParent = ref('')
const timeoutId = ref(0)

watch(job, async () => {
    //if the difference is the userlist, dont save the job
    if (formSubView.value==='inviteCrew') return;//dont save when changes is on invited crew
    if (isLoading.value) return;
    clearTimeout(timeoutId.value)
    classSaveIcon.value = 'fas fa-spinner fa-spin';
    saveIconText.value = 'Saving'
    const response = await saveJob(job.value as IJobComplete)
    const isNewGroup = (job.value!.reqCrew.find(el => el.id === 0));
    if (response.error) {
        showModal(response)
        if (response.snackbar.text === 'Your user doesn`t have access for this operation') {
            getJobMetaData();
            return;
        }
        if (response.snackbar.text === 'You are not allowed to reduce the amount crew on this job once they are confirmed'){
            getJobMetaData();
            return;
        }
    }
    if (isNewGroup) {
        await getJobMetaData();//Reload job to get group id
    }
    classSaveIcon.value = 'fas fa-check saved';
    saveIconText.value = 'Saved'
    classSaveIconParent.value = ''
    timeoutId.value = setTimeout(() => {
        classSaveIcon.value = 'fas fa-check fade'
        classSaveIconParent.value = 'fade'
        saveIconText.value = 'Saved'
    }, 1000)
}, { deep: true });

// const isLoading = ref(false);
// const _job = ref<jobComplete | null>(null);

const emails = ref<string[]>([]);
const locations = ref<idLocation[]>([]);
const jobFiles = ref<{id: number; filename: string }[]>([]);
const timesheets = ref([] as ItimesheetJob[])
const tzOffset = ref(0);

const prepends = ref<{
    text: string;
    defaultColor: string;
    fullName: string;
}[]>([]);
const responsibles = ref<IOnSiteResponsible[]>([]);
const logs = ref<ILogItem[]>([]);

async function copyInviteTemplate(){
    const internalJob = job.value; // assuming job.value contains your JSON object

    const eventName = `${internalJob.title} - ID ${internalJob.id}`;
    const meetingLocation = `${internalJob.location}`;
    const dateLabel = formatDateTime('DD/MM/YY hh: mmxx', internalJob.date)//getDateLabel(date);

    // Helper function to get "Tomorrow" or day of the week
    // function getDateLabel(date:Date) {
    //     const today = new Date();
    //     const tomorrow = new Date(today);
    //     tomorrow.setDate(today.getDate() + 1);

    //     if (
    //         date.getDate() === tomorrow.getDate() &&
    //         date.getMonth() === tomorrow.getMonth() &&
    //         date.getFullYear() === tomorrow.getFullYear()
    //     ) {
    //         return "Tomorrow";
    //     }
        
    //     return date.toLocaleDateString("en-AU", { weekday: "long" });
    // }

    let output = `⚠ Hello everyone! We have ${internalJob.reqCrew.length} shifts available ⚠\n`;

    // Process each crew group
    internalJob.reqCrew.forEach((group, index) => {
        const confirmedCrew = group.userList.filter(member => member.status === statusCrew.Confirmed);
        const missingCrew = group.amount - confirmedCrew.length;
        if (missingCrew<=0) {
            return
        }
        const startTime = formatDateTime('hh:mm xx',group.startTime);

        output += `##############################\n`;
        output += `${index + 1}) Date: ${dateLabel}\n`;
        output += `•⁠  ⁠Activity: ${group.groupName}\n`;
        output += `•⁠  ⁠Location: ${meetingLocation}\n`;
        output += `•⁠  ⁠Start time: ${startTime}\n`;
        output += `•⁠  ⁠English level: Intermediate\n`;
        output += `•⁠  ⁠Missing Crew: ${missingCrew} person\n`;
        output += `###############################\n\n`;
    });    
    output += `For extra details: https://app.primehire.com.au/jobs/upcoming/${internalJob.id}/details`;

    // Copy to clipboard
    navigator.clipboard.writeText(output).then(() => {
        console.log('Template copied to clipboard!', output);
    }).catch(err => {
        console.error('Could not copy text: ', err, output);
    });
}

async function copyWhatsappConfirmTemplate() {
    const internalJob = job.value; // assuming job.value contains your JSON object

    const eventName = `${internalJob.title} - ID ${internalJob.id}`;
    const meetingLocation = `${internalJob.location}`;
    
    
    // Function to format crew members
    const formatCrew = (crewList:{
    id: number;
    fullName: string;
    status: statusCrew;
    phone?: string;
}[]) => {
        return crewList
            .filter(member => member.status === statusCrew.Confirmed)
            .map(member => `${member.fullName} - ${member.phone}`)
            .join('\n');
    };
    const date = formatDateTime('DD/MM/YY hh: mmxx', internalJob.date)//new Date(internalJob.date).toLocaleDateString('en-AU', { weekday: 'long', day: 'numeric', month: 'numeric' });
    let output = `Hi guys 🙂 Below you will find the details for the job that you have confirmed\n\n`;
    output += `📌 Event name: ${eventName}\n`;
    output += `📍 Meeting location: ${meetingLocation}\n\n`;
    output += `📅  Date: ${date}\n`;

    // Process each crew group
    internalJob.reqCrew.forEach(group => {
        if (group.amount>0){
            const startTime = formatDateTime('hh:mm xx',group.startTime);
            output += `⏰ Start Time: ${startTime}\n`;
            output += `👥️ ${group.groupName}: ${group.amount}/${group.amount}\n`;
            output += formatCrew(group.userList) + '\n\n';
        }
    });

    // Supervisor info
    const supervisors = internalJob.selectedSupervisors.map(id => {
        const member = internalJob.reqCrew.flatMap(group => group.userList).find(user => user.id === id && user.status==statusCrew.Confirmed);
        return member ? `${member.fullName} - ${(member as any).phone}` : '';
    }).filter(Boolean);

    output += `Supervisor on site:\n`;
    output += '*'+supervisors.map((supervisor) => supervisor).join('\n')+'*';

    output += `

Friendly reminder 👇
-⁠  ⁠Must be at the meeting point 10 minutes before. 
-  ⁠Bring your 🦺 vest. 
-⁠  ⁠Don't forget clock in/out 💰
-  ⁠Bring your food/water.

Use this chat for any questions. Cheers.`

    output += `For extra details: https://app.primehire.com.au/jobs/upcoming/${internalJob.id}/details`

    // Copy to clipboard
    navigator.clipboard.writeText(output).then(() => {
        console.log('Template copied to clipboard!',output);
    }).catch(err => {
        console.error('Could not copy text: ', err,output);
    });
}

async function getJobMetaData() {
    isLoading.value = true;
    if (jobId.value == -1) {
        return
    }
    if (jobId.value !== undefined && jobId.value > -1) {
        const data = await getJobDetailsAsync(jobId.value);
        job.value = data.job;
        emails.value = data.extraData.emails;
        locations.value = data.extraData.locations;
        jobFiles.value = data.extraData.jobFiles;
        prepends.value = data.extraData.prepends;
        responsibles.value = data.extraData.responsibles;
        tzOffset.value = data.extraData.tzOffset;
        selectedOnsiteResponsibleId.value = data.extraData.responsibles.find(el => el.onSiteResponsibleName === data.job.onSiteResponsibleName)?.id ?? -1;
        timesheets.value = data.extraData.timesheet;
        logs.value = await getJobLogsAsync(jobId.value);
    }
    isLoading.value = false
}


</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";

.button-icon-text {
    color: $primary;
}

.button-icon-text {
    justify-content: flex-start;
}

fieldset {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

input[type=color] {
    cursor: pointer;
}

.link-list .link-list-item .icon-text .icon {
    color: $primary;
}

.warning{
    background-color: $primary-light;
    border-color: $primary;
    border-radius: 5px;
    padding: 0.5em;
}

#saveIcon {
    transition: transform 0.3s ease;
}

#saveIcon.saved {
    color: green;
    transform: rotate(360deg);
    /* Simple rotate animation */
}

#saveIndicator.fade {
    color: green;
    opacity: 0;
    transition: opacity 1s ease;
    /* Simple rotate animation */
}
.tagsFile {
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tagFile {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 5px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5em;
  border: 1px solid #a82a21;
  /* Blue border */
  border-radius: 5px;
}

.tagFile .button {
  height: 2.5em;
  margin-right: 5px;
  /* Adjust spacing as needed */
  margin-left: 5px;
  /* Adjust spacing as needed */
}
</style>