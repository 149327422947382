<template>
    <div v-show="selectedItem === null && selectedInvoice.id === 0">
        <h1 class="title">{{ props.user!.fullName }}</h1>
        <DefaultTableVue :headers="headers" :items="times" @rowClick="setSelection($event)">
            <template #jobName="{ item }">
                <td align="left">
                    <span>
                        {{ item.jobName }}
                    </span>
                    <br />
                    <span class="is-size-7 has-text-grey">
                        {{ formatDateTime('DD/MM hh:mmxx', item.jobDate) }}
                    </span>
                </td>
            </template>

            <template #idInvoice="{ item }">
                <td align="center">
                    <IconText @click.stop="viewInvoice(item.idInvoice)" icon="fa-light fa-eye" :text="item.idInvoice" />
                </td>
            </template>

            <template #duration="{ item }">
                <td align="left">{{ item.end - item.start > 0 ? formatDurationMS(item.end -
        item.start) : 'Missing Clock out' }}</td>
            </template>
        </DefaultTableVue>
        <!-- <table class="table">
            <thead>
                <tr>
                    <th>Job</th>
                    <th>Duration</th>
                    <th>Break</th>
                    <th>Invoice</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in times" :key="i" :class="{ 'is-selected': item == selectedItem }">
                    <td @click="setSelection(item)">{{ item.jobName }}</td>
                    <td @click="setSelection(item)">{{ item.end - item.start > 0 ? formatDurationMS(item.end -
        item.start) : 'Missing Clock out' }}</td>
                    <td @click="setSelection(item)">{{ formatDurationMS(item.break) }}</td>
                    <td @click="viewInvoice(item.idInvoice)">{{ item.idInvoice }}</td>
                </tr>
            </tbody>
        </table> -->
    </div>

    <Transition>
        <PopOverVue v-if="isPopOverOpen && selectedItem !== null" :is-open="true" @update:is-open="selectedItem = null">
            <!-- <div class="content-holder" v-if="selectedItem !== null"> -->
            <TimeSheetForm :isAdminManager="true" @refreshJob="selectedItem = null" style="position: absolute"
                :timesheet="[selectedItem]" :timesheet-id="selectedItem.id"
                :tz-offset="(new Date().getTimezoneOffset() * 60 * 1000)" />
            <!-- </div> -->
        </PopOverVue>
    </Transition>
    <Transition>
        <PopOverVue v-if="selectedInvoice.id != 0" @update:is-open="selectedInvoice.id = 0">
            <h3 class="title is-3">Invoice Details</h3>
            <ButtonIconText @clicked="selectedInvoice.id = 0" icon="fas fa-arrow-to-left" text="Back"
                class="is-fullwidth mb-3 is-primary" />

            <ButtonIconText @clicked="deleteSelectedInvoice" icon="fas fa-trash" text="Cancel this Invoice"
                class="is-fullwidth mb-3" />

            <iframe :src="selectedInvoice.data" style="width: 100%; height: 500px"></iframe>

        </PopOverVue>
    </Transition>
</template>

<script lang="ts" setup>
import { Ref, ref, onMounted, computed, watch } from 'vue';
import { IUserTimeList } from "../../types/timesheet";
import { getUserTimeCheckAsync, getInvoiceDetails, deleteInvoice } from "../../data/state";
import { formatDateTime, formatDurationMS, showModal } from "../../generalFunctions";
import DefaultTableVue from '@/components/elements/DefaultTable.vue';
import PopOverVue from '../elements/PopOver.vue';
import TimeSheetForm from '../TimeSheetForm.vue';
import { IUserTimeCheck } from '@/types/timesheet'
import router from '@/pages/_indexAdmin';
import { ItimesheetJob } from '@/types/jobs';
import ButtonIconText from '../elements/ButtonIconText.vue';
import IconText from '../elements/IconText.vue';

const timesheetId = defineModel('timesheetId', { type: Number });
const selectedInvoice = ref({ id: 0, data: '' });
const props = defineProps<{
    start: Date,
    end: Date,
    idUser: number,
    user: IUserTimeCheck,
}>();

const headers = ref([
    { text: 'Job', property: 'jobName', sortable: true },
    {
        text: 'Duration', property: 'duration', sortable: true, sorter: (a: IUserTimeList, b: IUserTimeList, direction: 'asc' | 'desc') => {
            if (direction === 'asc') {
                return (a.end - a.start) > (b.end - b.start) ? 1 : -1;
            } else {
                return (a.end - a.start) < (b.end - b.start) ? 1 : -1;
            }
        }
    },
    { text: 'Break', property: 'break', isDuration: true, sortable: true },
    { text: 'Invoice', property: 'idInvoice', sortable: true },
]);
// <td @click="setSelection(item)">{{ item.jobName }}</td>
//                     <td @click="setSelection(item)">{{ item.end - item.start > 0 ? formatDurationMS(item.end -
//                         item.start) : 'Missing Clock out' }}</td>
//                     <td @click="setSelection(item)">{{ formatDurationMS(item.break) }}</td>
//                     <td @click="viewInvoice(item.idInvoice)">{{ item.idInvoice }}</td>

const isPopOverOpen = computed(
    () => {
        console.log('props.timesheetId', timesheetId.value)
        return selectedItem.value !== null
    }

);

const selectedItem: Ref<ItimesheetJob | null> = ref(null);
async function deleteSelectedInvoice() {
    if (selectedInvoice.value.id === 0) return

    if (!confirm(`Do you want to proceed and cancel the invoice ${selectedInvoice.value.id}?`)) {
        return;
    }
    const snackBar = await deleteInvoice(selectedInvoice.value.id);
    if (snackBar.error)
        showModal(snackBar)
    else {
        const idToBeDeleted = selectedInvoice.value.id;
        times.value.forEach(el => {
            if (el.idInvoice === idToBeDeleted) el.idInvoice = null;
        })
        selectedInvoice.value.id = 0;
    }

}
async function viewInvoice(idInvoice: number | null) {
    if (!idInvoice) return;
    const invoice = await getInvoiceDetails(idInvoice)

    selectedInvoice.value.data = `data:application/pdf;base64,${String.fromCharCode(
        ...invoice[0].pdf!.data
    )}`;
    selectedInvoice.value.id = idInvoice
}
function setSelection(item: IUserTimeList) {
    selectedItem.value = item;
    router.push({
        name: 'time',
        params: { ...router.currentRoute.value.params, timesheetId: item.id }
    });
}



const isLoading = ref(false);

watch(() => props.idUser, () => {
    getTimesAsync();
});

watch(() => props.end, () => {
    getTimesAsync();
});

watch(() => props.start, () => {
    getTimesAsync();
});

onMounted(() => {
    getTimesAsync();
});

const times = ref<IUserTimeList[]>([]);

async function getTimesAsync() {
    if (props.idUser == undefined || props.start == undefined || props.end == undefined) {
        return []
    }
    isLoading.value = true;
    times.value = await getUserTimeCheckAsync(props.idUser, props.start, props.end);
    const idTimesheet: number | null = Number(router.currentRoute.value.params.timesheetId)
    if (idTimesheet) {
        const foundEl = times.value.find(el => el.id === idTimesheet)
        if (foundEl) selectedItem.value = foundEl
    }
    isLoading.value = false;
}

</script>

<style lang="scss" scoped>
@import "../../styles/theme.scss";
</style>