import { ISnackbar, IWorkers } from './user'

export type ViewNames = 'chat' | 'details' | 'timecard' | 'apply' | ''

export interface DashboardProps {
  id?: number | undefined
  tab?: TabNames | undefined
  view?: ViewNames | undefined
  jobDetailView?: string | undefined
}

export interface IJob {
  color: string
  description: string | undefined
  id: number
  date: number
  location: string | idLocation | undefined
  pcode: string
  onSiteResponsibleName: string | undefined
  onSiteResponsibleNumber: string | undefined
  title: string
  // startTime: number | undefined;
  state: number | string | undefined
  prepend: string
}

export interface jobHeader extends IJob {
  status: jobStatus
  hidePublic: boolean
  selectedSupervisors: number[]
  emailTimesheet: string[]
}

export interface IJobComplete extends jobHeader {
  reqCrew: requiredAndCrew[]
}

export interface IJobCompleteClocks extends IJobComplete {
  tzOffset: number
  clockList: {
    idUser: number
    start: number
    end: number
    break: number
    idSignature: number | null
  }[]
}

export interface jobExtraData {
  responsibles: dataOnSiteResponsible[]
  locations: idLocation[]
  emails: string[]
  prepends: { text: string; defaultColor: string; fullName: string }[]
}

export interface jobDialogData {
  job: IJobComplete
  extraData: jobExtraData
}

export interface IjobDetails {
  id: number
  prepend: string
  color: string
  title: string
  description: string
  date: number
  state: number
  hidePublic: boolean
  onSiteResponsibleName: string
  onSiteResponsibleNumber: string
  location: string
  selectedSupervisors: number[]
  reqCrew: requiredAndCrew[]
  timesheet: ItimesheetJob[]
  hasApplied: number
  processed: number
  tzOffset: number
}

export interface IpublicJob {
  id: number
  prepend: string
  color: string
  title: string
  description: string
  date: number
  state: number
  onSiteResponsibleName: string
  onSiteResponsibleNumber: string
  location: string
  // status: statusJob;
  reqCrew: {
    id: number
    amount: number
    startTime: number
    groupName: string
    confirmed: number
  }[]

  approved: number
  isIn: number
  hasApplied: number
  processed: number
  statusUser: statusCrew
}

export interface ItimesheetJob {
  idUser: number
  userName: string
  id: number
  start: number
  end: number
  break: number
  idGroup: number
  idInvoice: number | null
  idSignature: number | null
}

export interface IFutureJobs extends IJob {
  idClock: number
  groupName: string
  amount: number
  filled: number
}

export interface IDashboardResponse {
  myJobs: IJob[]
  futureAvailableJobs: IFutureJobs[]
}

export interface idLocation {
  id: number
  location: string
}
export const enum jobStatus {
  Pending = 0,
  Confirmed = 1,
  Cancelled = 2,
  Finished = 3,
}

export interface selectedUsersInviteCrew {
  id: number
  fullName: string
  //email: string;
  //phone: string;
  status: statusCrew
  //isSelected?: boolean;
}

export interface requiredAndCrew {
  id: number // internal id from the tbJobsReqCrew
  groupId: number
  groupName: string
  amount: number
  startTime: number
  requireCode: number
  userList: selectedUsersInviteCrew[]
  deleted: boolean
  confirmed: number
}

export interface IGetJobSupervisor {
  jobs: Array<IJobComplete>
}

export interface IJobFile {
  idFile: number
  attachJobSignatur: number // 0 / 1 boolean
  filename: string
  expireDate: number
  deleted: number // 0 / 1 boolean
  hasSignature: number // 0 / 1 boolean
  hasProof: number // 0 / 1 boolean
}
export interface IJobComplete extends jobHeader {
  reqCrew: requiredAndCrew[]
  timesheets: ILoadTimeSheets | undefined | null
}

export interface IJobExtraData {
  responsibles: dataOnSiteResponsible[]
  locations: idLocation[]
  emails: string[]
  prepends: { text: string; fullName: string; defaultColor: string }[]
  timesheet: ItimesheetJob[]
  jobFiles: { id: number; filename: string }[]
  tzOffset: number
}

export const enum statusCrew {
  NotDefined = 0,
  Pending = 1,
  Confirmed = 2,
  Denied = 3,
}

export interface selectedUsersInviteCrew {
  id: number
  fullName: string
  //email: string;
  //phone: string;
  status: statusCrew
  //isSelected?: boolean;
}

export interface requiredAndCrew {
  id: number // internal id from the tbJobsReqCrew
  groupId: number
  groupName: string
  amount: number
  startTime: number
  requireCode: number
  userList: selectedUsersInviteCrew[]
  deleted: boolean
}

export interface dataOnSiteResponsible {
  id: number
  onSiteResponsibleName: string
  onSiteResponsibleNumber: string
}

// export const enum statusJob {
//   Pending = 0,
//   Confirmed = 1,
//   Cancelled = 2,
//   Finished = 3,
// }

export interface IDashboardAdmin {
  error: boolean
  snackbard: ISnackbar
  graphHours: {
    workedHours: number
    orderField: number
    groupInterval: number
    groupName: string
  }[]
  usersClockIn: {
    UserName: string
    workedHours: number
    amountClockIn: number
  }[]
  usersNotClocked: { userName: string }[]
}

export interface IFullDashboard {
  supervisedIds: number[] //id's of jobs that is supervisor
  invitedIds: { idJob: Number; reqCrewId: Number }[]
  availableClockin: {
    id: number // id job
    files: {
      //files if it have any
      name: string
      id: number
    }[]
    clocks: Clock[] //my clock ins
  }[]
  jobs: IpublicJob[]
  isLoaded: boolean
}

export type JobStatus =
  | 'open'
  | 'pending'
  | 'confirmed'
  | 'filled'
  | 'completed'
  | 'invited'

export interface IClockInAvailable extends IJob {
  tzData: string
  tzOffset: number
  idReqCrew: number
  isSupervisor: number | string
  requireCode: number
  crew: { idUser: number; fullName: string }[]
  clocks: Clock[]
  files: string | null
  filesId: string | null
}

export interface Clock {
  start: number
  end: number
  break: number
}

export const enum TypeUpdateSetTime {
  StartTime = 1,
  EndTime,
  BreakTime,
  GroupID,
}

export interface IPictureData {
  id: number
  picture: string
}

export interface IAvailableClockins {
  jobs: Array<IClockInAvailable>
  arrayPicture: Array<IPictureData>
}

export interface ILoadTimeSheets {
  timesheet: viewClockData[]
  available: IWorkers[]
}

export interface viewClockData {
  break: number
  end: number
  id: number
  idInvoice: number | null
  idSignature: number | null
  idUser: number
  jobName: string
  offsetTz: number
  start: number
  userName: string
}

export interface IJobRequest {
  fullName: string
  groupName: string
  idReqCrew: number
  idUser: number
  jobId: number
  jobName: string
  startTime: number
}

export interface IJobAvailable {
  description: string
  id: number
  idReqCrew: number
  location: string
  startTime: number
  status: number
  title: string
}

export type TabNames =
  | 'all'
  | 'upcoming'
  | 'requests'
  | 'invites'
  | 'openings'
  | ''
