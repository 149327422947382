<template>
  <aside style="display: flex;" :class="{ mini }">
    <button class="button is-small" :class="{ 'margin-right': mini }" style="align-self: flex-end; flex-grow: 0;"
      @click="IsOpen = false">
      <span title="close" class="icon">
        <i class="fas fa-times fa-2xl"></i>
      </span>
    </button>
    <slot></slot>
  </aside>
</template>

<script lang="ts" setup>

import { computed } from "vue";
const props = defineProps<{ isOpen?: boolean }>();
const mini = defineModel('mini', { type: Boolean, default: false });
const emit = defineEmits<{
  'update:isOpen': [value: boolean]
}>();

let IsOpen = computed({
  get() {
    if (props.isOpen === undefined) {
      return true;
    }
    else {
      return props.isOpen;
    }
  },
  set(value) {
    emit("update:isOpen", value);
  }
})

</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';

// @import "../../../node_modules/bulma/sass/utilities/_all.sass";
.margin-right {
  margin-right: 12px;

}

.mini {
  width: 100px;
  padding-left: 0px;
  padding-right: 0px;
}

aside {
  @include mobile {
    width: 100%;
  }



  //needed for horizontal transitions
  overflow-x: hidden;

  width: 400px;
  position: absolute;
  padding: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0); //needed to make z-index work properly on safari
  z-index: 1000;
  background-color: $white;
  margin: 0;
  box-shadow: -5px 0em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0px 0 1px rgba(10, 10, 10, 0.02);

  >* {
    flex: 1;
  }

}
</style>