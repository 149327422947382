const fullHex = (hex: string) => {
  let strR = hex.slice(1, 2);
  let strG = hex.slice(2, 3);
  let strB = hex.slice(3, 4);

  let r = parseInt(strR + strR, 16);
  let g = parseInt(strG + strG, 16);
  let b = parseInt(strB + strB, 16);

  // return {r, g, b}
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

//convert hex to rgb
export const hex2rgba = (hex: string) => {
  if (hex.length === 4) {
    return fullHex(hex);
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // return `rgb{${r}, ${g}, ${b}`;
  return `rgba(${r}, ${g}, ${b}, 1)`;
};
