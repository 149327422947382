import { Ref, computed, ref } from "vue";

export interface IModalInfoChooseOption {
  active: boolean;
  text: string;
  timeout: number;
  style: "button" | "checkbox";
  values: { id: any; value: string }[];
  accept: (input: any) => any;
  reject: () => any;
}

export interface IModalInfo {
  active: boolean;
  color: string;
  text: string;
  timeout: number;
  icon?: string;
  iconColor?: string;
}

const phoneWidthBreakPoint = 768;
const tabletWidthBreakPoint = 1023;

const width = ref(window.innerWidth);
const height = ref(window.innerHeight);

export const isPhone = computed(() => width.value <= phoneWidthBreakPoint);
export const isTablet = computed(() => width.value <= tabletWidthBreakPoint);
export const modalInfo: Ref<IModalInfo> = ref({
  active: false,
  color: "",
  text: "",
  timeout: 0,
});

export const modalInfoChooseOption: Ref<IModalInfoChooseOption> = ref({
  active: false,
  text: "",
  timeout: 0,
  style: "button",
  values: [],
  accept: () => undefined,
  reject: () => undefined,
});

export const userRequestNavOpen = ref(false);
export const viewRequestNavOpen = ref(true);

export const isNavShowing = computed(() => {
  //Let view suppress Nav
  //   console.log('NAV')
  if (viewRequestNavOpen.value === false) {
    return false;
  }

  if (isPhone.value === false) {
    return true;
  }

  return userRequestNavOpen.value;
});

function setWidth(value: Event) {
  width.value = window.innerWidth;
  height.value = window.innerHeight;
  // console.log("Resize", `width: ${width.value}`, `height: ${height.value}`);
}

let loaded = false;
window.addEventListener("resize", setWidth);

if (!loaded) {
  loaded = true;
  console.log("loaded");
} else {
  console.log("By pass load");
}
