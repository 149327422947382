<template>
    <div class="mx-2 is-flex justify-content-center is-fullwidth">
        <div style="margin: auto; max-width: 600px;">
            <Profile :user-id="userId" :hash="props.hash" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import Profile from '../Profile.vue';
import { ref, onMounted } from 'vue';


const props = defineProps({
    hash: {
        type: String,
        required: true
    }
})
const userId = ref(0);
onMounted(() => {
    const spplited = props.hash.split('_');
    if (spplited.length !== 2) {
        return;//invalid hash
    }
    if (isNaN(Number(spplited[0]))) {
        return;//invalid hash
    }
    userId.value = Number(spplited[0]);
})
</script>