<template>
    <Tabs :tabs="tabs" v-model:selected-tab-id="selectedTabId" />
    <template v-if="data != null && selectedTabId == 'todo'">
        <ButtonIconText class="is-primary" style="align-self: flex-end; margin: .5em" icon="fas fa-plus" text="Add Todo"
            @clicked="addTodo" />
        <table class="table is-striped is-fullwidth">
            <thead>
                <tr>
                <th></th>
                    
                    <th>User</th>
                    <th>Description</th>
                    <th>Date Added</th>
                    <th></th>
                
            </tr>
            </thead>
            <tbody>
                <tr v-for="(todo, i) in data.toDoList" :key="i" @click="selectedTodo = todo;">
                    <td>
                        <label class="checkbox">
                            <input type="checkbox" :checked="todo.completed" @click="toggleTodo(todo)">
                        </label>
                    </td>
                    <td>{{ todo.userName }}</td>
                    <td>{{ todo.description }}</td>
                    <td :title="formatDateTime('DD/MM/YY hh:mm:ss',todo.dateAdded)">{{ relativeTimeAgo(todo.dateAdded)}}</td>
                    <!-- new Date(todo.dateAdded).toLocaleDateString()  -->
                    <td>
                        <ButtonIconText icon="fal fa-trash" @clicked="deleteTodo(todo)" />
                    </td>
                </tr>
                <tr v-if="data.toDoList.length===0">
                    <!-- td with colspan 5 centered text -->
                    <td :colspan="5" style="text-align: center;">No To Do</td>
                </tr>
            </tbody>
        </table>
    </template>
    <template v-else-if="data != null && selectedTabId == 'jobs'">
        <ButtonIconText icon="fas fa-plus" text="Add Job" class="is-primary" icon-color="white"
            style="align-self: flex-end;" @clicked="addJob" />
        <h5 class="separator" style="align-self: flex-start;" @click="isTodayShowing = !isTodayShowing">
            <IconText :icon="isTodayShowing ? 'fas fa-chevron-down' : 'fas fa-chevron-right'" text="TODAY" />
        </h5>
        <table v-if="isTodayShowing" class="table is-hoverable is-fullwidth" style="margin: 1em">
            <thead>
                <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Job</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <DashboardJobRow
                    v-for="item in data.jobCompleteTimes.filter(it => (it.date) <= (currentDay - 1 + 24 * 60 * 60 * 1000))"
                    :item="item" :key="item.id" :class="{ 'is-selected': idJob == item.id }"
                    @onclick="idJob = item.id" />
            </tbody>
        </table>
        <h5 class="separator" style="align-self: flex-start;" @click="isTomorrowShowing = !isTomorrowShowing">
            <IconText :icon="isTomorrowShowing ? 'fas fa-chevron-down' : 'fas fa-chevron-right'" text="TOMORROW" />
        </h5>
        <table v-if="isTomorrowShowing" class="table is-hoverable is-fullwidth" style="margin: 1em">
            <thead>
                <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Job</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <DashboardJobRow
                    v-for="item in data.jobCompleteTimes.filter(it => (it.date) >= (currentDay - 1 + 24 * 60 * 60 * 1000) && (it.date) <= (currentDay - 1 + 2 * 24 * 60 * 60 * 1000))"
                    :item="item" :key="item.id" :class="{ 'is-selected': idJob == item.id }"
                    @onclick="idJob = item.id" />
            </tbody>
        </table>

        <h5 class="separator" style="align-self: flex-start" @click="isNext7DaysShowing = !isNext7DaysShowing">
            <IconText :icon="isNext7DaysShowing ? 'fas fa-chevron-down' : 'fas fa-chevron-right'" text="NEXT 7 DAYS" />
        </h5>
        <table v-if="isNext7DaysShowing" class="table is-hoverable is-fullwidth" style="margin: 1em">
            <thead>
                <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Job</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <DashboardJobRow
                    v-for="item in data.jobCompleteTimes.filter(it => (it.date) >= (currentDay - 1 + 2 * 24 * 60 * 60 * 1000))"
                    :item="item" :key="item.id" :class="{ 'is-selected': idJob == item.id }"
                    @onclick="idJob = item.id" />
            </tbody>
        </table>

    </template>
    <template v-else-if="selectedTabId == 'messages'">
        <table class="table is-striped">
            <tbody>
                <tr v-for="(message, i) in messages" :key="i" @click="gotoMessage(message)">
                    <td>
                        <span v-for="(user, index) in message.users.flatMap(el=>el.user)" class="tag is-primary-light">{{ user }}</span>
                    </td>
                    <td>{{ message.title }}</td>
                    <td>{{ message.description }}</td>
                </tr>
            </tbody>
        </table>

    </template>
    <Transition>
        <PopOver v-if="idJob" v-model:is-open="isPopOverOpen">
            <!-- @update:is-open="idJob = null"> -->
            <JobFormVue :jobId="idJob" v-model:selectedTab="formSelectedTabId" v-model:formSubView="formSubView" />
        </PopOver>
    </Transition>
</template>
<script lang="ts" setup>
import { IAdminChatResponse } from "@/types/chat";

import { ITodoList } from "@/types/todo";
import { computed, onMounted, ref, watch } from "vue";
import { createNewJob, doRequest, getAdminDashboardJobs, getLatestChatAdminAsync, user } from "../../data/state";

import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import IconText from "@/components/elements/IconText.vue";
import DashboardJobRow from "./dashboard-job-row.vue";
import PopOver from "@/components/elements/PopOver.vue";
import Tabs, { ITabObject } from "@/components/elements/Tabs.vue";
import { IJobCompleteClocks } from "@/types/jobs";
import { formatDateTime, showModal } from "@/generalFunctions";
import router from "../_indexAdmin";
import { IDefaultSnackbarResponse } from "@/types/user";
import JobFormVue from "@/components/JobForm.vue";
import InputDateTime from "@/components/elements/InputDateTime.vue";
import TimeSheetForm from "@/components/TimeSheetForm.vue";

const tempDate = ref<number>(new Date().valueOf());
const idJob = defineModel('idJob', { type: Number });
const selectedTabId = defineModel('selectedTabId', { type: String, default: 'jobs' });
const formSelectedTabId = defineModel('formSelectedTabId', { type: String });
const formSubView = defineModel('formSubView', { type: String });

watch(formSelectedTabId, navigateJobForm);
watch(formSubView, navigateJobForm);
watch(idJob, navigateJobForm);

function relativeTimeAgo(milliseconds: number): string {
    const seconds = Math.floor((new Date().valueOf() - milliseconds) / 1000);

    const intervals: [number, string][] = [
        [31536000, 'year'],
        [2592000, 'month'],
        [604800, 'week'],
        [86400, 'day'],
        [3600, 'hour'],
        [60, 'minute'],
        [1, 'second']
    ];

    for (const [intervalSeconds, label] of intervals) {
        const intervalValue = Math.floor(seconds / intervalSeconds);
        if (intervalValue >= 1) {
            return intervalValue === 1 ? `${intervalValue} ${label} ago` : `${intervalValue} ${label}s ago`;
        }
    }

    return 'Just now';
}


function navigateJobForm() {
    const params = { idJob: idJob.value, formSelectedTabId: formSelectedTabId.value, formSubView: formSubView.value }
    const param = { name: 'dashboard', params };
    router.push(param);
}

async function toggleTodo(todo: ITodoList) {
    const response = await doRequest<IDefaultSnackbarResponse>("/api/misc/toggleTodo", "POST", { idTodo: todo.id, status: !todo.completed });
    if (response.error) {
        showModal(response);
    }
}
async function deleteTodo(todo: ITodoList) {
    const response = await doRequest<IDefaultSnackbarResponse>("/api/misc/deleteTodo", "POST", { idTodo: todo.id });
    if (response.error) {
        showModal(response);
    } else {
        data.value!.toDoList.splice(data.value!.toDoList.findIndex(el => el.id === todo.id), 1);
    }
}
const currentDayCalc = new Date();
const month = currentDayCalc.getMonth();
currentDayCalc.setUTCDate(currentDayCalc.getDate());
currentDayCalc.setUTCMonth(month);//this fix an error that can occur when day is 01/xx/2024, it reduces a month and this fixes
currentDayCalc.setUTCHours(0, 0, 0, 0)
const currentDay = currentDayCalc.valueOf();

const isPopOverOpen = computed({
    get() {
        return idJob.value !== undefined;
    },
    set(value) {
        if (!value) {
            idJob.value = undefined;
        }
    }
});



const tabs: ITabObject[] = [
    {
        id: 'todo',
        text: 'To Do',
        icon: 'fal fa-check',
        count: 0
    },
    {
        id: 'jobs',
        text: 'Jobs',
        icon: 'fal fa-briefcase'
    },
    {
        id: 'messages',
        text: 'Messages',
        icon: 'fal fa-comments'
    }
]

const isTodayShowing = ref<boolean>(true);
const isTomorrowShowing = ref<boolean>(true);
const isNext7DaysShowing = ref<boolean>(true);

const selectedTodo = ref<ITodoList | null>(null);


async function addTodo() {
    const todoText = prompt("Enter a new todo");
    if (todoText) {
        const response = await doRequest<IDefaultSnackbarResponse>("/api/misc/createtodo", "POST", { userText: todoText });
        if (response.error) {
            showModal(response);
        } else {
            refreshData();
        }
    }
}


async function addJob() {
    const response = await createNewJob();
        if (response.error){
            return;
        }        
    idJob.value =  response.jobId
}

const data = ref<{ graphData: any, jobCompleteTimes: IJobCompleteClocks[], toDoList: ITodoList[] } | null>(null);

const messages = ref<IAdminChatResponse[]>([]);
function gotoMessage(message: IAdminChatResponse) {
    // https://app.primehire.com.au/admin/dashboard/6604/actions/chat
    router.push({ name: 'dashboard', params: { idJob: message.id, formSelectedTabId: 'actions', formSubView: 'chat' } });
}

async function refreshData() {
    data.value = await getAdminDashboardJobs(currentDay.valueOf(), currentDay.valueOf() + 14 * 24 * 60 * 60 * 1000);
    messages.value = await getLatestChatAdminAsync();
}
onMounted(async () => {
    // const startDate = new Date().setUTCHours(0, 0, 0, 0);
    refreshData();

});

</script>
<style lang="scss" scoped>
@import "../../styles/theme.scss";

input[type="checkbox"] {
    height: 1.2em;
    width: 1.2em;
}

.is-primary-light {
    background-color: $primary-light !important;
    color: black !important;
    margin-left: 0px;
    margin-right: 2px;
}   



.separator {
    cursor: pointer;
    width: 100%;
    margin: 1em;
    align-self: flex-start;
    color: $primary;
    border-radius: 8px;
    padding: .5em;
    background-color: $white-ter;
    text-align: start;
}
</style>
