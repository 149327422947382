<template>
    <div class="field">
        <label class="label">ABN</label>
        <div class="control has-icons-left has-icons-right">
            <input class="input" :class="{ 'is-danger': !isValid }" placeholder="e.g. 12345678901" :value="value"
                @blur="value = ($event!.target! as HTMLInputElement).value">
            <span class="icon is-small is-left">
                <i class="fas fa-hashtag"></i>
            </span>
            <span v-if="!isValid" class="icon is-small is-right red">
                <i class="fas fa-exclamation-triangle"></i>
            </span>
        </div>
        <p v-if="!isValid" class="help is-danger">This ABN is invalid. <a
                href="https://abr.business.gov.au/Search">Validate your ABN here.</a></p>
    </div>
</template>
<script lang="ts" setup>

import { doUnauthenticatedRequest } from '@/data/state';
import { ref, watch } from 'vue';

const value = defineModel({ default: '' });
const isValid = ref(true);

watch(value, async () => {
    try {
    if (value.value.length > 0) {
        const abn = await doUnauthenticatedRequest<false|{Abn:string,AbnStatus:"Active"|"Cancelled",AbnStatusEffectiveFrom:string,Acn:string,AddressDate:string,
  AddressPostcode:string,AddressState:string,BusinessName:string[],EntityName:string,EntityTypeCode:string,EntityTypeName:string,
  Gst:string,Message:string}>('/api/misc/isValidABN','POST', { abn: value.value });
        if (abn === false || abn.AbnStatus !== 'Active') {
            isValid.value = false;
        } else {
            isValid.value = true;
        }
    } else {
        isValid.value = true;
    }
    } catch (error) {
        isValid.value = false;    
    }
});

</script>
<style scoped lang="scss">
@import "../../styles/theme.scss";

.red {
    color: darkred;
}
</style>