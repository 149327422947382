<template v-if="isLoading">
    <IconText icon="fa-solid fa-spinner-scale fa-spin-pulse fa-2x" />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import IconText from './IconText.vue';
const props = defineProps<{ isLoading?: boolean }>();

const isLoading = computed(() => {
    if(props.isLoading == undefined){
        return true;
    }else{
        return props.isLoading;
    }
});

</script>
<style lang="scss" scoped>

:deep(.icon-text) {
    margin: auto;
}

.icon {
    flex-grow: 1;
    align-self: center;
    justify-self: center;
}
</style>