<template>
    <span v-if="props.icon !== undefined" class="icon" :style="iconColor" @click="emit('onclick')" >
        <i :class="props.icon"></i>
    </span>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
const props = defineProps<{icon?: string, text?: string, iconColor?: string, textColor?: string}>();
const emit = defineEmits<{onclick: []}>();

const iconColor = computed(() => {
    if(props.iconColor == null || props.iconColor == undefined || props.iconColor == ''){
        return null;
    }else{
        return {color: props.iconColor};
    }
});
</script>
