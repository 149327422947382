<template>
    <div class="column is-full">
        <h4 class="title is-4">Future Jobs</h4>
        <VueApexCharts type="area" class="chartElement" :options="chartOptionsFuture" height="300px"
            :series="seriesChartFuture">
        </VueApexCharts>
    </div>
    <div class="column is-full">
        <h4 class="title is-4">Historical Data</h4>
        <fieldset class="columns is-multiline" style="align-items: center;">
            <div class="field column">
                <label class="label">Starting</label>
                <div class="control">
                    <input type="date" class="input" v-model="startDateString" @change="refreshData" />
                </div>
            </div>
            <div class="field column">
                <label class="label">Ending</label>
                <div class="control">
                    <input type="date" class="input" v-model="endDateString" @change="refreshData" />
                </div>
            </div>
            <p style="padding: 0;" class="column is-full">For better view, please select at least 2 weeks of data and
                starting on monday.</p>
        </fieldset>
        <div class="columns is-multiline" style="margin-top: 2em;">
            <div class="column is-full">
                <h5 class="title is-5">Worked Hours per week per group</h5>
                <VueApexCharts type="area" class="chartElement" :options="workedHoursByWeek" height="300px"
                    :series="seriesWorkedHoursByWeek"></VueApexCharts>
            </div>
            <div class="column" style="width: 50%;">
                <h5 class="title is-5">Worked Hours per group</h5>
                <VueApexCharts type="donut" :options="workedHoursByGroup" :series="seriesWorkedByGroup" height="300px">
                </VueApexCharts>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue'
import { getDashboardAdminData, getAdminDashboardJobs } from '../../data/state'
import { IDashboardAdmin } from '@/types/jobs';
import VueApexCharts from 'vue3-apexcharts';
import Tabs, { ITabObject } from '@/components/elements/Tabs.vue';
import { formatDateTime } from '@/generalFunctions';

const tabs: ITabObject[] = [{ id: 'upcoming', text: 'Upcoming Jobs' }, { id: 'past', text: 'Past Jobs' }];
const selectedTab = ref('upcoming');

const chartOptionsFuture = ref({
    theme: { mode: 'light' },
    xaxis: {
        categories: ['']
    },
});
const seriesChartFuture = ref([
    { name: 'Required Crew', data: [0] },
    { name: 'Confirmed Crew', data: [0] },
    { name: 'Pending Crew', data: [0] },
]);

const endDate = ref(new Date());
const startDate = ref(new Date(new Date().setDate(- 28)));
const endDateString = computed({
    get: () => endDate.value.toISOString().split('T')[0],
    set: (value: string) => endDate.value = new Date(value)
});

const startDateString = computed({
    get: () => startDate.value.toISOString().split('T')[0],
    set: (value: string) => startDate.value = new Date(value)
});


const isLoading = ref(false);
const dashboardData = ref(null as IDashboardAdmin | null)

const workedHoursByGroup = ref({
    theme: { mode: 'light' },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 2,
            opacityTo: 1,
            stops: [0, 100],
        },
    },
    dataLabels: {
        enabled: true,
    },
    labels: [''],
    xaxis: {
        type: 'datetime',
        min: 0,
        tickAmount: 6,
        labels: {
            formatter(value: number) {
                if (isNaN(value)) return 'NAN';
                return new Date(value).toLocaleDateString() + '(+7D)';
            },
        },
    },
});
const workedHoursByWeek = ref({
    theme: { mode: 'light' },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 100],
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        type: 'datetime',
        min: 0,
        tickAmount: 12,
        labels: {
            formatter(value: number) {
                if (isNaN(value)) return 'NAN';
                return new Date(value).toLocaleDateString() + '(+7D)';
            },
        },
    },
});
const seriesWorkedHoursByWeek = ref([{
    name: '',
    total: 0,
    data: [] as [number, number][]
}])

const seriesWorkedByGroup = ref([] as number[])

// watch(endDate, () => {
//     refreshData();
// });
// watch(startDate, () => {
//     refreshData();
// });

onMounted(() => {
    endDate.value = (new Date());
    const dateStart = (new Date(new Date().setDate(-28)));
    dateStart.setUTCHours(0);
    dateStart.setUTCMinutes(0);
    dateStart.setUTCSeconds(0);
    dateStart.setUTCMilliseconds(0)
    startDate.value = dateStart;
    endDate.value.setUTCHours(0);
    endDate.value.setUTCMinutes(0);
    endDate.value.setUTCSeconds(0);
    endDate.value.setUTCMilliseconds(0)
    refreshData();
    initializeFutureJobs(endDate.value.valueOf());

})

async function initializeFutureJobs(start: number) {
    const dashboardData = await getAdminDashboardJobs(start, start + 10 * 24 * 60 * 60 * 1000 - 1);
    chartOptionsFuture.value = {
        theme: { mode: 'light' },
        xaxis: {
            categories: dashboardData.graphData.dates.map((el: any) =>
                formatDateTime('DD/MM/YYYY', el)
            ),
        },
    }
    seriesChartFuture.value = [
        { name: 'Required Crew', data: dashboardData.graphData.requiredAmount },
        { name: 'Confirmed Crew', data: dashboardData.graphData.confirmedAmout },
        { name: 'Pending Crew', data: dashboardData.graphData.pendingAmount },
    ];
}

async function refreshData() {
    isLoading.value = true;
    dashboardData.value = await getDashboardAdminData(startDate.value, endDate.value);
    const countDays = (endDate.value.valueOf() + 1 - startDate.value.valueOf()) / 24 / 60 / 60 / 1000;

    const seriesName: {
        name: string;
        total: number;
        data: [number, number][];
    }[] = [];
    dashboardData.value.graphHours.forEach((el) => {
        if (!seriesName.find((el2) => el2.name === el.groupName)) {
            seriesName.push({ name: el.groupName, data: [], total: 0 });
        }
    });
    const firstdayOfWeek = new Date(
        startDate.value.setUTCDate(startDate.value.getUTCDate() - startDate.value.getUTCDay() - 6)
    ).valueOf();

    for (let i = 0; i < Math.floor(countDays / 7) + 1; i++) {
        const currentDay = firstdayOfWeek + i * 7 * 24 * 60 * 60 * 1000;
        // xAxisName.push(currentDay);
        seriesName.sort((el1, el2) => {
            if (el1.name < el2.name) {
                return -1;
            }
            if (el1.name > el2.name) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        seriesName.forEach((element) => {
            element.data.push([currentDay, 0]);
        });


        dashboardData.value.graphHours.forEach((el) => {
            if (Number(el.groupInterval) !== currentDay) return;

            seriesName.forEach((el2) => {
                if (el2.name === el.groupName) {

                    el2.data.pop();
                    el2.data.push([
                        Number(el.groupInterval),
                        Number(el.workedHours),
                    ]);
                    el2.total += Number(el.workedHours);
                }
            });
        });
    }

    workedHoursByGroup.value = {
        theme: { mode: 'light' },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 2,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        dataLabels: {
            enabled: true,
        },
        labels: seriesName.map((el) => el.name),
        xaxis: {
            type: 'datetime',
            min: startDate.value.valueOf(),
            tickAmount: 6,
            labels: {
                formatter(value: number) {
                    if (isNaN(value)) return 'NAN';
                    return new Date(value).toLocaleDateString() + '(+7D)';
                },
            },
        },
    };
    seriesWorkedByGroup.value = seriesName.map((el) => el.total);
    workedHoursByWeek.value = {
        theme: { mode: 'light' },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 1,
                opacityTo: 0,
                stops: [0, 100],
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: 'datetime',
            min: startDate.value.valueOf(),
            // max: dateEnd,
            tickAmount: 12,
            labels: {
                formatter(value: number) {
                    if (isNaN(value)) return 'NAN';
                    return new Date(value).toLocaleDateString() + '(+7D)';
                },
            },
        },
    };
    seriesWorkedHoursByWeek.value = seriesName;
    isLoading.value = false;
}


</script>

<style scoped lang="scss">
@import "../../styles/theme.scss";

.title.is-4 {
    margin-top: 2em;
    background-color: $white-ter;
    border-radius: 3px;
}

.title.is-5 {
    text-align: left;
}
</style>