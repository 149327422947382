<template>
    <h3 class="title is-3">Crew Invoices</h3>
    <fieldset class="columns" style="align-items: center;">
        <div class="field column">
            <label class="label">Starting</label>
            <div class="control">
                <input type="date" class="input" v-model="startDateString" />
            </div>
        </div>
        <div class="field column">
            <label class="label">Ending</label>
            <div class="control">
                <input type="date" class="input" v-model="endDateString" />
            </div>
        </div>
        <div class="field column">
            <div class="control">
                <!-- hidden character just to make the height right -->
                <label class="label">&#8232;</label>
                <ButtonIconText icon="fal fa-search" @clicked="callGetInvoices" text="Search"
                    class="is-primary is-fullwidth" />
            </div>
        </div>
        <div style="padding-top: 0px;" class="field column" @mouseleave="isOpenAbaMenu = false">
            <div class="control">
                <label class="label">&#8232;</label>
                <div style="width: 100%;" class="dropdown" :class="{ 'is-active': isOpenAbaMenu }">
                    <div style="width: 100%;" class="dropdown-trigger" @click="isOpenAbaMenu = !isOpenAbaMenu">
                        <button style="width: 100%;" class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span>ABA File</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a @click="downloadABA(2)" class="dropdown-item">
                                ABA File
                            </a>
                            <a @click="downloadABA(1)" class="dropdown-item">
                                ABA File $1
                            </a>
                            <hr class="dropdown-divider">
                            <a @click="downloadABA(0)" class="dropdown-item">
                                Clear Status
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </fieldset>

    <DefaultTable :is-loading="isLoading" :headers="headers" :items="invoices" :select-item="true"
        :selectedItems="selectedInvoices" @rowClick="setSelection($event)">
        <template #empty>
            <tr>
                <td align="center" :colspan="headers.length">No invoices.</td>
            </tr>
        </template>

        <template #header.checkbox>
            <th @click="toggleAll(true)">
                <input @click.stop="toggleAll(false)" v-model="checkAll" type="checkbox"
                    style="height: 100%; width: 100%;" />
            </th>
        </template>

        <template #checkbox="{ item }">
            <td @click.lazy.stop="() => { if (!item.cancelled) {item.checked = !item.checked;  checkCheckAll() }}">
                <input :disabled="item.cancelled" @click.lazy.stop="checkCheckAll()" type="checkbox" v-model="item.checked"
                    class="centerCheckbox" />
            </td>
        </template>

        <template #status="{ item }">
            <td>
                {{ parseStatusInvoice(item) }}
            </td>
        </template>
    </DefaultTable>
    <!-- <table v-else class="table">
        <thead>
            <tr>
                <td><input type="checkbox" v-model="checkAll" @click="toggleAll"></td>
                <td>ID</td>
                <td>Name</td>
                <td>Status</td>
                <td>Total</td>
            </tr>
        </thead>
        <tbody>
            <tr v-if="invoices.length > 0" v-for="( invoice, i ) in  invoices " :key="i"
                :class="{ 'is-selected': selectedInvoice == invoice }">
                <td><input type="checkbox" v-model="invoice.checked"></td>
                <td @click="setSelection(invoice)">{{ invoice.idInvoice }}</td>
                <td @click="setSelection(invoice)">{{ invoice.fullName }}</td>
                <td @click="setSelection(invoice)">{{ parseStatusInvoice(invoice.status) }}</td>
                <td @click="setSelection(invoice)" style="text-align: right">{{ invoice.total.toFixed(2) }}</td>
            </tr>
            <tr v-else>
                <td colspan="3">No invoices.</td>
            </tr>
        </tbody>
    </table> -->
    <Transition>
        <PopOver v-if="selectedInvoice != null" @update:is-open="setSelection(null)">
            <h3 class="title is-3">Invoice Details</h3>
            <IsLoadingIcon v-if="selectedInvoiceData == null" />

            <template v-else>
                <ButtonIconText @clicked="deleteSelectedInvoice" icon="fas fa-trash" text="Cancel this Invoice"
                    class="is-fullwidth mb-3" />
                <iframe :src="selectedInvoiceData" style="width: 100%; height: 500px"></iframe>
            </template>

        </PopOver>
    </Transition>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue';
import { IInvoiceCheckBox, StatusInvoice, getInvoices, getInvoiceDetails, getABAFileData, deleteInvoice } from '@/data/state';
import IsLoadingIcon from '@/components/elements/IsLoadingIcon.vue';
import PopOver from '@/components/elements/PopOver.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
import { base64ToBlob, getSunday, localDownloadFile, showModal } from '@/generalFunctions';
import router from '../_indexAdmin';
import DefaultTable from '@/components/elements/DefaultTable.vue';

// <td><input type="checkbox" v-model="checkAll" @click="toggleAll"></td>
//                 <td>ID</td>
//                 <td>Name</td>
//                 <td>Status</td>
//                 <td>Total</td>
const headers = ref([
    { text: ' ', property: 'checkbox' },
    { text: 'ID', property: 'idInvoice', sortable: true },
    { text: 'Name', property: 'fullName', sortable: true },
    { text: 'Status', property: 'status', sortable: true },
    { text: 'Total', property: 'total', sortable: true, isCurrency: true, align: 'right' },
]);

function checkCheckAll() {
    let isAllChecked = true;
    invoices.value.forEach(el => {
        
        if (el.cancelled) return;
        if (!el.checked) {
            checkAll.value = false;
            isAllChecked = false;
            return;
        }
    });
    if (isAllChecked) {
        checkAll.value = true;
    }
}
const selectedInvoices = ref([] as IInvoiceCheckBox[])
const invoices = ref<IInvoiceCheckBox[]>([]);
const isOpenAbaMenu = ref(false)
const startDate = ref(new Date());
const endDate = ref(new Date());
const startDateString = computed({
    get: () => startDate.value.toISOString().split('T')[0],
    set: (value: string) => {
        startDate.value = new Date(value)
        //startDate.value.setUTCHours(0, 0, 0, 0)
        updateQuery();
        // getTimesAync();
    }
});
const endDateString = computed({
    get: () => endDate.value.toISOString().split('T')[0],
    set: (value: string) => {
        endDate.value = new Date(value);
        updateQuery();
        // getTimesAync();
    }
});
const checkAll = ref(false)

function toggleAll(changeCheckAll = false) {

    invoices.value.forEach(el => {
        el.checked = !checkAll.value
        if (el.cancelled) el.checked = false;//not check cancelled
        
    })
    if (changeCheckAll) {
        checkAll.value = !checkAll.value;
    }
}
const selectedInvoice = ref<IInvoiceCheckBox | null>(null);
watch(selectedInvoice, () => {
    updateQuery();
})


const selectedInvoiceData = ref<string | null>(null);

async function deleteSelectedInvoice() {
    if (!selectedInvoice.value) return;
    if (!confirm(`Do you want to proceed and cancel the invoice ${selectedInvoice.value.idInvoice}?`)) {
        return;
    }
    const snackBar = await deleteInvoice(selectedInvoice.value.idInvoice);
    if (snackBar.error)
        showModal(snackBar)
    else {
        const idToBeDeleted = selectedInvoice.value.idInvoice;
        invoices.value.forEach(el => {
            if (el.idInvoice === idToBeDeleted) el.idInvoice = 0;
        })
        selectedInvoice.value.idInvoice = 0;
    }
}

function setSelection(item: IInvoiceCheckBox | null) {
    if (selectedInvoice.value == item) {
        selectedInvoice.value = null;
        selectedInvoiceData.value = null;
    } else {
        selectedInvoice.value = item;
    }
}

function parseStatusInvoice(Invoice: IInvoiceCheckBox) {
    if (Invoice.cancelled) return 'Cancelled';
    switch (Invoice.status) {
        case StatusInvoice.clear:
            return '';
        case StatusInvoice.oneDollarGenerated:
            return '$1.00 Generated';
        case StatusInvoice.fullGenerated:
            return 'Generated';
    }
}

watch(selectedInvoice, async () => {
    if (!selectedInvoice.value) return
    isLoading.value = true;
    const data = (await getInvoiceDetails(selectedInvoice.value.idInvoice))[0].pdf.data;
    selectedInvoiceData.value = `data:application/pdf;base64,${String.fromCharCode(...data)}`
    isLoading.value = false;
});


const isLoading = ref(false);

async function callGetInvoices() {
    isLoading.value = true;
    const response = (await getInvoices(startDate.value.valueOf(), endDate.value.valueOf())) as unknown as IInvoiceCheckBox[];
    response.forEach(el => el.checked = false);
    invoices.value = response;
    isLoading.value = false;
}

const props = defineProps<{
    startTime: number | null,
    endTime: number | null,
    idInvoice: number | null
}>();

function updateQuery() {
    endDate.value.setUTCHours(23, 59, 59, 999)
    startDate.value.setUTCHours(0, 0, 0, 0)
    router.push({
        name: 'invoices',
        params: {
            ...router.currentRoute.value.params,
            startTime: (startDate.value.valueOf()),
            endTime: (endDate.value.valueOf()),
            idInvoice: isLoading.value ? props.idInvoice : selectedInvoice.value?.idInvoice,
        }
        //    timesheetId: isLoading.value ? props.timesheetId : undefined,
    });
}

async function downloadABA(typeABA: number) {
    const listInvoicesID: number[] = invoices.value.filter(
        (el) => el.checked
    ).map(el => el.idInvoice);
    if (listInvoicesID.length === 0) return;
    let paymentDate: string | null = '';
    if (typeABA > 0) {
        const friday = new Date(getSunday(new Date().valueOf()).valueOf() - 2 * 24 * 60 * 60 * 1000)//get friday
        const fridayString = friday.toISOString().split('T')[0].split('-').reverse().join('/');
        paymentDate = window.prompt('Payment date', fridayString)
        if (!(paymentDate != null && paymentDate.length > 0)) {
            return
        }
    }

    const data = await getABAFileData(listInvoicesID, paymentDate, typeABA)
    if (data.error) {
        showModal(data)
        return;
    }
    if (typeABA === 0) return;
    localDownloadFile(base64ToBlob(data.abaFile), `ABA WE ${paymentDate.replaceAll('/', '-')}.aba`)
    localDownloadFile(base64ToBlob(data.xlsxFile), `ABA WE ${paymentDate.replaceAll('/', '-')}.xlsx`)
}

onMounted(() => {
    isLoading.value = true;
    if (props.endTime) {
        endDate.value = new Date(props.endTime);
    } else {
        endDate.value = getSunday(new Date().setUTCHours(23, 59, 59, 999))
    }
    if (props.startTime) {
        startDate.value = new Date(props.startTime)
    } else {
        startDate.value = new Date(endDate.value.valueOf() - 6 * 24 * 60 * 60 * 1000);
    }

    startDate.value.setUTCHours(0, 0, 0, 0)
    endDate.value.setUTCHours(23, 59, 59, 999)

    updateQuery();//update route
    callGetInvoices();
    isLoading.value = false;

});

</script>

<style lang="scss" scoped>
.centerCheckbox {
    height: 100%;
    width: 100%;
}
</style>