<template>
    <ul class="link-list">
        <li v-for="item in props.items" class="link-list-item" @click="onclick(item.id)" >
            <IconText class="item"  :icon="item.icon" :text="item.text" @onclick="onclick(item.id)" />
            <IconText class="nav-icon" :icon="'fas fa-chevron-right'" @onclick="onclick(item.id)"/>
        </li>
    </ul>
</template>
<script lang="ts" setup>
import IconText from './IconText.vue';

const props = defineProps<{items: IListItem[]}>();
const emits = defineEmits<{clicked: [id: string]}>();

function onclick(id: string){
    emits('clicked', id);
}

export interface IListItem{
    id: string,
    icon?: string,
    text?: string
}

</script>
<style lang="scss" scoped>
    @import "../../styles/theme.scss";

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding-top: 1em !important;
            padding-bottom: 1em !important;
            border-bottom: 1px solid $grey-lighter;
        }
    }

</style>