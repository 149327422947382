<template>
    <!--  -->
    <div class="job-card" @click="emits('onDetailsClick')">
        <IconText class="card-date" v-if="props.job" :text="formatDateTime('D. MMMM. DD, YYYY', props.job.date)"
            icon="fal fa-calendar-day" style="width: 70%;" />
        <ApplicationStatusPill :application-status="applicationStatus" :application-status-text="applicationStatus" />
        <hr v-if="props.job && props.job.color" class="bar" style="margin-top: 28px;"
            :style="{ 'border-color': props.job.color }" />
        <div style="text-align: left;">
            <h4 v-if="vendor != null" class="title is-4" style="text-align: left;">{{ vendor?.fullName }}</h4>
            <h5 v-if="props.job && props.job.title" data-field="title" class="subtitle">{{ props.job.title }}</h5>
        </div>
        <div class="buttons" style="width: 100%">
            <ButtonIconText class="is-small is-rounded" icon="fas fa-info-circle" text="Details"
            @clicked="emits('onDetailsClick')" />
            <ButtonIconText class="is-small is-rounded" :disabled="applicationStatus !== 'open'" icon="fas fa-hand-paper"
                :text="props.job.hasApplied == 0 ? 'Apply' : 'Applied'" @clicked="applyJob/*emits('onApplyClick')*/" />
            <ButtonIconText class="is-small is-rounded" :disabled="props.job.statusUser !== statusCrew.Confirmed"
                icon="fas fa-comments" text="Messages"  @clicked="emits('onChatClick')" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import ButtonIconText from "./elements/ButtonIconText.vue";
import { doApplyJob, formatDateTime } from "@/generalFunctions";
import { computed } from "vue";
import { getApplicationStatus } from "@/generalFunctions";
import { prepends } from "../data/state";
import { IpublicJob, JobStatus, statusCrew } from "../types/jobs";
import IconText from "./elements/IconText.vue";
import ApplicationStatusPill from "./ApplicationStatusPill.vue";

let emits = defineEmits<{
    onApplyClick: [],
    onDetailsClick: [],
    onChatClick: [],
    onclick: []
}>();

async function applyJob() {
    const response = await doApplyJob({ id: props.job.id, reqCrew: props.job.reqCrew });
    if (!response.error) {
        props.job.hasApplied = 1;
    }
}

// const applicationStatus = computed<JobStatus>(() => {
//     if (!props.job.reqCrew) return "filled"
//     if ((props.job as IpublicJob).isIn || (props.job as IjobDetails).reqCrew.some(el => el.userList?.some(el2 => el2.id === user.value!.id))) {
//         return "confirmed"
//     }

//     if (props.job.hasApplied && !props.job.processed) {
//         return "pending" //'Waiting for Approval'
//     }
//     //already checked isIn before
//     if (props.job.hasApplied && props.job.processed /*&& !props.job.isIn*/) {
//         return "filled" // Don't say "rejected"
//     }

//     const hasSpace = props.job.reqCrew.some(el => {
//         if (el.amount > el.confirmed)
//             return true
//         return false
//     });
//     if (!hasSpace) {
//         return "filled"
//     } else {
//         return "open"
//     }
// });
// 'open' | 'pending' | 'confirmed' | 'filled'
const applicationStatus = computed<JobStatus>(() => {
    return getApplicationStatus(props.job)
});

let props = defineProps<{ job: IpublicJob }>();
let vendor = computed(() => {
    if (props.job && props.job.prepend && prepends.value) {
        let found = prepends.value.find(item => item.text == props.job.prepend);
        if (found !== undefined) {
            return found;
        }
    }
    return null;
});

</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";
@import "../../node_modules/bulma/sass/utilities/_all.sass";

.buttons {
    button {
        margin: 0 !important;
    }

    margin: 0 !important;
}

.subtitle {
    margin-bottom: 0 !important;
}

hr {
    border: 4px solid transparent;
    border-radius: 4px;
    height: 0;
}

.job-card {
    @extend .box;
    font-family: Open Sans, sans-serif;
    font-weight: 100;
    position: relative;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap: 1.5em;
    align-items: space-around;
    width: 400px;
    max-width: 50%;
    margin: 1em;
    cursor: pointer;

    .tag {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    @include mobile {
        width: 100%;
        max-width: 100%;
    }

    .buttons {
        display: flex;
        align-self: center;
        justify-content: space-around;
        gap: 0.5em;
        margin-top: auto;

        .button {
            &:hover:not(:disabled) {
                background-color: $primary-light;
                border-color: $primary;
            }

            border-color: $grey-lighter;
            flex-grow: 1;
            background-color: transparent;
            justify-content: center;

            &.is-rounded {
                border-radius: 8px;
            }
        }
    }

    .date {
        display: inline-block;
        align-self: center;
    }
}
</style>