<template>
  <h1 class="title is-centered">Welcome, {{ user?.firstName }}!</h1>
  <Tabs :tabs="tabs" v-model:selected-tab-id="selectedTab"></Tabs>
  <is-loading-icon v-if="isLoading"></is-loading-icon>
  <template v-else>
    <template v-if="selectedTab == 'all'">
      <CardsContainer>
        <template v-for="(item, i) in jobsView" :key="i">
          <JobCard v-if="item.date < endDate.valueOf()" :job="item" :class="{ 'is-selected': (jobId == item.id) }"
            @onclick="setSelection(item,'')" @on-details-click="setSelection(item,'')"
            @on-apply-click="applyJob(item)/*setSelection(item.id, 'apply')*/"
            @on-chat-click="setSelection(item,'chat')">
          </JobCard>
        </template>
      </CardsContainer>
    </template>
    <template v-else-if="selectedTab == 'openings'">
      <div class="column is-12 has-text-centered">
        <h5 class="title is-5">
          {{ formatDateTime("D. MMMM. DD, YYYY", new Date().valueOf()) }} -
          {{ formatDateTime("D. MMMM. DD, YYYY", endDate.valueOf()) }}
        </h5>
        <div v-if="isPublicJobsLoading">
          <IsLoadingIcon></IsLoadingIcon>
        </div>
        <div v-else-if="filteredJobsOpportunities.length === 0" class="notification is-warning mt-2 ml-10">
          Sorry, there is no jobs available on the selected period.
        </div>
      </div>
      <CardsContainer>
        <template v-for="(item, i) in filteredJobsOpportunitiesOpen" :key="'c' + i.toString()">
          <JobCard v-if="item.date < endDate.valueOf()" :job="item" :class="{ 'is-selected': (jobId == item.id) }"
            @on-details-click="setSelection(item,'')" @on-apply-click="setSelection(item,'apply')"
            @on-chat-click="setSelection(item,'chat')">
          </JobCard>
        </template>
      </CardsContainer>
      <div style="flex-basis: 100%; margin: 2em; text-align: center">
        <h5 class="title is-5">
          {{ formatDateTime("D. MMMM. DD, YYYY", new Date().valueOf()) }} -
          {{ formatDateTime("D. MMMM. DD, YYYY", endDate.valueOf()) }}
        </h5>
        <ButtonIconText text="Load More" class="btnLoader is-primary" :class="{ 'is-loading': isPublicJobsLoading }"
          icon="fas fa-calendar-alt" @clicked="endDate = new Date(endDate.setDate(endDate.getDate() + 7))" />
      </div>
    </template>
    <CardsContainer v-else>
      <template v-for="(item, i) in jobsView" :key="i">
        <!-- v-if="item.date < endDate.valueOf()" I removed this filter because it is important for them to know which shifts they will be working on -->
        <JobCard :job="item" :class="{ 'is-selected': (jobId == item.id) }"
          @on-details-click="setSelection(item,'')"
          @on-apply-click="applyJob(item)/*setSelection(item.id, 'apply')*/"
          @on-chat-click="setSelection(item,'chat')">
        </JobCard>
      </template>
    </CardsContainer>

    <Transition>
      <!-- <PopOver v-if="props.view == 'apply'" v-model:is-open="isPopOverActive">
        <JobDetail :id="props.id" @close="isPopOverActive = false"></JobDetail>
      </PopOver> -->
      <PopOver v-if="(jobDetailView !== 'chat') && jobId" v-model:is-open="isPopOverActive">
        <JobDetail :id="jobId" @close="isPopOverActive = false" v-model:jobDetailView="jobDetailView"></JobDetail>
      </PopOver>
      <PopOver v-else-if="jobId" v-model:is-open="isPopOverActive">
        <Chat :jobId="jobId"></Chat>
      </PopOver>
    </Transition>
  </template>
</template>
<script lang="ts" setup>
import { formatDateTime, getFilterJobDate, doApplyJob } from "@/generalFunctions";
import { DashboardProps, IpublicJob, ViewNames, requiredAndCrew } from "@/types/jobs";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
  dashboard,
  isPublicJobsLoading,
  loadPublicJobs,
  user,
  getFullDashboard
} from "../data/state";

import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import CardsContainer from "@/components/elements/CardsContainer.vue";
import IsLoadingIcon from "@/components/elements/IsLoadingIcon.vue";
import Chat from "../components/Chat.vue";
import JobCard from "../components/JobCard.vue";
import JobDetail from "../components/JobDetail.vue";
import PopOver from "../components/elements/PopOver.vue";
import Tabs, { ITabObject } from "../components/elements/Tabs.vue";
import { statusCrew } from "./../types/jobs"

//Add enddate to for pagination
const dayInMilliseconds = 24 * 60 * 60 * 1000;
const endDate = ref(new Date(new Date().valueOf() + 7 * dayInMilliseconds));
onMounted(async () => {
  await getFullDashboard();
  if (selectedTab.value===''){
    if (dashboard.value.availableClockin.length>0)
      selectedTab.value='upcoming'
    else
      selectedTab.value='all'
  }
})

function isWithin12Hours(dateInMilliseconds: number) {
  const currentTime = new Date().getTime();
  const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

  const twelveHoursBefore = currentTime - twelveHours;
  const twelveHoursAfter = currentTime + twelveHours;

  return dateInMilliseconds >= twelveHoursBefore && dateInMilliseconds <= twelveHoursAfter;
}

function setSelection(job:IpublicJob, view:ViewNames|string) {
  jobId.value = job.id;
  if (isWithin12Hours(job.date) && view !=='chat' && job.isIn){
    view='timecard'
  }
  jobDetailView.value = view?view:'details';
}

const jobDetailView = defineModel('jobDetailView', { type: String, default: 'details' });
const jobId = defineModel('jobId', { type: Number, default: 0 });
const selectedTab = defineModel('selectedTab', { default: 'all', type: String });
watch(jobId,refreshRoute)
watch(selectedTab, refreshRoute );
watch(jobDetailView, refreshRoute);

watch(endDate, () => {
  loadPublicJobs(new Date(), new Date(endDate.value));
});

async function applyJob(item: IpublicJob) {
  const response = await doApplyJob(item)
  if (!response.error) {
    item.hasApplied = 1
  }
}

//jobs from currentdate and future
const filteredJobsOpportunities = computed(() => {
  const currentDay = new Date().valueOf()
  return dashboard.value.jobs.filter(
    (el) =>
      getFilterJobDate(el) > currentDay
    // && el.reqCrew.filter((el2) => el2.amount > el2.confirmed).length > 0
  );
});

//jobs from currentdate and future with open positions
const filteredJobsOpportunitiesOpen = computed(() => {
  const currentDay = new Date().valueOf()
  return filteredJobsOpportunities.value.filter(el => {
    if (el.statusUser === statusCrew.Denied || el.statusUser === statusCrew.Confirmed) return false;//dont need to show on opportunities
    // get the sum of all reqcrew amounts
    const totalCrew = el.reqCrew.reduce((acc, el) => acc + el.amount, 0)
    //sum of all confirmed crew
    const totalConfirmed = el.reqCrew.reduce((acc, el) => acc + el.confirmed, 0)
    if (totalCrew > totalConfirmed && el.date > currentDay)
      return true
    return false

  })
});

const myJobs = computed(() =>
  dashboard.value.jobs.filter(
    (el) => {
      return dashboard.value.availableClockin.some(el2 => el2.id === el.id);
    }
  ).sort((a, b) => a.date - b.date)
);

const emits = defineEmits<{ "update:props": [props: Record<string, any>] }>();

let isPopOverActive = computed({
  get() {
    return jobId !== null;
  },
  set(value) {
    if (value === false) {
      jobId.value=0;
      refreshRoute();
    }
  },
});

async function refreshRoute() {
  const paramJobId = (jobId.value||((jobId.value as any)==='0'))?jobId.value:undefined;
  if (paramJobId === undefined) {
    jobDetailView.value = "";
  }
    const url = router.resolve({
      name: "jobs",
      params: { jobId:paramJobId, jobDetailView: jobDetailView.value, selectedTab: selectedTab.value},
    });
    router.push(url);
}

const router = useRouter();

const isLoading = computed(
  () => false //disabled temporary //myJobs.value === null || availableJobs.value === null
);



// const upcomingJobs = computed(() => {
//   return dashboard.value.jobs.filter(item=> item.date >= new Date().valueOf())
// });

// let selectedTabId = computed({
//   get() {
//     if (props.tab === undefined) {
//       return "all";
//     } else {
//       return props.tab;
//     }
//   },
//   set(value) {
//     console.log("setting...");
//     emits("update:props", props);
//     pushToQueryString({ tab: value });
//   },
// });

const tabs: ITabObject[] = reactive([
  {
    id: "all",
    text: "All Jobs",
    count: filteredJobsOpportunities.value.length,
  },
  {
    id: "openings",
    text: "Openings",
    //TODO: re-evaluate after load
    //im counting just the pending one because they are more important to the user than the jobs than the just open ones
    count: filteredJobsOpportunities.value.filter(el => el.statusUser === statusCrew.Pending).length
  },
  {
    id: "upcoming",
    text: "My Shifts",
    count: myJobs.value.length,
  },
  // {
  //   id: "invites",
  //   text: "Invites"
  // },
]);


const jobsView = computed(() => {
  switch (selectedTab.value) {
    case "all":
      return filteredJobsOpportunities.value;
    case "upcoming":
      return myJobs.value;
    // case "invites":
    //   return [];
    case "openings":
      // console.log(filteredJobsOpportunities.value.filter(job => getApplicationStatus(job) === "open" && job.date >= new Date().valueOf()));
      return [];//filteredJobsOpportunities.value.filter(job => getApplicationStatus(job) === "open" && job.date >= new Date().valueOf());
    default:
      return [] as IpublicJob[];
  }
});
</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

.icon.is-loading {
  flex: 1;
}

.is-selected {
  background-color: hsl(0, 79%, 97%);
  border: 1px solid $primary;
  transition-property: background-color, border-color;
  transition: all 0.5s linear;
}

.jobs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

date.title {
  display: block;
}

.btnLoader.is-loading {
  color: transparent !important;

  span {
    color: transparent !important;
  }
}

.tabs li {
  a span:last-child {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  &.is-active a span:last-child {
    background-color: $primary;
    color: $white;
  }
}

table.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.table-empty-row {
  height: 30vh;

  tr {
    cursor: auto;
  }

  td {
    color: $primary;
    vertical-align: middle;
  }
}

@media screen and (max-width: 500px) {
  .tabs span.tag {
    display: none;
  }
}

.buttons {
  @extend .is-centered;
}
</style>