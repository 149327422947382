<template>
    <div class="columns is-mobile">
        <div class="column">
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fal fa-search"></i>
                </span>
                <input class="input is-rounded" type="text" placeholder="Search..." v-model="searchString" />
            </div>
        </div>
        <div v-if="user?.permissions!.includes('crewinvoice:write')" class="column is-narrow">
            <ButtonIconText style="height: 100%;" class="is-primary is-small is-fullwidth"
                :icon="'fa-regular fa-download'" @clicked="downloadXlxs" text="Download" />
        </div>
    </div>

    <IsLoadingIcon v-if="isCrewLoading" />
    <table v-else class="table is-fullwidth">
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Active</th>
                <th>CV</th>
            </tr>
        </thead>
        <tbody>
            <tr :class="{ 'is-selected': selectedWorkerId === item.id }" v-for="(item, i) in crewWorkersView" :key="i"
                @click="selectedWorkerId = item.id">
                <td>{{ item.fullName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>
                    <label class="checkbox">
                        <input type="checkbox" :checked="item.active" @click.stop @change.stop="doToggle(item)" />
                    </label>
                </td>
                <td>
                    <ButtonIconText v-if="item.hasCV" class="is-small is-rounded" icon="fal fa-file-pdf" text="CV"
                        @clicked="downloadCV(item.id)" />
                    <span v-else>-</span>
                </td>
            </tr>
        </tbody>
    </table>
    <Transition>
        <PopOver v-if="selectedWorkerId" v-model:is-open="isPopOverOpen">
            <Profile :user-id="selectedWorkerId" />
        </PopOver>
    </Transition>
</template>

<script lang="ts" setup>
import { computed, ref, Transition, watch, onMounted, onBeforeUnmount } from "vue";
import { crewWorkers, isCrewLoading, getUserDetails, toggleUserActiveAsync } from "../../data/crews";
import PopOver from "@/components/elements/PopOver.vue";
import Profile from "../Profile.vue";
import { IAvailableCrew, IAvailableCrewSelect, IUser } from "@/types/user";
import router from "../_indexAdmin";
import { user, getResumeAsync, doRequest } from "@/data/state";
import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import IsLoadingIcon from "@/components/elements/IsLoadingIcon.vue";
import { base64ToBlob, localDownloadFile } from "@/generalFunctions";

const props = defineProps<{ userId?: number | null }>();
const emits = defineEmits<{ 'update:userId': [userId: number | null] }>();

async function downloadXlxs() {
    const responseFile = await doRequest<string>('/api/misc/downloadxlsxData', 'POST', {});
    const blob = base64ToBlob(responseFile, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    localDownloadFile(blob, 'Users.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
}

const searchString = ref<string>('');

async function doToggle(user: IAvailableCrew) {
    user.active = !user.active;
    await toggleUserActiveAsync(user.id, user.active);
}

const downloadCV = async (userId: number) => {
    let url = await getResumeAsync(userId);
    url = url.slice(1, url.length - 1);
    window.open(url, '_blank');
};

const isPopOverOpen = computed({
    get() {
        return selectedWorkerId.value !== null;
    },
    set(value) {
        if (!value) {
            selectedWorkerId.value = null;
        }
    }
});

const _selectedWorkderId = ref<number | null>(null);
const selectedWorkerId = computed({
    get() {
        return props.userId;
    },
    set(value) {
        if (!value || value === props.userId) {
            router.push({ name: 'crew' });
        } else {
            router.push({ name: 'crew', params: { userId: value } });
        }
    }
});

const selectedWorker = ref<IUser | null>(null);

const crewWorkersView = computed(() => {
    if (searchString.value.length > 0) {
        return crewWorkers.value.filter(item => JSON.stringify(item).toLowerCase().includes(searchString.value.toLowerCase()));
    } else {
        return crewWorkers.value;
    }
});

watch(selectedWorkerId, (value) => {
    if (value != null) {
        router.push({ name: 'crew', params: { userId: value.toString() } });
    } else {
        router.push({ name: 'crew' });
    }
});

watch(() => props.userId, async (value) => {
    if (value != null) {
        let data = await getUserDetails(value);
        selectedWorker.value = data!.user;
    }
});

</script>

<style lang="scss" scoped>
@import "../../styles/theme.scss";

table.table {
    tbody {
        td {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>