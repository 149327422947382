<template>
    <h3 class="title is-3">Invite Crew</h3>
    <IsLoadingIcon v-if="isAvailableCrewLoading" />
    <template v-else>
        <!-- Previous code remains the same until the multiselect section -->
        <!-- <div class="field">
            <label for="filterStateCheckbox" class="checkbox">
                <input type="checkbox" id="filterStateCheckbox" v-model="filterState">
                Filter State
            </label>
        </div> -->
        <div class="field is-grouped">
            <div class="control has-icons-left is-expanded">
                <input class="input" type="text" v-model="filterName" placeholder="Filter by Name">
                <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                </span>
            </div>
            <div class="control">
                <button class="button" @click="showAdvancedFilters = !showAdvancedFilters">
                    <span class="icon">
                        <i class="fas" :class="[showAdvancedFilters ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                    </span>
                    <span>Filters</span>
                </button>
            </div>
        </div>

        <!-- Advanced Filters Panel -->
        <div v-show="showAdvancedFilters" class="box mt-2 mb-4">
            <div class="columns is-multiline">
                <!-- State Filter -->
                <div class="column is-half">
                    <div class="field">
                        <label class="label">State</label>
                        <div class="control">
                            <div class="custom-multiselect">
                                <div class="multiselect-header" @click="toggleStateDropdown">
                                    <span class="selected-text">
                                        {{ selectedStateFilters.length ? `${selectedStateFilters.length} selected` : 'Select states' }}
                                    </span>
                                    <span class="icon">
                                        <i class="fas" :class="[showStateDropdown ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                    </span>
                                </div>
                                <div v-show="showStateDropdown" class="multiselect-dropdown">
                                    <div class="dropdown-item" v-for="state in states" :key="state.id">
                                        <label class="checkbox">
                                            <input type="checkbox" 
                                                   :value="state.name"
                                                   v-model="selectedStateFilters">
                                            {{ state.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Status Filter -->
                <div class="column is-half">
                    <div class="field">
                        <label class="label">Status</label>
                        <div class="control">
                            <div class="custom-multiselect">
                                <div class="multiselect-header" @click="toggleStatusDropdown">
                                    <span class="selected-text">
                                        {{ selectedStatusFilters.length ? `${selectedStatusFilters.length} selected` : 'Select status' }}
                                    </span>
                                    <span class="icon">
                                        <i class="fas" :class="[showStatusDropdown ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                    </span>
                                </div>
                                <div v-show="showStatusDropdown" class="multiselect-dropdown">
                                    <div class="dropdown-item">
                                        <label class="checkbox">
                                            <input type="checkbox" 
                                                   :value="statusCrew.NotDefined"
                                                   v-model="selectedStatusFilters">
                                            Not Invited
                                        </label>
                                    </div>
                                    <div class="dropdown-item">
                                        <label class="checkbox">
                                            <input type="checkbox" 
                                                   :value="statusCrew.Pending"
                                                   v-model="selectedStatusFilters">
                                            Invited
                                        </label>
                                    </div>
                                    <div class="dropdown-item">
                                        <label class="checkbox">
                                            <input type="checkbox" 
                                                   :value="statusCrew.Confirmed"
                                                   v-model="selectedStatusFilters">
                                            Accepted
                                        </label>
                                    </div>
                                    <div class="dropdown-item">
                                        <label class="checkbox">
                                            <input type="checkbox" 
                                                   :value="statusCrew.Denied"
                                                   v-model="selectedStatusFilters">
                                            Denied
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dynamic Filters -->
                 
                <template v-if="crewFilter.length>0">
                    <div v-for="filter in crewFilter" :key="filter.id" class="column is-half">
                        <div class="field">
                            <label class="label">{{ filter.description }}</label>
                            
                            <!-- Checkbox Filter -->
                            <div v-if="filter.type === EFilterType.checkbox" class="control">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="dynamicFilterValues[filter.id]">
                                    {{ filter.description }}
                                </label>
                            </div>

                            <!-- Text Filter -->
                            <div v-else-if="filter.type === EFilterType.text" class="control">
                                <input class="input" type="text" v-model="dynamicFilterValues[filter.id]">
                            </div>

                            <!-- Multiselect Dropdown Filter -->
                            <div v-else-if="filter.type === EFilterType.combobox" class="control">
                                <div class="custom-multiselect">
                                    <div class="multiselect-header" @click="toggleDynamicDropdown(filter.id)">
                                        <span class="selected-text">
                                            {{ getDynamicSelectedText(filter) }}
                                        </span>
                                        <span class="icon">
                                            <i class="fas" :class="[openDynamicDropdowns[filter.id] ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                        </span>
                                    </div>
                                    <div v-show="openDynamicDropdowns[filter.id]" class="multiselect-dropdown">
                                        
                                        <div class="dropdown-item" v-for="option in filter.valuecomboparsed" :key="option">
                                            
                                            <label class="checkbox">
                                                <input type="checkbox" 
                                                       :value="option"
                                                       v-model="dynamicFilterValues[filter.id]">
                                                {{ option }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- Rest of the template remains the same -->
        <template v-if="filteredList && filteredList.length === 0">
            <div class="has-text-centered my-6">
                <span class="has-text-weight-medium">No workers matching the search.</span>
                <div class="mt-4">
                    <ButtonIconText text="Remove Filters" icon="fas fa-filter"
                        @clicked="clearFilters" />
                </div>
            </div>
        </template>

        <table v-else class="table is-fullwidth is-hoverable">
            <!-- Table content remains the same -->
            <thead>
                <tr>
                    <!-- <th style="cursor: pointer;" @click="sortTable('state')">State</th> -->
                    <th style="cursor: pointer;" @click="sortTable('fullName')">Name</th>
                    <th @click="sortTable('selectedOption')" style="cursor: pointer; text-align:center;">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="person in filteredList" :key="person.id">
                    <!-- <td>{{ person.state }}</td> -->
                    <td>{{ person.fullName }}</td>
                    <td style="text-align:center;">
                        <DropDown :selectedId="person.selectedOption" size="is-small" style="font-size: .8em;" :list="[
                            { id: statusCrew.NotDefined, icon: 'fas fa-user-times', iconColor: 'lightgray', text: 'Not Invited' },
                            { id: statusCrew.Pending, icon: 'fas fa-user-clock', iconColor: 'goldenrod', text: 'Invited' },
                            { id: statusCrew.Confirmed, icon: 'fas fa-user-check', iconColor: 'green', text: 'Accepted' },
                            { id: statusCrew.Denied, icon: 'fas fa-user-times', text: 'Denied' }
                        ]" @changedOption="changedOption(person, $event)" />
                    </td>
                </tr>
            </tbody>
        </table>
    </template>
</template>

<script lang="ts" setup>
import { showModal } from '@/generalFunctions';
import { IJobComplete, requiredAndCrew, statusCrew } from '@/types/jobs';
import { EFilterType, IAvailableCrewFilter, IAvailableCrewSelect } from '@/types/user';
import { computed, onMounted, onUnmounted, Ref, ref } from 'vue';
import { availableCrew, getCrewFilter, isAvailableCrewLoading, saveJobUserList, states } from '../../data/state';
import ButtonIconText from '../elements/ButtonIconText.vue';
import DropDown from '../elements/DropDown.vue';
import IsLoadingIcon from '../elements/IsLoadingIcon.vue';

const props = defineProps<{ job?: IJobComplete; reqCrew: requiredAndCrew; state: number|string|undefined}>();

const selectedStateFilters = ref<string[]>([]);
const selectedStatusFilters = ref<number[]>([]);
const dynamicFilterValues = ref<Record<number, string | boolean | string[]>>({});
const showAdvancedFilters = ref(false);


function clearFilters() {
    filterName.value = '';
    selectedStateFilters.value = [];
    selectedStatusFilters.value = [];
    dynamicFilterValues.value = {};
    showAdvancedFilters.value = false;
}
const crewFilter = ref<IAvailableCrewFilter[]>([]);
(async()=>{
    crewFilter.value = await getCrewFilter();
    crewFilter.value.forEach(el=>{
        if (el.type===EFilterType.combobox){
            dynamicFilterValues.value[el.id] = []
        }
    })
    dynamicFilterValues.value
})();
// let crewFilter = ref<IAvailableCrewFilter[]>([]);
//  await getCrewFilter();
const filterName = ref('')

const showStateDropdown = ref(false);
const showStatusDropdown = ref(false);
const openDynamicDropdowns = ref<Record<number, boolean>>({});

// Toggle functions for dropdowns
function toggleStateDropdown() {
    showStateDropdown.value = !showStateDropdown.value;
    showStatusDropdown.value = false;
    Object.keys(openDynamicDropdowns.value).forEach(key => {
        openDynamicDropdowns.value[Number(key)] = false;
    });
}

function toggleStatusDropdown() {
    showStatusDropdown.value = !showStatusDropdown.value;
    showStateDropdown.value = false;
    Object.keys(openDynamicDropdowns.value).forEach(key => {
        openDynamicDropdowns.value[Number(key)] = false;
    });
}

function toggleDynamicDropdown(filterId: number) {
    openDynamicDropdowns.value[filterId] = !openDynamicDropdowns.value[filterId];
    showStateDropdown.value = false;
    showStatusDropdown.value = false;
    Object.keys(openDynamicDropdowns.value).forEach(key => {
        if (Number(key) !== filterId) {
            openDynamicDropdowns.value[Number(key)] = false;
        }
    });
}

function getDynamicSelectedText(filter: IAvailableCrewFilter) {
    const values = dynamicFilterValues.value[filter.id] as string[];
    if (!values || !Array.isArray(values)) return 'Select options';
    return values.length ? `${values.length} selected` : 'Select options';
}

// Click outside handler
function handleClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.custom-multiselect')) {
        showStateDropdown.value = false;
        showStatusDropdown.value = false;
        Object.keys(openDynamicDropdowns.value).forEach(key => {
            openDynamicDropdowns.value[Number(key)] = false;
        });
    }
}

function setStartFilter(){
    if (states.value.length==0){
        setTimeout(setStartFilter,100)
        return
    }
    selectedStateFilters.value=[]
    let foundes = states.value.find(el=>el.id==props.state)
    if (foundes){
        selectedStateFilters.value.push(foundes.name);
    }
}
onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    setStartFilter();
    console.log('dynamicFilterValues',dynamicFilterValues)
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});

const currentSortField: Ref<keyof IAvailableCrewSelect> = ref('fullName')
const isAscending = ref(true)
function sortTable(field: keyof IAvailableCrewSelect) {
    if (currentSortField.value === field) {
        isAscending.value = !isAscending.value;
    } else {
        currentSortField.value = field;
        isAscending.value = true;
    }
}
const privateAvailableCrew = computed(() => {
    const array = availableCrew.value?.map(el => {
        const foundUser = props.reqCrew.userList.find(el2 => el2.id === el.id)
        if (foundUser) return { ...el, selectedOption: (foundUser.status) } as IAvailableCrewSelect
        return { ...el, selectedOption: (0) } as IAvailableCrewSelect
    });
    
    if (!array) return;
    
    array.sort((a, b) => {
        if (a[currentSortField.value] < b[currentSortField.value]) return isAscending.value ? -1 : 1;
        if (a[currentSortField.value] > b[currentSortField.value]) return isAscending.value ? 1 : -1;
        return 0;
    });
    
    return array;
});



// onMounted(async () => {
//     if (availableCrew.value != null && availableCrew.value.length > 0) {
//         const response = await doRequest<IAvailableCrewFilter[]>(
//             '/api/user/getAvailableCrewFilter',
//             'POST',
//             {}
//         );
//         response.forEach((el) => (el.valuecomboparsed = el.valuecombo.split(';')));
//         dynamicFilters.value = response;
//         console.log('dynamicFilters.value',dynamicFilters.value)
//     }
// });

async function changedOption(person: IAvailableCrewSelect, newValue: number) {
    console.log('Calling:saveJobUserList', person, newValue)
    person.selectedOption = newValue;
    const response = await saveJobUserList(props.reqCrew.id, person)
    if (response.error) {
        showModal(response)
        return;
    }
    if (response.UserhasDoubleShift) {
        showModal({ error: true, snackbar: { active: true, color: 'primary', timeout: 10000, text: `${person.fullName} is in more then one shift in a period of 20 hours, please check again!` } })
    }
    if (response.isOverfilled) {
        showModal({ error: true, snackbar: { active: true, color: 'primary', timeout: 10000, text: 'This job have more confirmed crew then the amount needed, please check again!' } })
    }
    const foundElIndex = props.reqCrew.userList.findIndex(el => el.id === person.id)
    if (foundElIndex >= 0) {
        if (person.selectedOption === statusCrew.NotDefined) {
            props.reqCrew.userList.splice(foundElIndex, 1);
        } else {
            props.reqCrew.userList[foundElIndex].status = person.selectedOption
        }
    } else {
        props.reqCrew.userList.push({
            fullName: person.fullName,
            id: person.id,
            status: person.selectedOption
        })
    }
}

// Updated filtered list computation
const filteredList = computed(() => {
    let filtered = privateAvailableCrew.value || [];

    // Apply name filter
    if (filterName.value !== '') {
        filtered = filtered.filter(el => 
            el.fullName.toLowerCase().includes(filterName.value.toLowerCase())
        );
    }

    // Apply state filter based on job state
    // if (filterState.value) {
    //     const jobState = states.value.find(el2 => el2.id === Number(props.job?.state))?.name;
    //     filtered = filtered.filter(el => el.state === jobState);
    // }

    // Apply advanced filters
   
        // State filter
    if (selectedStateFilters.value.length>0) {
        // console.log(selectedStateFilters.value)
        filtered = filtered.filter(el => selectedStateFilters.value.includes(el.state));
    }

    // Status filter
    if (selectedStatusFilters.value.length>0) {
        filtered = filtered.filter(el => selectedStatusFilters.value.includes(el.selectedOption));
    }

    // Dynamic filters
    if (crewFilter.value) {
        filtered = filtered.filter(person => {
            
            return crewFilter.value.every(filter => {
                const filterValue = dynamicFilterValues.value[filter.id];
                switch (filter.type) {
                    case EFilterType.checkbox:
                        if (!filterValue) return true;
                        return person.filterValues.find(el=>el.id==filter.id)?.value == "true";
                    case EFilterType.text:
                        return (person.filterValues.find(el=>el.id==filter.id)?.value as string).toLowerCase().includes(String(filterValue));
                    case EFilterType.combobox:
                        if ((filterValue as string[]).length==0) return true
                        let elements = person.filterValues.find(el=>el.id==filter.id)?.value;
                        return (filterValue as string[]).every(el3=>elements?.includes(el3))
                    default:
                        return true;
                }
            });
        });
    }
    return filtered;
});

//Leaving disabled for now, always when we used the mass message with all users selected was a mistake
// function toggleAll() {
//
// }



</script>
<style lang="scss" scoped>

</style>
