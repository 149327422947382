<template>
  <div style="position: relative; flex-grow: 1" id="rootTimeCardDetal">
    <Transition name="left">
      <div v-show="!isGettingSignature" class="content-holder" v-if="!isPopOverOpen">
        <template v-if="isSupervisor">
          <table class="table is-fullwidth is-narrow mb-0">
            <!--IF IS SUPERVISOR-->
            <tbody>
              <tr>
                <td>Start</td>
                <td>End</td>
                <td>Break</td>
              </tr>
              <template v-for="(clock, i) in props.job?.timesheet" :key="i">
                <tr @click="openTimesheetForm(clock.id)" class="table-row-header">
                  <td colspan="2" class="header-">
                    <Icon v-if="clock.idInvoice || clock.idSignature" icon="fas fa-check primary" />
                    
                    <template v-if="(clock.end-clock.start)>20*60*60*1000">
                      <i class="fa-lg fa-solid fa-triangle-exclamation fa-beat-fade"></i>
                    </template>
                    <i>{{
                      clock.end < 0
                        ? "":
                        'Total:'+formatDurationMS(clock.end - clock.start - clock.break,true)+' - '
                        
                        
                    }}</i>
                    <span class="clockWorkerName">
                      
                    {{ clock.userName }}</span>
                  </td>
                  <td>
                    <Icon icon="fas fa-arrow-right" />
                  </td>
                </tr>
                <tr @click="openTimesheetForm(clock.id)">
                  <td>
                    {{
                      formatDateTime('DD/MM hh:mm xx', clock.start - props.job.tzOffset)
                    }}
                  </td>
                  <td>
                    {{ 
                      (clock.end > 0
                        ? formatDateTime('DD/MM hh:mm xx', clock.end - props.job.tzOffset): "Missing clock out")
                    }}
                  </td>
                  <td>
                  {{
                    formatDurationMS(clock.break,true)
                  }}
                  </td>
                </tr>
                <!-- <tr>
                  
                  <td>
                    <i>
                    Paid Hours</i>
                  </td>
                  <td colspan="2" align="center">
                  <i>
                    {{
                      clock.end > 0
                        ? formatDurationMS(clock.end - clock.start - clock.break)
                        : "Missing clock out"
                    }}</i>
                  </td>
                </tr> -->
              </template>
            </tbody>
          </table>
          <div class="notification">
            <h3 class="title is-6" v-if="unclockedUsers.length > 0">Unclocked Workers</h3>
            <table class="table is-fullwidth is-narrow mb-0">
            <tbody>
              <tr align="center" v-for="(item, i) in unclockedUsers" :key="i" @click="addClock(item.id)" style="cursor: pointer">
                <td><b><i class="fa-lg fa-solid fa-triangle-exclamation fa-beat-fade"></i> {{ item.name }}</b></td>
              </tr>
            </tbody>
          </table>
          </div>
        </template>
        <table v-else class="table is-fullwidth is-narrow mb-0">
          <tbody>
            <!--IF IS CREW-->
            <tr v-for="(clock, i) in props.job?.timesheet" :key="i">
              <td>
                {{ clock.userName }}
              </td>
              <td>
                {{
        clock.end > 0
          ? formatDurationMS(clock.end - clock.start - clock.break)
          : "Missing clock out"
      }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="isSupervisor" class="content flex-container"
          style="height: 100%; display: flex; flex-direction: column; margin-top: auto; margin-bottom: 0px;">
          <!-- <div class="buttons" style="margin-top: auto; margin-bottom: 0px;"> -->
          <ButtonIconText style="margin-top: auto" icon="fas fa-stopwatch" :text="isCheckIn ? 'Check In' : 'Check Out'"
            class="is-fullwidth is-primary" @clicked="doClockInOut" />

          <template v-if="!showSelectTime && !showFinishJob">
            <ButtonIconText v-if="amountMissing > 0 && !showSelectTime" @clicked="startSelectTime"
              icon="fas fa-stopwatch" :text="'Clock Out All'" class="mt-2" />
            <ButtonIconText v-if="!showFinishJob" @clicked="startFinishJob" icon="fas fa-flag-checkered"
              :text="'Finish Job'" class="mt-2" />
          </template>
          <!-- </div> -->
        </div>
        <div v-else class="buttons" style="margin-top: auto">
          <ButtonIconText style="margin-top: auto" icon="fas fa-stopwatch" :text="isCheckIn ? 'Check In' : 'Check Out'"
            class="is-fullwidth is-primary" @clicked="doClockInOut" />
        </div>
        <Transition name="right">
          <div v-show="showSelectTime" class="fieldset">
            <div class="field">
              <label class="label">Finish Time</label>
              <div class="control">
                <input class="input" type="datetime-local" v-model="selectedDateTime" step="900" />
              </div>
            </div>
            <div class="buttons">
              <ButtonIconText @clicked="clockOutEveryone()" icon="fas fa-stopwatch" :text="'Save'" class="is-primary" />
              <ButtonIconText @clicked="showSelectTime = false" icon="fas fa-ban"
                style="margin-left: auto; margin-bottom: 10px" :text="'Cancel'" class="is-outlined" />
            </div>
          </div>
        </Transition>
        <Transition name="right">
          <div v-show="showFinishJob" class="fieldset box" style="position: absolute; bottom: 0; z-index: 1000;">
            <div class="field">
              <label class="label">Customer Name</label>
              <div class="control">
                <input class="input" type="text" placeholder="Mark" v-model="selectedName" step="900" />
              </div>
            </div>
            <div class="mb-3" v-if="amountMissing > 0">
              <span>*You are approving everyone except those pending clock
                out</span>
            </div>
            <div class="buttons" style="justify-content: space-evenly;">
              <ButtonIconText @clicked="getSignature" icon="fas fa-thumbs-up" :text="'Approve'" class="is-primary"
                style="flex-grow: 1;" />
              <ButtonIconText @clicked="showFinishJob = false" icon="fas fa-ban"
                style="margin-bottom: 10px; flex-grow: 1" :text="'Cancel'" class="is-outlined" />
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
    <Transition name="right">
      <div class="content-holder signatureDivContainer" v-if="isGettingSignature">

        <template v-if="signatureMessage.length > 0">
          <div class="tag" style="margin-top: auto">{{ signatureMessage }}</div>
          <ButtonIconText text="Close" icon="fas fa-times" style="margin-top: auto;" @clicked="closeForm"
            class="is-primary" />
        </template>

        <template v-else>
          <div class="signatureDiv">
            <canvas id="signaturePad"></canvas>
          </div>
          <div class="buttons" style="margin-bottom: 5px; margin-top: auto">
            <ButtonIconText :disabled="isLoading" @clicked="saveSignature" icon="fas fa-check" style="margin-left: auto; margin-bottom: 10px"
              :text="'Finish Job'" class="is-primary" />
            <ButtonIconText @clicked="resetCanvas" icon="fas fa-eraser" style="margin-left: auto; margin-bottom: 10px"
              :text="'Clear'" class="is-outlined" />
            <ButtonIconText @clicked="isGettingSignature = false" icon="fas fa-ban"
              style="margin-left: auto; margin-bottom: 10px" :text="'Cancel'" class="is-outlined" />
          </div>
        </template>

      </div>
    </Transition>
    <Transition name="right">
      <div class="content-holder" v-if="isPopOverOpen">
        <!-- props.job.tzOffset -->
        <TimeSheetForm @refreshJob="askRefreshJob" style="position: absolute" :timesheet="props.job.timesheet"
          :timesheet-id="Number(router.currentRoute.value.query.timesheetId)" :tz-offset="props.job.tzOffset" />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { modalInfo } from "@/AppLayout";
import {
  formatDurationMS,
  formatDateTime,
  isValidDate,
  normalizeMinutesUTC,
  toLocalISOString,
} from "@/generalFunctions";
import router from "@/pages/_index";
import { IjobDetails, TypeUpdateSetTime } from "@/types/jobs";
import { ISaveSignatureTimes } from '@/types/timesheet';
import { IDefaultSnackbarResponse } from "@/types/user";
import SignaturePad from "signature_pad";
import { Ref, computed, nextTick, ref } from "vue";
import {
  addClockSupervisor,
  dashboard,
  doClock,
  finishJobSignature,
  setTime,
  user,
} from "../data/state";
import TimeSheetForm from "./TimeSheetForm.vue";
import ButtonIconText from "./elements/ButtonIconText.vue";

import Icon from "./elements/Icon.vue";

const isLoading = ref(false)


const props = defineProps<{ job: IjobDetails }>();
const emit = defineEmits(["refreshJob", "close"]);

function openTimesheetForm(id: number) {
  const { path, query } = router.currentRoute.value;
  router.push({
    path,
    query: { ...query, timesheetId: id.toString() },
  });
}

const showSelectTime = ref(false);
const showFinishJob = ref(false);
const isGettingSignature = ref(false);
const selectedName = ref("");

async function getSignature() {
  isGettingSignature.value = true;
  await nextTick();
  document.getElementById("rootTimeCardDetal")?.parentElement?.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
  resetCanvas();
}

const signatureMessage = ref("");

function closeForm() {
  isGettingSignature.value = false;
  signatureMessage.value = "";
  showSelectTime.value = false;
  showFinishJob.value = false;
}

async function saveSignature() {
  isLoading.value=true;
  const data = signaturePad.value!.toDataURL('image/jpeg');
  const timesheetData: ISaveSignatureTimes[] = props.job!.timesheet.map((el) => {
    let isChecked = true;
    if (el.idInvoice || el.idSignature || (el.end === 0)) {
      isChecked = false;//not signature on those cases
    }
    return {
      break: el.break,
      checked: isChecked,
      end: el.end,
      id: el.id,
      idUser: el.idUser,
      start: el.start
    }
  })
  const results = await finishJobSignature(props.job!.id, selectedName.value, timesheetData, data)
  if (results.error) {
    modalInfo.value = {
      active: true,
      color: "primary",
      text: results.snackbar.text,
      timeout: 5000,
    };
    signatureMessage.value = results.snackbar.text;
  } else {
    signatureMessage.value = "Signature saved";
  }
  isLoading.value=false;
}




function resetCanvas() {
  const canvas = document.getElementById("signaturePad") as HTMLCanvasElement;
  if (!canvas) {
    return;
  }
  signaturePad.value = new SignaturePad(canvas, {
    backgroundColor: "rgba(255,255,255,1)",
  });
  if (!canvas) {
    return;
  }
  canvas.width = canvas.parentElement!.offsetWidth;
  canvas.height = canvas.parentElement!.offsetHeight;
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  // Drawing a vertical line at x = 100
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.fillStyle = 'black';
  ctx.beginPath();
  ctx.moveTo(50, 0);
  ctx.lineTo(50, canvas.height);
  ctx.stroke();
  ctx.save(); // Save the current state
  ctx.translate(30, 200); // Move the origin to just left of the line
  ctx.rotate(Math.PI / 2);
  ctx.textAlign = "center";
  ctx.font = "20px Arial";
  if (selectedName.value.trim() !== '')
    ctx.fillText("Authorized by: " + selectedName.value, 0, 0);
  ctx.restore(); // Restore the original state
}

async function addClock(idUser: number) {
  await addClockSupervisor(props.job!.id!, idUser);
  emit("refreshJob");
}
const selectedDateTime = ref("");

function askRefreshJob() {
  emit("refreshJob");
}

async function startFinishJob() {
  showFinishJob.value = true;
  await nextTick();
  document.getElementById("rootTimeCardDetal")?.parentElement?.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
}

async function startSelectTime() {
  selectedDateTime.value = toLocalISOString(
    normalizeMinutesUTC(new Date().valueOf())
  );
  showSelectTime.value = true;
  await nextTick();
  document.getElementById("rootTimeCardDetal")?.parentElement?.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
}

async function clockOutEveryone() {
  console.log(selectedDateTime.value);
  if (!isValidDate(selectedDateTime.value)) {
    alert("Selected date is invalid");
    return;
  }

  const clockOutDate = normalizeMinutesUTC(
    new Date(selectedDateTime.value).valueOf()
  );

  const promises = props.job!.timesheet.map(async (el) => {
    if (el.end !== 0)
      return {
        error: false,
        snackbar: { active: false, color: "", text: "", timeout: 0 },
      } as IDefaultSnackbarResponse; //ignore the clocked out ones
    const duration = clockOutDate - el.start;
    let breakAmt = 0;
    if (duration > 12 * 60 * 60 * 1000) {
      breakAmt = 1 * 60 * 60 * 1000;
    } else if (duration > 6 * 60 * 60 * 1000) {
      breakAmt = 0.5 * 60 * 60 * 1000;
    }
    const responseSetTimeClockout = await setTime(
      el.id,
      clockOutDate,
      TypeUpdateSetTime.EndTime,
      false
    );
    if (responseSetTimeClockout.error === true) return responseSetTimeClockout;
    return await setTime(el.id, breakAmt, TypeUpdateSetTime.BreakTime, false);
  });
  const resultPromises = await Promise.all(promises);
  const foundError = resultPromises.filter((el) => el.error === true);
  if (foundError.length === 0) {
    emit("refreshJob");
    showSelectTime.value = false;
    return;
  }
  alert("Failed to clock out, refreshing data");
  emit("refreshJob");
}

const isPopOverOpen = computed(
  () =>
    router.currentRoute.value.query.timesheetId &&
    router.currentRoute.value.query.timesheetId !== "0"
);

const amountMissing = computed(() => {
  let tmpAmountMissing = 0;
  props.job?.timesheet.forEach((el) => {
    if (el.end === 0) tmpAmountMissing++;
  });
  return tmpAmountMissing;
});

const unclockedUsers: Ref<
  {
    id: number;
    name: string;
  }[]
> = ref([]);

const usersInJob: Ref<
  {
    id: number;
    name: string;
  }[]
> = ref([]);

function refreshCrewJob() {
  if (!props.job) {
    unclockedUsers.value = [];
    usersInJob.value = [];
    return;
  }
  const allUsers: { id: number; name: string }[] = [];

  props.job.reqCrew.forEach((el) => {
    el.userList.forEach((el2) => {
      allUsers.push({ id: el2.id, name: el2.fullName });
    });
  });

  usersInJob.value = allUsers;
  const unclockedUsersFunc = [...usersInJob.value];
  let filteredArray = unclockedUsersFunc.filter(
    (item1) => !props.job?.timesheet.find((item2) => item2.idUser === item1.id) //REMOVE DUPLICATED VALUES
  );
  unclockedUsers.value = filteredArray;
}

const isSupervisor = computed(() => {
  return dashboard.value.supervisedIds.find((el) => el === props.job?.id);
});

const isCheckIn = computed(() => {
  refreshCrewJob();
  const myClockin = props.job?.timesheet.filter(
    (el) => el.idUser === user.value!.id
  );
  if (!myClockin) return true;
  if (myClockin.length === 0) return true;
  if (myClockin[myClockin.length - 1].end === 0) return false;
  return true;
});

async function doClockInOut() {
  if (props.job !== undefined) {
    let clockResult = await doClock(props.job.id, "");
    if (clockResult.error) {
      modalInfo.value = {
        active: true,
        color: "primary",
        text: clockResult.snackbar.text,
        timeout: 5000,
      };
    }
    emit("refreshJob");
  }
}

const signaturePad: Ref<null | SignaturePad> = ref(null);
</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";

.content-holder {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.clockWorkerName {
  font-weight: bold;
  text-transform: uppercase;
}

.buttons {
  margin-top: auto;
}

.table {
  font-size: 0.8em;

  .select,
  input.input {
    font-size: 0.8em;
  }

  tr:active {
    background-color: #f5f5f5;
    /* Highlight color when the row is tapped */
  }
}

.divider {
  align-content: center;
  height: 1px;
  /* Thickness of the line */
  background-color: #333;
  /* Color of the line */
  width: 80%;
  /* Length of the line */
  margin-top: 20px;
  /* Space above the line */
  margin-bottom: 20px;
  /* Space below the line */
  margin-left: auto;
  /* Center the line horizontally */
  margin-right: auto;
  /* Center the line horizontally */
}

.signatureDivContainer {
  // width: 100vw; /* viewport height */
  height: 100vh;
  /* viewport width */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%) rotate(90deg);
  overflow: hidden;
}

.signatureDiv {
  width: 100%;
  /* fills the rotated container width */
  height: 100%;
  /* fills the rotated container height */
  background-color: transparent;
  /* Just for visibility */
  /* Add other styles as needed */
}

.table-row-header {
  background-color: $grey-lightest;
  cursor: pointer;
}

.signaturePad {
  width: 100%;
  /* fills the rotated container width */
  height: 100%;
  /* fills the rotated container height */
  border: 1px solid #000;
  border-radius: 5px;
}

.isCompleted {
  background-color: green;
}
</style>