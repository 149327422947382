<template>
    <button class="button button-icon-text" :disabled="props.disabled" :class="{ 'is-loading': props.isLoading }" @click.stop="doClick">
        <IconText :text-color="props.textColor" :style="{ '--textColor': props.textColor ? props.textColor : 'white' }"
            :icon="props.icon" :icon-color="props.iconColor" :text="props.text"/>
    </button>
</template>
<script lang="ts" setup>
import IconText from './IconText.vue';

const props = defineProps<{ textColor?: string, icon?: string, text?: string, iconColor?: string, isLoading?: boolean, disabled?: boolean }>();
const emit = defineEmits<{ clicked: [] }>();

function doClick() {
    if(props.disabled) return;
    emit('clicked');
    if(window.analytics && window.analytics.track) {
        if(props.text){
            window.analytics.track('Clicked ' + props.text, { text: props.text, icon: props.icon });
        }else if(props.icon){
            window.analytics.track('Clicked ' + props.icon, { text: props.text, icon: props.icon });
        }else{
            window.analytics.track('ClickedButton', { });        
        }
    }
}

</script>
<style lang="scss" scoped>
@import '../../styles/theme.scss';
.button-icon-text.is-primary {
    :deep(.icon-text) {
        .icon {
            color: var(--textColor);
        }
    }
}
</style>