import {
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from "vue-router";
import { _user, user } from "../data/state";

//REFERENCED COMPONENTS
import CalendarVue from "@/pages/Calendar.vue";
import Invoices from "@/pages/Invoices.vue";
import Profile from "@/pages/Profile.vue";
import LinkLogin from "@/pages/linklogin.vue";
import Login from "../pages/Login.vue";
import TimeSheets from "../pages/TimeSheets.vue";
import Jobs from "../pages/Jobs.vue";
import { DashboardProps } from "../types/jobs";
import Messages from "./Messages.vue";

function parseToIntOrUndefined(number: string): number | undefined {
  const num = parseInt(number);
  if (isNaN(num)) {
    return undefined;
  } else {
    return num;
  }
}

function parseId(route: RouteLocationNormalized): Record<string, any> {
  if (typeof route.params.id != "undefined") {
    return { id: parseToIntOrUndefined(route.params.id as string) };
  } else {
    return { id: null };
  }
}

export type RouteNames =
  | "jobs"
  // | "supervisor"
  | "me"
  | "calendar"
  | "timesheet"
  | "invoices"
  | "login"
  | "messages"
  | "requests";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      name: "jobs",
      component: Jobs,
      path: "/jobs/:selectedTab?/:jobId?/:jobDetailView?",
      meta: {
        icon: "user-hard-hat",
        hidden: false,
        order: 1,
        title: "Jobs",
        position: "top",
      },
      props: (route) => {
        const props = {
          selectedTab: route.params.selectedTab,
          jobId: parseToIntOrUndefined(route.params.jobId as string),
          jobDetailView: route.params.jobDetailView
        }
        return props;
      },
    },
    {
      path: "/me",
      name: "me",
      component: Profile,
      meta: {
        icon: "user",
        title: "My profile",
        order: 8,
        position: "bottom",
      },
      props: (route) => {
        let tab = route.query.tab;
        if (!tab || tab == "") {
          tab = "account";
        }

        if (user.value) {
          return {
            tab: tab,
            userId: user.value.id,
          };
        } else {
          return {
            tab: tab,
            userId: 0,
          };
        }
      },
    },
    {
      path: "/linklogin/:id/:redirect?/:jobid?/:idReqCrew?/:accept?",
      name: "linkLogin",
      component: LinkLogin,
      meta: {
        icon: "sign-out",
        hidden: true,
        order: 1000,
        title: "Link Login",
        position: "bottom",
        nav: false,
      },
      props: (route) => {
        const props = {} as any;
        props.id = route.params.id;
        props.redirect = route.params.redirect;
        props.jobid = route.params.jobid;
        props.idReqCrew = route.params.idReqCrew;
        props.accept = route.params.accept;
        return props;
      },
    },
    {
      path: "/calendar/:idJob?/:formSelectedTabId?",
      name: "calendar",
      component: CalendarVue,
      meta: {
        icon: "calendar",
        title: "Calendar",
        order: 3,
        root: "/calendar",
        position: "top",
        hidden: false,
      },
      props: (route) => {
        if (route.params.idJob === undefined) {
          return {};
        } else {
          let id = Number(route.params.idJob);
          if (!Number.isInteger(id)) id = 0;
          if (
            route.params.formSelectedTabId === undefined ||
            route.params.formSelectedTabId === ""
          ) {
            route.params.formSelectedTabId = "details";
          }
          return {
            idJob: id,
            formSelectedTabId: route.params.formSelectedTabId,
          };
        }
      },
      // props: (route) => {
      //   let props = parseId(route)
      //   props.view = route.params.view
      //   props.id = route.params.id
      //   return props
      // },
    },
    {
      path: "/invoices/:id?/:view?",
      name: "invoices",
      component: Invoices,
      meta: {
        icon: "receipt",
        title: "Invoices",
        order: 5,
        root: "/invoices",
        position: "top",
      },
      props: parseId,
    },
    // {
    //   path: '/sign-in',
    //   name: 'signin',
    //   component: Login,
    //   meta: {
    //     icon: 'sign-in',
    //     title: 'Sign in',
    //     hidden: () => user.value != null && user.value.token != null,
    //     order: 6,
    //     position: 'bottom',
    //     nav: false
    //   },
    // },
    {
      name: "login",
      component: Login,
      path: "/",
      meta: {
        icon: "sign-out",
        hidden: true,
        order: 1000,
        title: "Sign out",
        position: "bottom",
        nav: false,
      },
    },
    {
      name: "logout",
      component: Login,
      path: "/logout",
      meta: {
        icon: "sign-out",
        hidden: true,
        order: 1000,
        title: "Sign out Force",
        position: "bottom",
        nav: false,
      },
    },
    {
      name: "messages",
      component: Messages,
      path: "/messages",
      props: (route) => {
        return {
          tab: route.query.tab,
          id: route.params.id,
          view: route.params.view,
        };
      },
      meta: {
        hidden: false,
        title: "Messages",
        order: 5,
        icon: "bell",
      },
    },
  ],
});

router.onError((err) => {
  // Handle the router error here
  console.error("Router error:", err);
  // Add code for reporting or other error handling logic
});

export let history: RouteLocationNormalized[] = [router.currentRoute.value];

router.beforeEach((to, from) => {
  //explicitly return false to cancel the navigation
  // return false
  if (to.name === "logout") {
    localStorage.removeItem("user");
    window.location.href = "/"; //hard refresh
    return false;
  }
  if (
    user.value == null ||
    user.value === undefined ||
    typeof user.value.token === "undefined"
  ) {
    console.log("Sign Out");
    if (to.path !== "/" && to.name !== "linkLogin") {
      return { name: "login" };
    }
    return true;
  } else {
    return true;
  }
});

router.afterEach((to, from) => {
  //Log all routes
  if (typeof window.analytics !== "undefined") {
    const props: Record<string, any> = Object.assign({}, to.params);
    props.from = from.fullPath;
    props.domain = window.location.hostname;
    if (user.value) {
      props.userId = user.value.id;
      if (to.name) {
        props.name = to.name;
        window.analytics.page("", to.name.toString(), props);
      }
    } else {
      if (to.name) {
        props.name = to.name;
        window.analytics.page("", to.name.toString(), props);
      }
    }
  }
});

export function relativeNavigationTo(
  routeName: RouteNames,
  params?: Record<string, string>
) {
  const currentRoute = router.currentRoute.value;
  if (params === undefined) {
    params = {};
  }
  let destinationRoute = router.resolve({ name: routeName, params: params });
  let destinationUrl = destinationRoute.path;

  //IF KEY/VALUE PAIR MATCH DESELECT / NAVIGATE AWAY
  if (destinationRoute.path === currentRoute.path) {
    const temp = destinationRoute.path.split("/");
    //pop view
    temp.pop();
    //pop id
    temp.pop();
    destinationUrl = temp.join("/");
  }

  const queryString = Object.keys(currentRoute.query)
    .map((key) => `${key}=${currentRoute.query[key]}`)
    .join("&");
  if (queryString !== "") {
    destinationUrl = destinationUrl + "?" + queryString;
  }
  router.push(destinationUrl);
}

export function pushToQueryString(params: Record<string, string>) {
  const currentQuery = router.currentRoute.value.query;
  Object.assign(Object.assign({}, currentQuery), params);
  let currentRouteName = router.currentRoute.value.name as string;
  const target = router.resolve({ name: currentRouteName, query: params });
  router.push(target.fullPath);
}

declare global {
  interface Window {
    router: any;
  }
}

export default router;
