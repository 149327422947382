<template>
    <h3 class="title is-3">Local Storage:</h3>
    <table class="table">
        <tbody v-if="data">
            <tr v-for="(value, key) in data" :key="key">
                <td >
                    {{ key }}
                </td>
                <td>
                    <pre>{{ value }}</pre>
                </td>
            </tr>
        </tbody>
    </table>
    <h4 class="title is-4">Utilities</h4>
    <div class="buttons">
        <ButtonIconText @clicked="throwError" icon="fas fa-bug" text="Throw Error" class="is-danger" />
    </div>
</template>
<script lang="ts" setup>
import { ref, computed, reactive, onMounted, watch } from 'vue'
import ObjectTable from '@/components/elements/ObjectTable.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
const data = computed(() => {
    let map = Object.keys(window.localStorage).map(key => {
        try {
            return { [key]: JSON.stringify(JSON.parse(window.localStorage.getItem(key) as string),null, 3) }
        } catch (e) {
            return { [key]: window.localStorage.getItem(key) }
        }
    });
    return Object.assign({}, ...map);
});

function throwError() {
    throw new Error('This is a test error');
}

</script>
<style lang="scss" scoped>

</style>