import { createRouter, createWebHistory } from 'vue-router';
import { IAvailablePermissions, user } from '@/data/state';

import Crew from './admin/crew.vue';
import Dashboard from './admin/dashboard.vue';
import Settings from './admin/settings.vue';
import Stats from './admin/stats.vue';
import Time from './admin/time.vue';
import Debug from './admin/debug.vue';
import Groups from './admin/groups.vue';
import JobRequests from './admin/JobRequests.vue';
import Invoices from '../pages/admin/Invoices.vue';
import Calendar from './Calendar.vue';
import Registrants from './admin/registrants.vue';

function hasPermission(permission: IAvailablePermissions) {
  try {
    if (!user || !user.value) return false;
    return user.value.permissions!.includes(permission);  
  } catch (error) {
    console.log(error)
    return false
  }
  
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      name: 'root',
      path: '/admin',
      redirect: { path: '/admin/stats' },
      meta: {
        hidden: true,
        title: 'Admin',
        icon: 'home',
        order: 1,
      },
    },
    {
      name: 'dashboard',
      component: Dashboard,
      path: '/admin/dashboard/:idJob?/:formSelectedTabId?/:formSubView?',
      props: (route) => ({
        idJob: Number(route.params.idJob) || undefined,
        formSelectedTabId: route.params.formSelectedTabId || 'info',
        formSubView: route.params.formSubView || '',
        timesheetId: Number(route.query.timesheetId) || undefined,
      }),
      meta: {
        hidden: false,
        title: 'Dashboard',
        icon: 'home',
        order: 1,
      },
    },
    {
      name: 'stats',
      component: Stats,
      path: '/admin/stats',
      meta: {
        title: 'Stats',
        icon: 'chart-line',
        hidden: false,
        order: 0,
      },
    },
    {
      name: 'registrants',
      component: Registrants,
      path: '/admin/registrants/:id?',
      props: true,
      meta: {
        title: 'Registrants',
        icon: 'user-plus',
        hidden: !hasPermission('crew:approve'),
        order: 2,
      },
    },
    {
      name: 'crew',
      component: Crew,
      path: '/admin/crew/:userId?',
      props: (route) => ({
        userId: Number(route.params.userId) || undefined,
      }),
      meta: {
        title: 'Available Crew',
        icon: 'user-hard-hat',
        hidden: !hasPermission('crew:read'),
        order: 2,
      },
    },
    {
      path: '/admin/calendar/:idJob?/:formSelectedTabId?/:formSubView?',
      name: 'calendar',
      component: Calendar,
      meta: {
        icon: 'calendar-day',
        title: 'Calendar',
        order: 2,
        showTitle: false,
        root: '/calendar',
        position: 'top',
        hidden: false,
      },
      props: (route) => ({
        idJob: Number(route.params.idJob) || 0,
        formSelectedTabId: route.params.formSelectedTabId || 'info',
        formSubView: route.params.formSubView || '',
      }),
    },
    {
      path: '/admin/requests/:idJob?/:formSelectedTabId?/:formSubView?',
      name: 'requests',
      component: JobRequests,
      meta: {
        icon: 'hand-paper',
        title: 'Job Requests',
        order: 3,
        position: 'top',
      },
      props: (route) => ({
        idJob: Number(route.params.idJob) || 0,
        formSelectedTabId: route.params.formSelectedTabId,
        formSubView: route.params.formSubView,
      }),
    },
    {
      name: 'time',
      component: Time,
      path: '/admin/time/:startTime?/:endTime?/:idUser?/:timesheetId?',
      meta: {
        title: 'Check Time',
        hidden: !hasPermission('timesheet:read'),
        icon: 'clock',
        order: 4,
      },
      props: (route) => ({
        timesheetId: Number(route.params.timesheetId) || undefined,
        startTime: Number(route.params.startTime) || undefined,
        endTime: Number(route.params.endTime) || undefined,
        idUser: Number(route.params.idUser) || 0,
      }),
    },
    {
      name: 'invoices',
      component: Invoices,
      path: '/admin/invoices/:startTime?/:endTime?/:idInvoice?',
      meta: {
        showTitle: false,
        title: 'Invoices',
        hidden: !hasPermission('crewinvoice:read'),
        icon: 'file-invoice-dollar',
        order: 5,
      },
      props: (route) => ({
        startTime: Number(route.params.startTime) || undefined,
        endTime: Number(route.params.endTime) || undefined,
        idInvoice: Number(route.params.idInvoice) || undefined,
      }),
    },
    {
      name: 'groups',
      component: Groups,
      path: '/admin/groups/:text?',
      props: true,
      meta: {
        title: 'Groups',
        hidden: !hasPermission('group:write'),
        icon: 'users',
        order: 6,
      },
    },
    // Uncomment this block if the Messages route is needed
    // {
    //   name: 'messages',
    //   component: Messages,
    //   path: '/admin/messages/:text?',
    //   props: true,
    //   meta: {
    //     title: 'Messages',
    //     hidden: false,
    //     icon: 'envelope',
    //   },
    // },
    {
      name: 'settings',
      component: Settings,
      path: '/admin/settings/:text?',
      props: true,
      meta: {
        title: 'Settings',
        hidden: !hasPermission('settings:read'),
        icon: 'cog',
        order: 7,
      },
    },
    {
      name: 'debug',
      component: Debug,
      path: '/admin/debug',
      meta: {
        hidden: true,
      },
    },
  ],
});

export default router;

router.afterEach((to, from) => {
  // Log all routes
  if (typeof window.analytics !== 'undefined') {
    const props: Record<string, any> = { ...to.params, from: from.fullPath, domain: window.location.hostname };
    if (user.value) {
      props.userId = user.value.id;
    }
    if (to.name) {
      props.name = to.name;
      window.analytics.page('', to.name.toString(), props);
    }
  }
});
