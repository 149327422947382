<template>
    <div class="container" @focusin="callShowMenu"  >
      <input readonly class="input" type="text" v-bind:value="formatDateTime('DD/MM/YY hh:mm xx',date)" />
      <Transition name="right">
        <div class="menu" v-show="showMenu">
          <div class="field" @click.prevent.stop>
            <button  @click="showMenu = false" class="button has-background-grey-lighter is-rounded is-pulled-right is-small">
                <i style="cursor: pointer;margin-top: 0px;" class="fa-2x fa-thin fa-xmark"></i>
            </button>
            
            <br>
            <Tabs style="margin-top:1em" :tabs="tabs" v-model:selected-tab-id="selectedTab" class="is-fullwidth"/>

            <!-- <Transition> -->
                <div v-if="selectedTab==='date'">
                    <div style="max-width: 80vw; width: 300px">
                        <SimpleCalendar @click.stop="" :selected-days="selectedDay"></SimpleCalendar>
                    </div>
                </div>
            <!-- </Transition>
            <Transition> -->
                <div v-if="selectedTab==='time'" style="width: 300px">
                    <div class="container">
                        <div class="columns is-mobile">
                        <div class="column">
                            <button @click="changeTime('hours',1)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-up"></i></button>
                        </div>
                        <div class="column">
                            <button @click="changeTime('minutes',15)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-up"></i></button>
                        </div>
                        <div class="column">
                            <button @click="changeTime('ampm',1)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-up"></i></button>
                        </div>
                        </div>
                        <div class="columns is-mobile">
                        <div class="column">
                            <input v-model="hours" class="input is-medium no-border" type="text" placeholder="Enter a number">
                        </div>
                        <div class="column">
                            <input v-model="minutes" class="input is-medium no-border" type="text" placeholder="Enter a number">
                        </div>
                        <div class="column">
                            <input v-model="ampm" class="input is-medium no-border" type="text" placeholder="Enter a number">
                        </div>
                        </div>
                        <div class="columns is-mobile">
                        <div class="column">
                            <button @click="changeTime('hours',-1)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-down"></i></button>
                        </div>
                        <div class="column">
                            <button @click="changeTime('minutes',-15)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-down"></i></button>
                        </div>
                        <div class="column">
                            <button @click="changeTime('ampm',-1)" class="button smallMargin is-medium is-fullwidth"><i class="fa-thin fa-angle-down"></i></button>
                        </div>
                        </div>
                    </div>
                    <button class="button is-primary is-fullwidth" style="margin-top:10px" @click="closeMenu">Set</button>
                </div>
            <!-- </Transition> -->
          </div>
        
        </div>
      </Transition>
    </div>
  </template>
  
<script setup lang="ts">
import { ref,Ref,watch } from 'vue'
import SimpleCalendar from './SimpleCalendar.vue';
import Tabs from './Tabs.vue';
import { formatDateTime } from '@/generalFunctions';
const tabs = ref([{
id: 'date',
text: 'Date',
icon: "fa-thin fa-calendar",
},{
id: 'time',
text: 'Time',
icon: 'fa-thin fa-clock',
}]);
const selectedTab:Ref<'date'|'time'> = ref('date')
const selectedDay = ref([] as number[]);
const hours = ref('01');
const minutes = ref('00');
const ampm:Ref<'AM'|'PM'> = ref('AM');
let loading = false;

function closeMenu(){
    console.log('closing menu')
    const returnDate = (new Date(selectedDay.value[0]).valueOf())+
    parseInt(hours.value)*3600000+parseInt(minutes.value)*60000+(ampm.value==='PM'?12:0)*3600000;
    internalDate.value = returnDate;
    date.value = internalDate.value;
    showMenu.value = false;
}
function callShowMenu(){
    if (showMenu.value){
        return;
    }
    loading = true;
    internalDate.value = internalDate.value || 0;
    if (internalDate.value===0) internalDate.value = props.defaultTime||0;
    let day = new Date(internalDate.value);
    hours.value = (day.getUTCHours()>12?day.getUTCHours()-12:day.getUTCHours()).toString().padStart(2,'0');
    minutes.value = day.getUTCMinutes().toString().padStart(2,'0');
    ampm.value = day.getUTCHours()>=12 ? 'PM' : 'AM';
    day.setUTCHours(0,0,0,0);
    selectedDay.value = [day.valueOf()];
    previousDate.value = internalDate.value;
    showMenu.value = true;
    loading = false;
}

function changeTime(property: 'hours'|'minutes'|'ampm', value: number){
    if (property === 'hours'){
        let intHour = parseInt(hours.value);
        intHour += value;
        if (intHour>12){
            intHour = 1;
        }else if (intHour<1){
            intHour = 12;
        }
        hours.value = intHour.toString().padStart(2,'0')
    }else if (property === 'minutes'){
        let intMinutes = parseInt(minutes.value);
        intMinutes += value;
        if (intMinutes>59){
            intMinutes = 0;
        }else if (intMinutes<0){
            intMinutes = 45;
        }
        minutes.value = intMinutes.toString().padStart(2,'0')
    }else if (property === 'ampm'){
        ampm.value = ampm.value === 'AM' ? 'PM' : 'AM';
    }
}

watch(selectedDay, () => {
    if (loading){
        return;
    }
    if (selectedDay.value.length>1){
        selectedDay.value.shift()
    }
    selectedTab.value='time'
},{deep: true});
const showMenu = ref(false)
const previousDate = ref(0)
const props = defineProps({
    defaultTime: {
    type: Number,
    required: false
  }
})

const date = defineModel('date', {
    type: Number,
    required: true,
})

watch(date, () => {
    internalDate.value = date.value;
});

const internalDate = ref(date.value)
</script>
  
  <style scoped>
  .container {
    position: relative;
  }
  .no-border {
      /* font-size: 1rem !important; */
      padding-left: 7px !important;
      padding-right: 7px !important;
      border: none;
      background-color: transparent;
      text-align: center;
    }
    .smallMargin{
        margin-left: 5px;
        margin-right: 5px;
    }
    .input-container input {
      margin: 0 0px; /* Adjust margin as needed */
      padding: 0, 5px;
    }

  .menu {

    position: absolute;
    top: calc(100% + 5px); /* Adjust as needed */
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1000; /* Ensure it's above other elements */
  }
  </style>
  