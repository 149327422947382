<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">

        <p class="modal-card-title">{{ props.title }}</p>
        <CircleProgressbar v-if="props.timer||0 > 0" :timer="props.timer||0" @click="closeModal"><button class="delete"
            aria-label="close"></button></CircleProgressbar>
        <button v-else class="delete" aria-label="close" @click="closeModal"></button>

      </header>
      <section class="modal-card-body">
        <slot></slot>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="closeModal">Close</button>
      </footer>
    </div>
  </div>
</template>
<script lang="ts" setup>
let closed = false;
function closeModal() {
  if (closed) return;
  closed = true;
  emits('onclose')
}

import { onMounted } from 'vue';
import CircleProgressbar from './CircleProgressbar.vue';

onMounted(() => {
  if (props.timer && props.timer > 0)
    setTimeout(() => {
      closeModal()
    }, props.timer)
});

const props = defineProps<{
  title: string
  timer?: number
}>();

let emits = defineEmits<{
  onclose: []
}>();

</script>
<style lang="scss" scoped>
footer {
  justify-content: flex-end;
}
</style>