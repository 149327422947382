<template>
    <div class="cards">
        <slot></slot>
    </div>
</template>
<style lang="scss" scoped>
    @import "../../../node_modules/bulma/sass/utilities/_all.sass";
    .cards{
        @include mobile{
            flex-direction: column;
            flex-wrap: nowrap;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

</style>
