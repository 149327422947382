<template>
    <fieldset class="columns" style="align-items: center;">
        <div class="field column">
            <label class="label">Starting</label>
            <div class="control">
                <input type="date" class="input" v-model="startDateString" />
            </div>
        </div>
        <div class="field column">
            <label class="label">Ending</label>
            <div class="control">
                <input type="date" class="input" v-model="endDateString" />
            </div>
        </div>
        <div class="field column">
            <div class="control">
                <label class="label">

                </label>
                <label class="checkbox">
                    <input type="checkbox" v-model="showAllUsers" />
                    Show All Users
                </label>
            </div>
        </div>
        <div class="field column">
            <div class="control">
                <ButtonIconText icon="fal fa-search" text="Search" @clicked="getTimesAync" class="is-primary" />
            </div>
        </div>
    </fieldset>
    <div class="columns" style="align-items: center;">
        <div class="field column">
            <div class="control">
                <input class="input is-rounded" type="text" placeholder="Search..." v-model="searchString" />
            </div>
        </div>
    </div>
    <DefaultTable :is-loading="isLoading" :select-item="true" :selected-item="selectedItem" v-bind:headers="headers"
        v-bind:items="filteredWorkerTime" @rowClick="setSelection($event)">
        <template #totalWorked="{ item }">
            <td @click="setSelection(item)" align="left">{{ item.totalWorked > 0 ?
                    formatDurationMS(item.totalWorked,
                        false) : 'Missing Clock out' }}</td>
        </template>

        <template #actions="{ item }">
            <td>
                <div class="buttons" style="flex-direction: column; align-items: flex-start;">
                    <IconText @click="createInvoices(item.idUser)" icon="fal fa-receipt" text="Invoice" />
                </div>
            </td>
        </template>

        <template #empty>

            <tr>
                <td colspan="4">
                    <IconText icon="fal fa-empty" text="No records found." />
                </td>
            </tr>

        </template>
    </DefaultTable>
    <!-- <table class="table is-fullwidth">
        <thead>
            <tr>
                <th>Name</th>
                <th>Hours Worked</th>
                <th>Total Break</th>
                <th></th>
            </tr>
        </thead>
        <tbody v-if="!isLoading && workersTimes.length > 0">
            <tr v-for="worker in workersTimes" :key="worker.idUser" :class="{ 'is-selected': selectedItem == worker }">
                <td @click="setSelection(worker)">{{ worker.fullName }}</td>
                <td @click="setSelection(worker)" align="left">{{ worker.totalWorked > 0 ?
                    formatDurationMS(worker.totalWorked,
                        false) : 'Missing Clock out' }}</td>
                <td @click="setSelection(worker)" align="left">{{ formatDurationMS(worker.totalBreak, false) }}</td>
                <td>
                    <div class="buttons" style="flex-direction: column; align-items: flex-start;">
                        <IconText @click="createInvoices(worker.idUser)" icon="fal fa-receipt" text="Invoice" />
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody v-else-if="isLoading">
            <tr>
                <td colspan="4" align="center">
                    <IsLoadingIcon />
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="4">
                    <IconText icon="fal fa-empty" text="No records found." />
                </td>
            </tr>
        </tbody>
    </table> -->
    <Transition>
        <PopOver v-if="selectedItem != null" :is-open="true" @update:is-open="setSelection(null)">
            <WorkerClocks :id-user="selectedItem.idUser" :user="selectedItem" :start="startDate" :end="endDate" />
        </PopOver>
    </Transition>
    <ButtonIconText @clicked="createInvoices(0)" v-if="workersTimes.length > 0" icon="fal fa-receipt"
        text="Generate Invoice" class="is-primary" />
</template>

<script lang="ts" setup>
import DefaultTable from '@/components/elements/DefaultTable.vue';
import { ref, onMounted, computed, watch } from 'vue'
import { getCheckTimeAsync, generateInvoices } from '../../data/state'
import { IUserTimeCheck, IUserTimeCheckCheckbox } from '../../types/timesheet'
import IconText from '@/components/elements/IconText.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
import IsLoadingIcon from '@/components/elements/IsLoadingIcon.vue';
import PopOver from '@/components/elements/PopOver.vue';
import WorkerClocks from '@/components/admin/workerclocks.vue';
import { getSunday, formatDurationMS, showModal } from '../../generalFunctions'
import router from '../_indexAdmin';

const props = defineProps<{
    startTime: number | null,
    endTime: number | null,
    idUser: number | null,
    timesheetId: number | null
}>();

const selectedItem = ref<IUserTimeCheck | null>(null);

const headers = ref([
    { text: 'Name', property: 'fullName', sortable: true },
    { text: 'Hours Worked', property: 'totalWorked', isDuration: true, sortable: true },
    { text: 'Total Break', property: 'totalBreak', isDuration: true, sortable: true },
    { text: '', property: 'actions', sortable: true },
]);

const startDate = ref(new Date());
const endDate = ref(new Date());
const endDateString = computed({
    get: () => endDate.value.toISOString().split('T')[0],
    set: (value: string) => {
        endDate.value = new Date(value)
        endDate.value.setUTCHours(23, 59, 59, 999)
        updateQuery();
        getTimesAync();
    }
});

const startDateString = computed({
    get: () => startDate.value.toISOString().split('T')[0],
    set: (value: string) => {
        startDate.value = new Date(value)
        startDate.value.setUTCHours(0, 0, 0, 0)

        updateQuery();
        getTimesAync();
    }
});



watch(selectedItem, updateQuery);

function updateQuery() {
    router.push({
        name: 'time',
        params: {
            ...router.currentRoute.value.params,
            startTime: (startDate.value.valueOf()),
            endTime: (endDate.value.valueOf()),
            idUser: isLoading.value ? props.idUser : selectedItem.value?.idUser,
        }
    });
}



function setSelection(item: IUserTimeCheckCheckbox | null) {
    selectedItem.value = item;
}

const showAllUsers = ref(false);
const workersTimes = ref<IUserTimeCheckCheckbox[]>([]);
const isLoading = ref<boolean>(false);

onMounted(() => {
    isLoading.value = true;

    if (props.endTime) {
        endDate.value = new Date(props.endTime);
    } else {
        endDate.value = getSunday(new Date().setUTCHours(23, 59, 59, 999) - 7 * 24 * 60 * 60 * 1000)
    }

    if (props.startTime) {
        startDate.value = new Date(props.startTime)
    } else {
        startDate.value = new Date(endDate.value.valueOf() - (7 + 6) * 24 * 60 * 60 * 1000);
    }

    startDate.value.setUTCHours(0, 0, 0, 0)
    endDate.value.setUTCHours(23, 59, 59, 999)

    updateQuery();//update route
    getTimesAync();
})

const searchString = ref('');
const filteredWorkerTime = computed(() => {
    if (searchString.value.length > 0) {
        return workersTimes.value.filter(item => JSON.stringify(item).toLowerCase().includes(searchString.value.toLowerCase()));
    } else {
        return workersTimes.value;
    }
});

async function getTimesAync() {
    isLoading.value = true;
    const checkTime = ((await getCheckTimeAsync(startDate.value, endDate.value, showAllUsers.value)) as unknown as IUserTimeCheckCheckbox[]);
    checkTime.forEach((element) => {
        element.checked = false;
    });
    workersTimes.value = checkTime;
    if (props.idUser) {
        const found = workersTimes.value.find(el => el.idUser === props.idUser)
        if (found)
            setSelection(found)
    }
    isLoading.value = false;
}

async function createInvoices(idUser: number) {
    let amountInvoices: number;
    if (idUser === 0) {
        amountInvoices = workersTimes.value.length;
    } else {
        amountInvoices = 1;
    }
    if (!confirm(`Do you want to proceed and create the invoice${amountInvoices > 1 ? 's' : ''} for ${amountInvoices} worker${amountInvoices > 1 ? 's' : ''}?`)) {
        return;
    }
    const response = await generateInvoices(idUser, startDate.value.valueOf(), endDate.value.valueOf())
    showModal(response)

}

</script>

<style scoped lang="scss">
@import "../../styles/theme.scss";
</style>