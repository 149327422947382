<template>
    <div class="buttons">
        <ButtonIconText class="is-primary" text="Create New Group" icon="fal fa-plus" @clicked="addGroup" />
    </div>
    <table class="table is-fullwidth">
        <thead>
            <tr>
                <th>Group Name</th>
                <th>Active</th>
                <th>

                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, i) in groups">
                <td>{{ item.groupName }}</td>
                <td>
                    <label class="checkbox">
                        <input type="checkbox" :checked="item.active == 1" @change.stop="doToggle(item)" />
                    </label>
                </td>
                <td>
                    <ButtonIconText class="is-small" icon="fal fa-trash" />
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script lang="ts" setup>
import ButtonIconText from "@/components/elements/ButtonIconText.vue";
import { doRequest, groups } from "../../data/state";
import { user } from "../../data/state";
import { IDefaultSnackbarResponse } from "@/types/user";
import { showModal } from "@/generalFunctions";

async function doToggle(item: {
    id: number;
    groupName: string;
    active: 0|1;
}) {
    const data = await doRequest<IDefaultSnackbarResponse>("/api/misc/toggleGroupActive", "POST",
        {
            id: item.id,
            status: item.active == 1 ? 0 : 1
        });
    if (data.error == true) {
        showModal(data)
    }
}
async function addGroup() {
    const conf = window.prompt('Enter Group Name');
    if (conf != null && conf.length > 0) {
        const data = await doRequest<any>("/api/misc/createNewGroup", "POST",
            {
                groupName: conf
            });
        // const group = await fetch("https://backend2.primehire.com.au/api/misc/createNewGroup", 
        // {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": user.value.token
        //     },
        //     body: JSON.stringify({
        //         groupName: conf
        //     })
        // });
        // const data = await group.json();
        if (data.error == true) {
            alert(data.snackbar.text);
        } else {
            groups.value.push(data);
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../styles/theme.scss";
</style>