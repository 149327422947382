<template>
    <span class="avatar-root">
        <img v-if="src != ''" :src="src" :alt="name" :title="name"  :style="{'height': size.toString() + 'px', 'width': size.toString() + 'px'}" />
        <i v-else class="fad fa-user-circle" :style="{'font-size': size + 'px'}" ></i>
        <span :style="{'font-size': fontSize}">{{name}}</span>
    </span>    
</template>

<script lang="ts" setup>
    import { computed } from "vue";

    const props = defineProps<{src: string, name?: string, size?: number, fontSize?: string}>();
    const size = computed(() => {
        if(props.size == null || props.size == undefined){
            return 32;
        }else{
            return props.size;
        }
    });

    const fontSize = computed(() => {
        if(props.fontSize == null || props.fontSize == undefined || props.fontSize == ''){
            return '1em';
        }else{
            return props.fontSize;
        }
    });

</script>

<style lang="scss" scoped>
    @import '../../styles/theme.scss';
    .avatar-root{
        display: flex; 
        align-items: center;
        margin: 0.5em;
    }

    img,
    i{
        border-radius: 100%;
        margin-right: 0.5em;
    }
    span{
        font-size: 1em;
        white-space: break;
    }

    img{
        border: 1px solid $grey;
    }
</style>