<template>
    <h3 class="title is-3">Share Job</h3>
    <IconText v-if="isLoading" style="align-self: center;" icon="fas fa-spinner fa-spin" text="Loading..."></IconText>
    <template v-else>
        <p v-if="props.job === undefined">No job selected</p>
        <template v-else-if="selectedLink.length == 0">
            <div class="field">
                <label class="label">
                    Select receipient
                    <div class="control">
                        <div class="select">
                            <select v-model="selectedEmail" :disabled="people.length == 0">
                                <option value="">No selection</option>
                                <option v-for="person in people" :value="person.email">{{ person.name }} ({{ person.email
                                }})
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
            </div>
            <button class="button is-primary is-fullwidth" style="margin-top: auto;" :disabled="people.length == 0"
                @click="generateLink">Share</button>
        </template>
        <template v-else-if="selectedLink.length > 0">
            <p>Share Link</p>
            <pre @click="copyLink">{{ selectedLink }}</pre>
            <ButtonIconText class="is-primary" style="margin-top: auto" text="Copy" icon="fas fa-copy" @clicked="copyLink">
            </ButtonIconText>
        </template>

    </template>
</template>
<script lang="ts" setup>
import { doRequest, user } from '../../data/state';
import { IJobComplete } from '@/types/jobs';
import { computed, ref, onMounted, watch } from 'vue';
import ButtonIconText from '../elements/ButtonIconText.vue';
import IconText from '../elements/IconText.vue';

const props = defineProps<{ job?: IJobComplete }>();
const selectedEmail = ref<string>('');
const selectedLink = ref<string>('');
const people = ref<{ email: string, name: string, phone: string }[]>([])
const isLoading = ref(true);

function copyLink() {
    navigator.clipboard.writeText(selectedLink.value);
    alert('Copied to clipboard');
}

async function generateLink() {
    isLoading.value = true;
    var json = await doRequest<{ linklogin: string, error: boolean }>("/api/user/generateLinkLoginUserCompany", "POST",
        {
            email: selectedEmail.value
        });
    // var data = await fetch("https://backend2.primehire.com.au/api/user/generateLinkLoginUserCompany",
    //     {
    //         method: 'POST',
    //         body: JSON.stringify({
    //             email: selectedEmail.value
    //         }),
    //         headers: {
    //             Accept: 'application/json, text/plain, */*',
    //             Authorization: user.value.token,
    //             'Content-Type': 'application/json;charset=utf-8'

    //         }
    //     });
    // var json = await data.json() as { linklogin: string, error: boolean };
    selectedLink.value = json.linklogin;
    isLoading.value = false;
}


watch(() => props.job, getCustomers);
onMounted(getCustomers);

async function getCustomers() {
    isLoading.value = true;
    selectedLink.value = '';
    selectedEmail.value = '';
    people.value = [];
    var data = await doRequest<any>("/api/user/getusersCustomer", "POST",
        {
            prefix: props.job!.prepend,
        });
    // var data = await fetch("https://backend2.primehire.com.au/api/user/getusersCustomer",
    //     {
    //         method: 'POST',
    //         body: JSON.stringify({
    //             prefix: props.job!.prepend,
    //         }),
    //         headers: {
    //             Accept: 'application/json, text/plain, */*',
    //             Authorization: user.value.token,
    //             'Content-Type': 'application/json;charset=utf-8'
    //         }
    //     });
    // people.value = await data.json();
    people.value = data;
    isLoading.value = false;
}


</script>
<style lang="scss" scoped>
pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.button-icon-text .icon-text .icon {
    color: white !important;
}
</style>