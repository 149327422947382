<template>
    <h3 class="title is-3">Message Workers</h3>
    <template v-if="sent">
        <p style="font-weight: bold; text-align: center;">
            Message sent to:
            <br />
        <div v-for="user in selectedUsers" :key="user.id">{{ user.fullName }}</div>
        </p>
    </template>
    <template v-else>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>
                        <label class="checkbox">
                            <input type="checkbox" @click="toggleAll">
                        </label>
                    </th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(person, i) in workers" :key="i">
                    <td>
                        <label class="checkbox">
                            <input type="checkbox" v-model="selectedUsers" :value="person">
                        </label>
                    </td>
                    <td>{{ person.fullName }}</td>
                </tr>
            </tbody>
        </table>
        <div class="field">
            <label class="label">
                Message
                <div class="control">
                    <textarea class="textarea" placeholder="Textarea" v-model="message"></textarea>
                </div>
            </label>
        </div>
        <div class="field">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" v-model="email">
                    Email
                </label>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" v-model="phone">
                    Phone
                </label>
            </div>
        </div>
        <ButtonIconText :is-loading="isSending" :disabled="!isValid || isSending" class="is-primary"
            style="margin-top: auto" text="Send" icon="fas fa-comment" @clicked="sendMessage" />

    </template>
</template>

<script lang="ts" setup>
import { IJobComplete, selectedUsersInviteCrew } from '@/types/jobs';
import { ref, computed } from 'vue';
import ButtonIconText from '../elements/ButtonIconText.vue';
import { user } from '../../data/state';

const props = defineProps<{ job?: IJobComplete }>();
const selectedUsers = ref<selectedUsersInviteCrew[]>([]);
const message = ref<string>('');
const email = ref(false);
const phone = ref(false);
const sent = ref(false);
const isValid = computed(() => {
    return selectedUsers.value.length > 0 &&
        message.value.length > 0 &&
        (email.value || phone.value);
});

const isSending = ref(false);

function toggleAll() {
    if (selectedUsers.value.length < workers.value!.length) {
        selectedUsers.value = [];
        if (workers.value != undefined) {
            workers.value.forEach(worker => {
                selectedUsers.value.push(worker);
            });
        }
    } else {
        selectedUsers.value = [];
    }
}

const workers = computed(() => {
    return props.job?.reqCrew.flatMap(c => c.userList);
});

async function sendMessage() {
    if (isValid) {
        isSending.value = true;
        var data = await fetch("https://backend2.primehire.com.au/api/user/sendNotification",
            {
                method: 'POST',
                body: JSON.stringify({
                    text: message.value,
                    listUser: selectedUsers.value.map(u => u.id),
                    selectedEmail: email.value,
                    selectedPhone: phone.value
                }),
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    Authorization: user.value!.token!,
                    'Content-Type': 'application/json;charset=utf-8'
                }
            });
        isSending.value = false;
        sent.value = true;
    }
}


</script>
<style lang="scss" scoped>
ul {
    list-style: initial;

    li {
        list-style: circle;
    }
}

p {
    margin-top: auto;
    margin-bottom: auto;
}
</style>