<template>
    <h3 class="title is-3">Duplicate Job</h3>
    <template v-if="confirmationMessage != ''">
        <div class="notification" style="margin-top: auto; margin-bottom: auto"
            :class="{ 'is-success': successMessage, 'is-danger': !successMessage }">
            {{ confirmationMessage }}
        </div>
    </template>
    <template v-else>
        <SimpleCalendar :selected-days="selectedDays"></SimpleCalendar>
        <span style="margin-left: auto;">Selected {{ selectedDays.length }} day(s) </span>
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" v-model="copyCrew" />
                Copy Crew
            </label>
        </div>
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" v-model="copyStatus" />
                Copy Status
            </label>
        </div>
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" v-model="sendSMS" />
                Send SMS
            </label>
        </div>
        <ButtonIconText text="Duplicate" class="is-primary" icon="fas fa-copy" @clicked="duplicateJob"
            style="margin-top: auto;" />
    </template>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import ButtonIconText from '../elements/ButtonIconText.vue';
import InputDateTime from '../elements/InputDateTime.vue';
import { doRequest, user } from '../../data/state';
import SimpleCalendar from '../elements/SimpleCalendar.vue';
import { IDefaultSnackbarResponse } from '@/types/user';

const selectedDays = ref([] as number[])

const props = defineProps<{ id: number, jobDate: number }>();
const copyCrew = ref<boolean>(true);
const copyStatus = ref<boolean>(true);
const sendSMS = ref<boolean>(false);

const confirmationMessage = ref<string>('');
const successMessage = ref<boolean>(false);

function getTimeOfDayInMilliseconds(datems: number) {
    const date = new Date(datems)
    // Extract hours, minutes, seconds, and milliseconds
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    // Convert all to milliseconds and sum them up
    return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000) + milliseconds;
}


async function duplicateJob() {
    const millis = getTimeOfDayInMilliseconds(props.jobDate)
    const dates = selectedDays.value.map(el => {
        let date = new Date(el)//initialize to midnight, when there is daylightsaving time this fix the issue
        date.setUTCHours(0, 0, 0, 0);
        return date.valueOf() + millis
    });
    const data = await doRequest<IDefaultSnackbarResponse>('/api/job/duplicateJob', 'POST', {
        id: props.id,
        dates: dates,
        duplicateCrew: copyCrew.value,
        duplicateCrewStatus: copyStatus.value,
        sendNotification: sendSMS.value
    })


    confirmationMessage.value = data.snackbar.text;
    successMessage.value = !data.error;
}
</script>
<style lang="scss" scoped></style>