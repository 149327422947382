<template>
    <div class="carousel container">
        <div class="slides">
            <div class="slide" v-for="(slide, index) in props.slides" :key="index" v-show="index === activeSlide">
                <img :src="slide">
            </div>
        </div>
        <div class="buttons is-justify-content-space-between">
            <button :disabled="activeSlide <= 0" style="min-width: 6em;" class="button is-outlined"
                @click="prevSlide">Previous</button>
            <button style="min-width: 6em;" class="button is-primary" @click="emit('onDownloadClick')">Download</button>
            <button :disabled="activeSlide >= props.slides.length - 1" style="min-width: 6em;" class="button is-outlined"
                @click="nextSlide">Next</button>
        </div>
    </div>
</template>
  
<script  lang="ts"  setup>
import { ref } from 'vue';

const activeSlide = ref(0);
const props = defineProps<{ slides: string[] }>();
const emit = defineEmits(['onDownloadClick']);
const nextSlide = () => {
    activeSlide.value = (activeSlide.value + 1) % props.slides.length;
};

const prevSlide = () => {
    activeSlide.value = (activeSlide.value - 1 + props.slides.length) % props.slides.length;
};

</script>
  
<style>
</style>
  