<template>
    <form ref="form" class="column">
        <InputValidate label="First Name" v-model="user.firstName" :minlength="2" :required="true" pattern="[a-zA-Z\s]{2,}"
            errorMessage="Must be at least 2 letters long." />

        <InputValidate label="Last Name" v-model="user.lastName" :minlength="2" :required="true" pattern="[a-zA-Z\s]{2,}"
        errorMessage="Must be at least 2 letters long." />

        <InputABN v-model="user.abn" />

        <InputValidate label="Phone" v-model="user.phone" type="phone" :required="true" />
        
        <InputValidate label="Email" v-model="user.email" :required="true" type="email" />
        
        <InputValidate v-if="props.type == 'company'" label="Company Name" v-model="user.companyName" :minlength="2"
        :required="true" pattern="[a-zA-Z\s]{2,}" errorMessage="Must be at least 2 letters long." />        
    
        <InputValidate v-if="props.type == 'company'" label="Website" v-model="user.website" :required="true" error-message="Must be a valid domain (e.g. www.yourdomain.com.au)." /> 
       
        <InputValidate label="Postcode" pattern="^\d{4}$" v-model="user.postcode" :required="true" />

        <div v-if="props.type == 'worker'" class="file has-name is-fullwidth mb-4">
            <label class="file-label">
                <input @change="changedFile($event)" class="file-input is-fullwidth" type="file" id="resume" accept=".pdf" required>
                <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Upload CV/Resume (PDF only)
                    </span>
                </span>
                <span class="file-name">
                    {{ selectedFile ? selectedFile : 'No file uploaded' }}
                </span>
            </label>
        </div>

        <InputValidate :multiline="true" label="Message" v-model="user.message" :required="true" />

        <ButtonIconText class="is-primary is-fullwidth" text="Submit" :disabled="isloading" :is-loading="isloading"
            @clicked="submit" />
    </form>
    <div class="modal is-active" v-if="errorMessage.length > 0" >
        <div class="modal-background" @click="errorMessage = ''"></div>
        <div class="modal-content">
            <div class="box" style="display: flex; flex-direction: column;">
                <h1 class="tag title is-5 error-header">Error</h1>
                <p style="padding: 2em; text-align: center">
                    <IconText icon="fas fa-exclamation-triangle" :text="errorMessage" />
                </p>
                <ButtonIconText style="align-self: flex-end;" class="is-primary" icon="fas fa-times-circle" text="Close" @clicked="errorMessage = ''" />
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close"  @click="errorMessage = ''"></button>
    </div>
    <div class="modal is-active" v-if="user.id && user.id > 0" >
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box" style="display: flex; flex-direction: column">
                <h1 class="tag title is-5 error-header">
                    <IconText icon="fas fa-thumbs-up" text="You have registered." />
                </h1>
                <p >
                    <p>{{ user.firstName }},</p>
                    <p>You have registeration successfully.  We will reach out to you shortly at:</p>
                    <p style="display: flex; flex-direction: column;">
                        <IconText icon="fas fa-at" :text="user.email" />
                        <IconText icon="fas fa-mobile-alt" :text="user.phone" />
                    </p>
                </p>
                <p>
                    <b>Not you?</b> Click to register a new user: <a href="#" @click="resetUser" class="a-reregister">Register</a>
                </p>
            </div>
        </div>
    </div>    
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, watch } from 'vue'
import InputValidate from '@/components/forms/InputValidate.vue';
import InputABN from '@/components/forms/InputABN.vue';
import ButtonIconText from '@/components/elements/ButtonIconText.vue';
import { doUnauthenticatedRequest } from '@/data/state';
import { convertFileToBase64 } from '@/generalFunctions'
import IconText from '@/components/elements/IconText.vue';
import { ISnackbar } from '@/types/user';
//Moved to segment deployment
//import LogRocket from 'logrocket'
const form = ref<HTMLFormElement | null>(null);
const errorMessage = ref('');

const user = reactive({
    id: -1,
    firstName: '',
    lastName: '',
    phone: '+61',
    email: '',
    message: '',
    postcode: '',
    companyName: '',
    website: '',
    resume: '',
    abn: ''
});



onMounted(() => {
    if(localStorage.getItem('registrant')){
        let localUser = JSON.parse(localStorage.getItem('registrant') as string);
        user.id = localUser.id;
        user.firstName = localUser.firstName;
        user.lastName = localUser.lastName;
        user.phone = localUser.phone;
        user.email = localUser.email;
        user.message = localUser.message;
        user.postcode = localUser.postcode;
        user.companyName = localUser.companyName;
        user.website = localUser.website;
        user.abn = localUser.abn;
        user.resume = localUser.resume;
    }
});

watch(user, () => {
    localStorage.setItem('registrant', JSON.stringify(user));
}, {deep: true});

const props = defineProps({
    type: {
        type: String,
        required: true,
        default: 'worker'
    }
});
const selectedFile = ref('')
async function changedFile(event: Event) {
    user.resume = '';
    selectedFile.value = ''
    const target = event.target as HTMLInputElement;
    if (!target || !target.files || target.files.length === 0) return;
    selectedFile.value = target.files[0].name;
    user.resume = (await convertFileToBase64(target.files[0]))
}

function resetUser(){
    localStorage.clear();
    document.location.reload();
}



const isloading = ref(false);

async function submit() {
    isloading.value = true;
    console.log(form.value);
    if(form.value != null){
        console.log("checkform", form.value.checkValidity());
    if (form.value.checkValidity()) {
        let data = await doUnauthenticatedRequest<{error: boolean, insertId: number | undefined, snackbar: ISnackbar}>('/api/public/preregister', 'POST', user);
        if(data.error){
            errorMessage.value = data.snackbar.text;
            isloading.value = false;
            return;
        }else{
            if(data.insertId !== undefined){
                user.id = data.insertId;
                const userDictionary = JSON.parse(JSON.stringify(user));
                // window.analytics.identify(user.id.toString(), userDictionary);
                // window.analytics.track("registeration", userDictionary);
                
                //Moved to segment deployment
                //LogRocket.identify(user.id.toString(), userDictionary);
            }
        }
    } else {
        alert('Correct the errors in the form before submitting');
    }
    }
    isloading.value = false;
}




</script>
<style scoped lang="scss">
@import '../../styles/theme.scss';

.error-header {
    background-color: $primary-light !important;
}

p{
    padding: 1em;
}

.modal-content{
    max-width: 90% !important; 
    margin-left: auto !important; 
    margin-right: auto !important;
}

.modal-background{
    background-color: hsl(0deg 0% 100% / 86%) !important;
}

.a-reregister{
    background-color: $primary-light;
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 3px;
    border: 1px solid transparent;
    margin-left: 10px;
}

</style>