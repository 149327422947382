<template>
  <div style="position: relative; flex-grow: 1" id="rootTimeCardDetal">
    <Transition name="left">
      <div class="content-holder" v-if="!isPopOverOpen">
        <table class="table is-fullwidth mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Paid Hours</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- TODO: Caio -->
            <tr v-for="(clock, i) in props.timesheets" :key="i" @click="openTimesheetForm(clock.id)">
              <template
                v-if="reqCrews.flatMap(item => item.userList).find(el => el.id === clock.idUser && el.status == statusCrew.Confirmed)">
                <td>
                  <i v-if="clock.idInvoice || clock.idSignature" class="icon fas fa-check primary"
                    style="margin-right: -8px; margin-left: -15px;"></i>
                  {{ clock.userName }}
                </td>
                <td>
                  {{
                    clock.end > 0
                    ? formatDurationMS(clock.end - clock.start - clock.break)
                    : "No clock out"
                  }}
                </td>
                <td>
                  <i class="icon fas fa-arrow-right primary"></i>
                </td>
              </template>
            </tr>
            <tr class="divider"></tr>
            <tr class="divider"></tr>
            <tr>
              <td colspan="3">
                <input type="checkbox" v-model="showAllClocked" @change="refreshCrewJob" />
                Show all clocked in
              </td>
            </tr>
            

            <tr v-for="(item, i) in unclockedUsers" :key="i" @click="addClock(item.id)" style="cursor: pointer" >
              <template v-if="reqCrews.flatMap(item => item.userList).find(el => el.id === item.id && el.status == statusCrew.Confirmed)">
                <td>{{ item.name }}</td>
                <td>No Clock in</td>
              </template>
            </tr>
          </tbody>
        </table>
        <ButtonIconText @clicked="downloadTimesheet" :disabled="props.timesheets.length == 0"
          text="Download Timesheet Report" class="is-fullwidth is-primary" icon="fas fa-download"
          style="margin-top: auto" />
      </div>
    </Transition>
    <Transition name="right">
      <div class="content-holder" v-if="isPopOverOpen">
        <TimeSheetForm @refreshJob="askRefreshJob" style="position: absolute" :timesheet="props.timesheets"
          :timesheet-id="Number(router.currentRoute.value.query.timesheetId)" :tz-offset="props.tzData" />
      </div>
    </Transition>
  </div>
</template>
<script lang="ts" setup>
import {
  formatDurationMS,
  isValidDate,
  localDownloadFile,
  base64ToBlob,
  toLocalISOString,
} from "@/generalFunctions";
import router from "@/pages/_indexAdmin";
import { ItimesheetJob, TypeUpdateSetTime, requiredAndCrew, statusCrew } from "@/types/jobs";
import { ISaveSignatureTimes } from '@/types/timesheet';
import { IDefaultSnackbarResponse } from "@/types/user";
import SignaturePad from "signature_pad";
import { Ref, computed, watch, ref, onMounted } from "vue";
import {
  addClockSupervisor,
  dashboard,
  doClock,
  finishJobSignature,
  setTime,
  user,
  generateTimesheetJob
} from "../data/state";
import TimeSheetForm from "./TimeSheetForm.vue";
import ButtonIconText from "./elements/ButtonIconText.vue";
const showAllClocked = ref(false)
function statusCrewToString(status: statusCrew | undefined) {
  if (status === undefined) return "Unknown";
  switch (status) {
    case statusCrew.Confirmed:
      return "Confirmed";
    case statusCrew.Denied:
      return "Declined";
    case statusCrew.Pending:
      return "Pending";
    case statusCrew.NotDefined:
    default:
      return "Unknown";
  }
}

const props = defineProps<{ idJob: number, reqCrews: requiredAndCrew[], tzData: number; timesheets: ItimesheetJob[] }>();
const emit = defineEmits(["refreshJob"]);
const unclockedUsers: Ref<
  {
    id: number;
    name: string;
  }[]
> = ref([]);

watch(() => props.reqCrews, () => {
  refreshCrewJob();
});
onMounted(() => {
  refreshCrewJob();
})

async function downloadTimesheet() {
  const data = await generateTimesheetJob(props.idJob)
  if (data.duplicateTime.length>0){
    alert('There are duplicated times in the timesheet, please check the timesheet before download')
  }
  localDownloadFile(base64ToBlob(data.pdfData), 'Invoice Job ' + props.idJob + '.pdf')
}

function refreshCrewJob() {

  const allUsers: { id: number; name: string }[] = [];

  props.reqCrews.forEach((el) => {
    el.userList.forEach((el2) => {
      allUsers.push({ id: el2.id, name: el2.fullName });
    });
  });

  if (showAllClocked.value){
    unclockedUsers.value = allUsers;
    return
  }
  const unclockedUsersFunc = [...allUsers];
  let filteredArray = unclockedUsersFunc.filter(
    (item1) => !props.timesheets.find((item2) => item2.idUser === item1.id) //REMOVE DUPLICATED VALUES
  );
  unclockedUsers.value = filteredArray;
}

function openTimesheetForm(id: number) {
  router.push({
    path: router.currentRoute.value.path,
    query: { ...router.currentRoute.value.query, timesheetId: id.toString() },
  });
}

async function addClock(idUser: number) {
  await addClockSupervisor(props.idJob, idUser);
  refreshCrewJob();
  emit("refreshJob");
}

function askRefreshJob() {
  router.push({
    path: router.currentRoute.value.path,
    query: { ...router.currentRoute.value.query, timesheetId: 0 },
  });
  emit("refreshJob");
}

const isPopOverOpen = computed(
  () =>
    router.currentRoute.value.query.timesheetId &&
    router.currentRoute.value.query.timesheetId !== "0"
);




</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

.content-holder {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.buttons {
  margin-top: auto;
}

.table {
  font-size: 0.8em;

  .select,
  input.input {
    font-size: 0.8em;
  }

  tr:active {
    background-color: #f5f5f5;
    /* Highlight color when the row is tapped */
  }
}

.divider {
  align-content: center;
  height: 1px;
  /* Thickness of the line */
  background-color: #333;
  /* Color of the line */
  width: 80%;
  /* Length of the line */
  margin-top: 20px;
  /* Space above the line */
  margin-bottom: 20px;
  /* Space below the line */
  margin-left: auto;
  /* Center the line horizontally */
  margin-right: auto;
  /* Center the line horizontally */
}

.signatureDivContainer {
  // width: 100vw; /* viewport height */
  height: 100vh;
  /* viewport width */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%) rotate(90deg);
  overflow: hidden;
}

.signatureDiv {
  width: 100%;
  /* fills the rotated container width */
  height: 100%;
  /* fills the rotated container height */
  background-color: transparent;
  /* Just for visibility */
  /* Add other styles as needed */
}

.signaturePad {
  width: 100%;
  /* fills the rotated container width */
  height: 100%;
  /* fills the rotated container height */
  border: 1px solid #000;
  border-radius: 5px;
}

.isCompleted {
  background-color: green;
}

:deep(.button-icon-text) .icon-text {
  color: white;
}
</style>