import { computed, ref } from 'vue'
import { IAvailableCrew, IUser } from '../types/user'
import { doRequest, user } from './state'

// export interface ICrewWorker {
//   id: number;
//   fullName: string;
//   email: string;
//   phone: string;
//   active: boolean | number;
//   hasCV: number;
// }

export const isCrewLoading = ref(false)
const _crewWorkers = ref<IAvailableCrew[]>([])
export const crewWorkers = computed(() => {
  if (_crewWorkers.value.length === 0) {
    getAvailableCrewAysnc()
  }
  return _crewWorkers.value
})

async function getAvailableCrewAysnc() {
  if (isCrewLoading.value) return
  //
  const response = await doRequest<IAvailableCrew[]>(
    '/api/user/getAvailableCrew',
    'POST',
    {
      activeStatus: [true, false],
      state: 'All', //get from all states
      pcode: '',
    }
  )
  _crewWorkers.value = response
  isCrewLoading.value = false
}

export async function getUserDetails(id: number) {
  if (id) {
    const response = await fetch(
      process.env.VUE_APP_API_SERVER + '/api/user/getuserdetails',
      {
        method: 'POST',
        headers: {
          Authorization: (user.value as IUser).token as string,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
        }),
      }
    )
    let data = (await response.json()) as {
      user: IUser
      access: string[]
      isAdmin: boolean
    }
    if (
      data &&
      data.user &&
      data.user.picture &&
      data.user.picture.length > 0
    ) {
      data.user.picture = 'data:image/webp;base64,' + data.user.picture
    }
    return data
  } else {
    return null
  }
}

export async function toggleUserActiveAsync(id: number, newState: boolean) {
  const response = await fetch(
    process.env.VUE_APP_API_SERVER + '/api/user/toggeuseractive',
    {
      method: 'POST',
      headers: {
        Authorization: (user.value as IUser).token as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        newState,
      }),
    }
  )
  return
}
