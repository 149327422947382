<template>
  <nav>
    <figure class="image">
      <img src="../assets/ph-icon-horizontal.png" />
    </figure>
    <router-link
      v-for="(item, i) in router.getRoutes().filter(item => !item.meta?.hidden).sort((a, b) => (a.meta!.order as number) - (b.meta!.order as number))"
      :key="i" :to="{ name: item.name }" active-class="is-active" class="icon-text">
      <span class="icon">
        <i class="fal" :class="'fa-' + item.meta!.icon"></i>
      </span>
      <span>{{ item.meta.title }}</span>
    </router-link>
    <a href="/logout" class="icon-text" @click="logout">
      <icon-text icon="fal fa-sign-out" text="Sign out" />
    </a>
    <a v-if="showExtraData && !isAdmin" href="/admin/dashboard" class="icon-text"
      style="margin-top: auto; display: flex; flex-direction: column;">
      <icon-text icon="fal fa-external-link" text="Admin Portal" />
    </a>
    <a v-if="showExtraData && isAdmin && user && user.managerOf===''" href="/jobs" class="icon-text"
      style="margin-top: auto; display: flex; flex-direction: column;">
      <icon-text icon="fal fa-external-link" text="Worker Portal" />
    </a>
  </nav>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { parseJwt } from '@/generalFunctions'
import { notifications } from '../data/state';
import { useRouter } from 'vue-router'
import { user } from "../data/state";
import IconText from './elements/IconText.vue';
import { IUser } from '@/types/user';
import { userRequestNavOpen } from '../AppLayout';
const showExtraData = computed(() => {
  const myUser = user.value as IUser
  if (myUser && myUser.permissions && myUser.permissions.length > 0) return true
  return false
});

const router = useRouter();

const isAdmin = computed(() => {
  return router.currentRoute.value.path.split('/')[1] === 'admin';
});

function logout() {
  userRequestNavOpen.value = false;
  localStorage.removeItem('token');
  document.location.href = '/login';
}

</script>

<style lang="scss" scoped>
@import "../styles/theme.scss";
@import '../../node_modules/bulma/sass/utilities/_all.sass';

.badge {
  font-size: 12px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: $primary;
  color: white;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
}

nav {
  display: flex;
  overflow-x: hidden;
  border-right: 1px solid $grey-lighter;
  overflow-y: auto;
  z-index: 1000;

  @include mobile {
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
  }

  >:last-child {
    margin-bottom: 2em;
  }

  flex-direction: column;
  background-color: $white-ter;
  align-items: stretch;
  box-shadow: 5px 0em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0px 0 1px rgba(10, 10, 10, 0.02);
  margin-bottom: 0;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 0;
  height: 100%;

  .bottom-nav {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }

  .image {
    align-self: center;
    margin-top: 2em;
    margin-bottom: 2em;

    img {
      width: 60px;
    }
  }

  a {
    font-weight: 400;
  }

  a.icon-text {
    display: inline-block;
    white-space: nowrap;
    border-left: 4px solid transparent;

    &:hover {
      border-left-color: $grey-light;
      background-color: $grey-lightest;
    }

    &.is-active {
      background-color: $primary-light;
      border-left-color: $primary;
    }

    padding: 0.5em;
    font-size: 1.1em;
    color: $primary-color;
    text-decoration: none;
  }
}
</style>