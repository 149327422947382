<template>
    <tr @click="emits('onclick')">
        <!-- on Today, dont need to add DD/MM on format datetime -->
        <td data-field="prepend"
            :style="{ 'border-left-color': props.item.color, 'background-color': props.item.color.replace('1)', '.1)') }">
            {{ props.item.prepend }}
        </td>
        <td data-field="date">{{ formatDateTime('YYYY-MM-DD', props.item.date) }}</td>
        <td data-field="time">{{ formatDateTime('hh:mmxx', props.item.date) }}</td>
        <td data-field="title">{{ props.item.title }}</td>
        <td data-field="status">
            <IconText v-if="getJobStatus(props.item) == 'Finished'" icon="fas fa-check" icon-color="green"
                :text="getJobStatus(props.item)" />
            <IconText v-else-if="getJobStatus(props.item) == 'Missing Clock In'" icon="fas fa-user-times"
                icon-color="darkred" :text="getJobStatus(props.item)" />
            <IconText v-else-if="getJobStatus(props.item) == 'In progress'" icon="fas fa-solid fa-circle-notch fa-spin"
                icon-color="blue" :text="getJobStatus(props.item)" />
            <IconText v-else-if="getJobStatus(props.item) == 'Job Cancelled'" icon="fas fa-users-slash" icon-color="black"
                :text="getJobStatus(props.item)" />
            <IconText v-else icon="fas fa-clock" icon-color="black"
                :text="getJobStatus(props.item)" />
        </td>
    </tr>
</template>
<script lang="ts" setup>
import IconText from '@/components/elements/IconText.vue';
import {
    formatDateTime
} from '@/generalFunctions';
import { IJobComplete, IJobCompleteClocks, jobStatus, requiredAndCrew, selectedUsersInviteCrew, statusCrew } from '@/types/jobs';

const props = defineProps<{ item: IJobCompleteClocks }>();
const emits = defineEmits<{ 'onclick': [] }>();

function getJobStatus(job: IJobCompleteClocks) {
    if ((new Date().valueOf()) > job.date + job.tzOffset) {
        if (job.status === jobStatus.Cancelled) return 'Job Cancelled'
        let combinedUserList = [] as any[];
        if (job.reqCrew.length > 0)
            combinedUserList = job.reqCrew.reduce((acc: selectedUsersInviteCrew[], current: requiredAndCrew) => {
                return acc.concat(current.userList);
            }, []);
        combinedUserList = combinedUserList.filter(el => el.status === statusCrew.Confirmed)
        const allClockedIn = combinedUserList.every(el => {
            return job.clockList.some(el2 => el2.idUser === el.id)
        })
        const allClockedOut = combinedUserList.every(el => {
            return job.clockList.some(el2 => (el2.idUser === el.id && el2.end > 0))
        })
        if (allClockedOut) return 'Finished'
        if (allClockedIn) return 'In progress'
        return 'Missing Clock In'
    } else {
        let required = 0
        let confirmed = 0
        job.reqCrew.forEach((el) => {
            required += el.amount
            el.userList.forEach((el2) => {
                if (el2.status === statusCrew.Confirmed) {
                    confirmed++
                }
            })
        })
        if (confirmed < required) return 'Getting Crew'
        return 'Not started'
    }
    //
}

function isMissingCrew(job: IJobComplete): boolean {
    let amount = job.reqCrew.reduce((t, c) => t + c.amount, 0);
    let actual = job.reqCrew.reduce((t, c) => t + c.userList.filter(u => u.status == 2).length, 0);
    return amount != actual;
}


</script>
<style lang="scss" scoped>
@import '../../styles/theme.scss';

tr {
    vertical-align: middle;
}

td[data-field=title] {
    width: auto;
}

tr td:first-child {
    padding: 0;
    padding-left: 5px;
    vertical-align: middle;
    border-left-width: 3px;
    border-left-style: solid;
    max-width: 45px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

td[data-field=date] {
    width: 125px;
    max-width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

td[data-field=time] {
    width: 100px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

td[data-field=status] {
    width: 200px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

:deep(.icon-text) {
    white-space: nowrap;
    overflow: hidden;
}
</style>
