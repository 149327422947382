<template >
  <template v-if="selectedJob != null">
    <!-- <ApplicationStatusPill :job="selectedJob" style="margin: 0; margin-top: 1em; margin-bottom: 1em;" /> -->
    <span class="title is-4" style="text-align: left;">
      {{ company?.fullName }}
    </span>
    <span class="subtitle">
      {{ selectedJob.title }}
    </span>
    <p>
      <IconText v-if="selectedJob.date" :text="formatDateTime('DDD DD/MM hh:mm:ss xx', selectedJob.date)"
        icon="fal fa-calendar-day" />
    </p>

    <Tabs style="margin-top:1em" :tabs="tabs" v-model:selected-tab-id="jobDetailView" />

    <template v-if="jobDetailView == 'details'">
      <table class="table" v-if="selectedJob != null">
        <tbody>
          <!-- <tr v-if="selectedJob.date">
            <td>
              <IconText icon="fal fa-calendar" icon-color="hsl(0, 79%, 40%)" />
            </td>
            <td>
              <span>{{ formatDateTime('DDD DD/MM hh:mm:ss xx', selectedJob.date) }}</span>
            </td>
          </tr> -->
          <tr v-if="selectedJob.location != null">
            <td>
              <IconText icon="fal fa-map-marker-alt" icon-color="hsl(0, 79%, 40%)" />
            </td>
            <td>{{ selectedJob.location }}</td>
          </tr>
          <tr>
            <td>
              <IconText icon="fal fa-user-headset" icon-color="hsl(0, 79%, 40%)" />
            </td>
            <td>
              {{ selectedJob.onSiteResponsibleName }}
              {{ selectedJob.onSiteResponsibleNumber }}
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="selectedJob.description">
        <p>Notes</p>
        <pre>{{ selectedJob.description }}</pre>
      </template>
      <div style="margin-top: auto" class="buttons">
        <ButtonIconText :disabled="disableApply" class="is-primary is-fullwidth" :text="textApply"
          icon="fal fa-hand-paper" @clicked="applyJob" />
        <!-- <ButtonIconText class="is-fullwidth" text="Add to calendar" icon="fal fa-calendar-plus"
          @onclick="addToCalendar()" /> -->
        <div style="width: 100%;" class="dropdown is-up" @click="isDropdownActive = !isDropdownActive"
          :class="{ 'is-active': isDropdownActive }">
          <div class="dropdown-trigger" style="width: 100%;">
            <button class="button is-fullwidth" aria-haspopup="true" aria-controls="dropdown-menu">
              <span class="icon is-small">
                <i class="fal fa-calendar-plus"></i>
              </span>
              <span>Add to calendar</span>

            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">

              <!-- <v-btn fab dark small @click="addJobCalendar(job, 5)">
                  <v-icon>mdi-yahoo</v-icon>
                </v-btn>
                <v-btn fab dark small @click="addJobCalendar(job, 2)">
                  <v-icon>mdi-microsoft-outlook</v-icon>
                </v-btn>
                <v-btn fab dark small @click="addJobCalendar(job, 3)">
                  <v-icon>mdi-microsoft-office</v-icon>
                </v-btn>

                <v-btn fab dark small @click="addJobCalendar(job, 4)">
                  <v-icon>mdi-apple</v-icon>
                </v-btn>
                <v-btn fab dark small @click="addJobCalendar(job, 1)">
                  <v-icon>mdi-google</v-icon> -->

              <a @click="addToCalendar('google')" class="dropdown-item">
                <span class="icon">
                  <i style="font-size: 1.2em;" class="fab fa-google" />
                </span>
                Google
              </a>
              <a @click="addToCalendar('outlook')" class="dropdown-item">
                <span class="icon">
                  <i class="fab fa-windows"></i>
                </span>
                Outlook
              </a>
              <a @click="addToCalendar('ics')" class="dropdown-item">
                <span class="icon">
                  <i class="fab fa-apple"></i>
                </span>
                Apple
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="jobDetailView == 'crews' && selectedJob.reqCrew">
      <div class="icons">
        <!-- <icon-text :text="crew.groupName" icon="fal fa-user"></icon-text> -->
        <template v-for="(item, index) in selectedJob.reqCrew" :key="index">
          <icon-text style="margin-bottom: 0.5em; font-size: 1.2em;" icon="fal fa-users"
            :text="item.confirmed + '/' + item.amount + ' - ' + item.groupName">
          </icon-text>
          <b v-if="(item.startTime !== selectedJob.date)" class="tag has-text-primary is-medium is-rounded is-outlined">{{
            ' *Start at: ' + formatDateTime('DD/MM/YY hh: mmxx', item.startTime) + ' * ' }}</b>
          <ul>
            <template v-if="item.userList.filter(item => item.status == statusCrew.Confirmed).length > 0">
              <li class="li-header">Confirmed</li>
              <li style="list-style-type: none"
                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.Confirmed)" :key="i">
                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-check" class="is-success"
                  icon-color="green"></icon-text>
              </li>
            </template>
            <template
              v-if="item.userList.filter(item => item.status == statusCrew.NotDefined || item.status == statusCrew.Pending).length > 0">
              <li class="li-header">Pending</li>
              <li style="list-style-type: none"
                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.NotDefined || item.status == statusCrew.Pending)"
                :key="i">
                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-clock"
                  icon-color="gray"></icon-text>
              </li>
            </template>
            <template v-if="item.userList.filter(item => item.status == statusCrew.Denied).length > 0">
              <li class="li-header">Not Available</li>
              <li style="list-style-type: none"
                v-for="(crew, i) in item.userList.filter(item => item.status == statusCrew.Denied)" :key="i">
                <icon-text style="font-size: 1em" :text="`${crew.fullName}`" icon="fas fa-user-times"
                  icon-color="darkred"></icon-text>
              </li>
            </template>
          </ul>
          <div v-if="selectedJob.reqCrew.length > 1" class="divider"></div>
        </template>
      </div>
      <div style="margin-top: auto" class="buttons">
        <ButtonIconText v-if="userStatus === statusCrew.Confirmed" :disabled="disableChat" class="is-fullwidth is-primary"
          text="Messages" icon="fal fa-comments" @clicked="
            gotoChat
            " />
        <ButtonIconText v-if="userStatus === statusCrew.Pending" class="is-fullwidth is-primary" text="Accept"
          icon="fal fa-check" @clicked="acceptInvite(true)" />
        <ButtonIconText v-if="userStatus === statusCrew.Pending" class="is-fullwidth" text="Reject" icon="fal fa-times" @clicked="acceptInvite(false)" />
      </div>
    </template>
    <template v-else-if="jobDetailView == 'timecard'">
      <TimeCardDetailSupervisor @refresh-job="loadJob" :job="selectedJob" @close="emits('close')">
      </TimeCardDetailSupervisor>
    </template>
    <template v-else-if="jobDetailView == 'jobFiles'">
      <div class="tagsFile">
        <span class="tagFile" v-for="(item, i) in getJobFiles()" :key="i">
          <span class="outlined" style="width: 100%;" @click="downloadFile(item.id, item.name)">
            <!-- @click="previewFile(selectedJob.filesId?.split(',')[i])" -->
            {{ item.name }}
          </span>
          <button class="button is-small" @click="downloadFile(item.id, item.name)">
            <span class="icon is-small">
              <i class="fas fa-download"></i>
            </span>
          </button>
        </span>
      </div>
    </template>
  </template>
  <template v-else>
    <is-loading-icon></is-loading-icon>
  </template>
</template>
<script lang="ts" setup>
import { doApplyJob, formatDateTime, localDownloadFile, showModal } from "@/generalFunctions";
import { Ref, computed, onMounted, ref, watch } from "vue";
import { prepends, dashboard, downloadFileJobSignature, getJobDetail, user, doRequest } from "../data/state";
import router, { relativeNavigationTo } from "../pages/_index";
import { IjobDetails, JobStatus, requiredAndCrew, selectedUsersInviteCrew, statusCrew } from "../types/jobs";
import TimeCardDetailSupervisor from "./TimeCardDetailSupervisor.vue";
import ButtonIconText from "./elements/ButtonIconText.vue";
import IconText from "./elements/IconText.vue";
import IsLoadingIcon from "./elements/IsLoadingIcon.vue";
import ApplicationStatusPill from "./ApplicationStatusPill.vue";
import Tabs, { ITabObject } from "./elements/Tabs.vue";
import * as calendarLink from "calendar-link";
import { IDefaultSnackbarResponse } from "@/types/user";
const isDropdownActive = ref(false);
const props = defineProps<{
  id: number | undefined;
}>();

const emits = defineEmits(['close','changeTab']);

const company = computed(() => {
  return prepends.value?.find((el) => el.text === selectedJob.value?.prepend);
})
function gotoChat(){
  const currRoute = router.currentRoute.value;
  const params = Object.assign({}, currRoute.params);
  currRoute.name;
  params.jobDetailView = 'chat';
  router.push({name:currRoute.name!,params});
  // relativeNavigationTo('jobs', {
  //             id: props.id?.toString() || '',
  //             view: 'chat',
  //           })
}

function formatStatusCrew(status: statusCrew): string {
  switch (status) {
    case statusCrew.NotDefined:
      return "Not Defined";
    case statusCrew.Pending:
      return "Pending";
    case statusCrew.Confirmed:
      return "Confirmed";
    case statusCrew.Denied:
      return "Not Available";
    default:
      throw new Error("Invalid status");
  }
}

const selectedJob: Ref<IjobDetails | null> = ref(null);

async function acceptInvite(isAccepted: boolean) {
  let reqCrewId = 0;
  selectedJob.value?.reqCrew.forEach(el => {
    if (el.userList.some(el2 => el2.id === user.value!.id)) {
      reqCrewId = el.id;
    }
  })
  if (reqCrewId === 0) return showModal({
    error: true, snackbar: {
      active: true,
      color: 'primary',
      timeout: 5000,
      text: 'You are not in the crew'
    }
  })
  const response = await doRequest<IDefaultSnackbarResponse>('/api/user/replyJob', 'POST', { id: reqCrewId, accepted: isAccepted })
  if (response.error) {
    showModal(response)
  } else {
    loadJob();//refresh job
  }
}

const applyJobModal: Ref<HTMLDialogElement | null> = ref(null);
const isModalOpen = ref(false);
const textApply = computed(() => {
  if (selectedJob.value !== null) {
    if (!selectedJob.value.reqCrew) {
      return 'Not Available'
    }
    let isIn = false;
    selectedJob.value.reqCrew.forEach(el => el.userList.forEach(el2 => {
      if (el2.id === user.value!.id)
        isIn = true;
    }));
    if (isIn) {
      return 'Application Done'
    }
    if (selectedJob.value.hasApplied && !selectedJob.value.processed) {
      return 'Waiting for Approval'
    }

    if (selectedJob.value.hasApplied && selectedJob.value.processed) {
      return 'Application Closed' //'Application Closed: All Positions Filled'
    }
    let hasSpace = false;
    selectedJob.value.reqCrew.forEach(el => {
      if (el.amount > (el.confirmed || 0)) { hasSpace = true }
    })
    if (hasSpace)
      return 'Apply'
    else
      return 'Job has been filled'
  }
  return 'Apply';
});

function applyJob() {
  doApplyJob({ id: selectedJob.value!.id, reqCrew: selectedJob.value!.reqCrew })
}

const disableApply = computed(() => {
  if (selectedJob.value === null) {
    return true
  } else if (selectedJob.value.hasApplied == 1) {
    return true
  } else if (!selectedJob.value.reqCrew) {
    return true
  } else if (!selectedJob.value.reqCrew.some(el => el.amount > el.confirmed)) {
    return true
  }
  return false;
});

const disableChat = computed(() => {
  if (selectedJob.value === null) {
    return true
  } else if (selectedJob.value.selectedSupervisors.some(sup => sup === user.value!.id)) {
    return false
  } else {
    return !selectedJob.value?.reqCrew.some(crew => crew.userList.some(member => member.id === user.value!.id));
  }
});


function addToCalendar(type: 'google' | 'outlook' | 'office365' | 'yahoo' | 'ics') {
  let startTime = 0;
  if (selectedJob.value!.reqCrew.length === 0) return
  startTime = selectedJob.value!.reqCrew[0].startTime;
  selectedJob.value?.reqCrew.forEach(el => {
    if (el.userList.some(el2 => el2.id === user.value!.id)) {
      startTime = el.startTime;
    }
  })
  const event: any = {
    location: selectedJob.value!.location,
    title: selectedJob.value!.title,
    description: `
Start Time: ${formatDateTime('DD/MM/YY hh:mm xx', startTime)}
Location: ${selectedJob.value!.location}

${selectedJob.value!.description}
          

Shifts are marked as 4 hours minimum, but usually it takes more than that`,
    start: startTime + selectedJob.value!.tzOffset,
    duration: [4, 'hour'],
  }
  if (type !== 'ics')
    open(calendarLink[type](event))
  else
    localDownloadFile(calendarLink[type](event), selectedJob.value!.title + '.ics', 'text/calendar')

}

const jobDetailView = defineModel('jobDetailView', { default: 'details', type: String });
// setInterval(()=>{
//   console.log(jobDetailView.value)
// },1000)
watch(jobDetailView, (newVal, oldVal) => {
  emits('changeTab', newVal)
});
// const selectedTab = ref("details");
const tabs: Ref<ITabObject[]> = ref([
  {
    id: "details",
    text: "Details",
  },
  {
    id: "crews",
    text: "Crews",
  },
  {
    id: "timecard",
    text: "Time Card",
  },
  {
    id: "jobFiles",
    text: "Files",
    visible: false,
    count: 0
  },
]);




//TODO: Make a preview window
//   function previewFile(fileId: string | undefined) {
//     if (isNaN(Number(fileId))) return;
//     alert("previewFile");
//     //
//   }

function getJobFiles() {
  return _jobFiles.value
}
const _jobFiles: Ref<{
  name: string;
  id: number;
}[]> = ref([])

const jobFiles = computed(() => {
  return _jobFiles.value
});

watch(jobFiles, (newVal, oldVal) => {
  tabs.value.forEach((el) => {
    if (el.id === "jobFiles") {
      el.count = newVal.length;
    }
  });
});

function fillJobFiles() {
  if (!dashboard.value.isLoaded) {
    setTimeout(fillJobFiles, 100)
    return
  }
  const foundJobFile = dashboard.value.availableClockin.find(el => el.id === selectedJob.value?.id)
  if (foundJobFile) {
    _jobFiles.value = foundJobFile.files
  } else {
    _jobFiles.value = []
  }
  if (selectedJob.value && _jobFiles.value && _jobFiles.value.length > 0) {
    tabs.value.forEach((el) => {
      if (el.id === "jobFiles") {
        el.visible = true;
        el.count = _jobFiles.value!.length;
      }
    });
  }
}



const userStatus = ref(0 as statusCrew)
async function loadJob() {
  if (props.id != undefined) {
    selectedJob.value = await getJobDetail(props.id)
    selectedJob.value!.reqCrew.forEach(el => {
      if (el.userList.some(el2 => el2.id === user.value!.id)) {
        userStatus.value = el.userList.find(el2 => el2.id === user.value!.id)?.status || 0
      }
    })

    // if (isWithin12Hours(selectedJob.value.date)) {
    //   jobDetailView.value = 'timecards'
    // }
    // if (clockins.value === null) return setTimeout(loadJob, 100);
    // const loadedJob = clockins.value?.filter((el) => el.id === props.id) || [];

    // selectedJob.value = loadedJob.length > 0 ? loadedJob[0] : null;
    // if (!selectedJob.value) {
    //   var data = await getJobDetailsAsync(props.id);
    //   selectedJob.value = data.job;
    // };



  }
  fillJobFiles();
}

//  computed(()=>{
//   console.log('calculating files')

//   const foundJobFile = dashboard.value.availableClockin.find(el=>el.id===selectedJob.value?.id)
//   console.log(selectedJob.value)
//   console.log(foundJobFile)
//   if (foundJobFile){
//     console.log('calculating files',foundJobFile.files)
//     return foundJobFile.files
//   }
//   return []
// })
async function downloadFile(fileId: number, filename: string) {
  if (isNaN(Number(fileId))) return;
  const file = await downloadFileJobSignature(fileId, selectedJob.value!.id)
  localDownloadFile(file, filename);
}


onMounted(loadJob);

watch(() => props.id, loadJob);

watch(
  () => props.id,
  async (newId, oldId) => {
    // console.log("old id", oldId, "new id", newId);
    // selectedJob.value = null;
    // await getJobDetails();
  }
);
</script>
<style lang="scss" scoped>
@import "../styles/theme.scss";

pre {
  font-family: Open Sans, sans-serif;
  background-color: white;
  white-space: pre-wrap;
}

.li-header {
  text-transform: uppercase;
  font-size: 0.8em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.icons {
  margin-top: 1.5em;
}

p.buttons {
  margin-top: auto;
}

.is-5 {
  margin-top: 2em;
}

.block {
  display: block;
}

.tags {
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.job-status {
  margin: 0.5em;
  flex-direction: column;
  align-items: flex-start;
}

.tag:not(.job-status) {
  margin: 2px;
  font-size: 1em;
  background-color: $white-ter !important;

  :deep(.icon-text) {
    color: $grey-darker;

    .icon {
      margin-right: 2px;
      margin-left: 2px;
      color: black;
    }
  }
}

.color-box {
  border-radius: 5px;
  height: 8px;
  width: 100%;
}

.title {
  font-weight: 100;
}

.tagsFile {
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tagFile {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 5px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5em;
  border: 1px solid #a82a21;
  /* Blue border */
  border-radius: 5px;
}

.tagFile .button {
  height: 2.5em;
  margin-right: 5px;
  /* Adjust spacing as needed */
  margin-left: 5px;
  /* Adjust spacing as needed */
}

.dropdown-menu {
  width: 100%;

  .dropdown-content {
    border: 1px solid $primary-color;
    border-radius: 5px;
  }
}

.divider {
  align-content: center;
  height: 1px;
  /* Thickness of the line */
  background-color: $grey-lighter;
  /* Color of the line */
  width: 80%;
  /* Length of the line */
  margin-top: 20px;
  /* Space above the line */
  margin-bottom: 20px;
  /* Space below the line */
  margin-left: auto;
  /* Center the line horizontally */
  margin-right: auto;
  /* Center the line horizontally */
}

:deep(.icon-text .icon-text-icon) {
  color: $primary;
}
</style>